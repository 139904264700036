import React, { useState } from 'react';
import logoRp from '../../assets/square_rp_logo.png';

function HandleImageDisplay({ index, imageUrl, imageName, width, height }) {
	const [isValidUrl, setIsValidUrl] = useState(true);

	const handleImageError = () => {
		setIsValidUrl(false);
	};

	return (
		<div>
			{isValidUrl ? (
				<img
					src={imageUrl}
					alt={imageName ? imageName : 'radioLogo'}
					title={imageName ? imageName : 'radioLogo'}
					onError={handleImageError}
					width={width}
					height={height}
				/>
			) : (
				<>
					<img
						src={logoRp}
						alt={imageName ? imageName : 'radioLogo'}
						title={imageName ? imageName : 'radioLogo'}
						width={width}
						height={height}
					/>
				</>
			)}
		</div>
	);
}
export default HandleImageDisplay;
