import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import {
	selectRadio,
	selectToken,
	selectRadiosErrorMessage,
	selectRadiosMessage,
	selectRadiosErrorMessage400,
	selectRadiosServerErrorMessage,
} from '../../../selectors';
import { getRadio, updateRadio } from '../../../services/Radios';
import { setErrorMessage, setErrorMessage400, setErrorServerMessage } from '../../../features/radiosSlice';
import InsideMenu from '../../../components/InsideMenu';
import Spinner from '../../../components/Spinner';
import RadioTypeInputs from '../children/RadioTypeInputs';
import GeneralsInfosInputs from '../children/GeneralsInfosInputs';
import ImgInputs from '../children/ImgInputs';
import GoToTop from '../../../components/GoToTop';
import { modalStyles } from '../../../utils/const';
import { deleteWhiteSpacesInObject } from '../../../utils/functions';

function RadioLogoOnlyUpdate({ type }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const [currentRadio, setCurrentRadio] = useState([]);
	const [isLoaded, setLoaded] = useState(false);
	const [currentRadioLogos, setCurrentRadioLogos] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [formInputs, setFormInputs] = useState({
		radio: {
			type: 3,
			nom: '',
		},
		'1400x1400': {},
	});
	const radioData = useSelector(selectRadio);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const errorMessage400 = useSelector(selectRadiosErrorMessage400);
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);

	useEffect(() => {
		if (radioData) {
			setCurrentRadio(radioData.data);
			setCurrentRadioLogos(radioData.logo);
			setLoaded(true);
		}
	}, [params, radioData]);

	useEffect(() => {
		if (currentRadio) {
			setFormInputs({
				radio: {
					rpid: params.rpID,
					type: 3,
					nom: currentRadio.nom,
				},
			});
		}
	}, [params, currentRadio]);

	useEffect(() => {
		if (token) {
			dispatch(getRadio(token, params.rpID, type));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, params, type]);

	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/radios_logo_only');
	}

	const updateFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({
			...prevInputs,
			radio: {
				...prevInputs.radio,
				[name]: value === 'undefined' ? undefined : value,
			},
		}));
	};

	const handleChangeFormInputs = (event) => {
		updateFormInput(event.target.name, event.target.value);
	};

	const submitForm = (event) => {
		event.preventDefault();
		deleteWhiteSpacesInObject(formInputs);
		dispatch(updateRadio(token, params.rpID, formInputs));
		setIsModalOpen(true);
	};

	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorMessage400([]));
		dispatch(setErrorServerMessage(''));
	};

	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to="/radios_logo_only" className="fs-5 " onClick={eraseErrorMessage}>
					Retour
				</Link>
			</div>
		);
	}

	return (
		<div>
			<InsideMenu
				homeLink={'/radios_logo_only'}
				title={'Gestion des Radios Logo only'}
				links={[{ name: "Ajout d'une radio logo only", path: '/radios_logo_only/add' }]}
			/>

			{isLoaded ? (
				<div className="RadioManager bg-white rounded-4 p-5 mx-4">
					<form onSubmit={submitForm}>
						<RadioTypeInputs
							type={type}
							formInputs={formInputs}
							handleChangeFormInputs={handleChangeFormInputs}
						/>
						<GeneralsInfosInputs
							type={type}
							formInputs={formInputs}
							handleChangeFormInputs={handleChangeFormInputs}
						/>
						<ImgInputs type={type} currentRadioLogos={currentRadioLogos} setFormInputs={setFormInputs} />
						<div className="row">
							<div className="d-flex mt-5">
								<input className="formSubmitButton" type="submit" value="Enregistrer" />
							</div>
						</div>
					</form>
				</div>
			) : (
				<Spinner />
			)}
			<div>
				<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
					{successMessage ? (
						<div className="text-success">
							Les modifications ont été enregistrées. <br />
							<Link
								to={type === 3 ? '/radios_logo_only' : '/radios'}
								className="fs-5 "
								onClick={eraseErrorMessage}
							>
								Retour à la page des radios
							</Link>
						</div>
					) : errorMessage ? (
						<div className="mt-5">
							<div className="text-danger">{errorMessage}</div>
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : errorMessage400.length > 0 ? (
						<div>
							<div>Les erreurs suivantes sont apparues dans les données de votre formulaire:</div>
							{errorMessage400.map((message, index) => (
								<div key={index} className="my-2">
									<div className="text-danger"> - {message.msg}</div>
								</div>
							))}
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : null}
				</Modal>
			</div>
			<GoToTop />
		</div>
	);
}

export default RadioLogoOnlyUpdate;
