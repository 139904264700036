import axios from 'axios';
import {
	setArticles,
	setArticle,
	setErrorMessage,
	setMessage,
	setErrorServerMessage,
} from '../../features/articlesSlice';

const { REACT_APP_API_URL } = process.env;

//Get articles list by state. Param pending allows to get [state 0 & 1]
export const getArticlesByState = (token, isPending, requestedState) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/articles`, {
				headers: { 'x-auth-token': token },
				params: { state: requestedState, pending: isPending },
			})
			.then((response) => {
				dispatch(setArticles(response.data.articles));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};

//Get a single article to display/edit
export const getArticle = (token, artID) => {
	if (artID === 'add') {
		return (dispatch) => dispatch(setArticle(null));
	} else {
		return (dispatch) =>
			axios
				.get(`${REACT_APP_API_URL}/articles/${artID}`, {
					headers: { 'x-auth-token': token },
				})
				.then((response) => {
					dispatch(setArticle(response.data.article));
				})
				.catch(function (error) {
					dispatch(setErrorMessage('A problem has occurred'));
				});
	}
};

//Update one article
export const updateArticle = (token, artID, data) => {
	return (dispatch) =>
		axios
			.put(`${REACT_APP_API_URL}/articles/${artID}`, data, {
				headers: {
					'x-auth-token': token,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 400) {
					dispatch(setErrorMessage(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred'));
				}
			});
};

//Create one article
export const createArticle = (token, data) => {
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/articles`, data, {
				headers: {
					'x-auth-token': token,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 400) {
					dispatch(setErrorMessage(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred'));
				}
			});
};

//Validate article
export const validateArticle = (token, artID) => {
	return (dispatch) =>
		axios
			.post(
				`${REACT_APP_API_URL}/articles/${artID}/validate`,
				{},
				{
					headers: { 'x-auth-token': token },
				}
			)
			.then((response) => {
				dispatch(setMessage(response.data));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

//Refuse article
export const refuseArticle = (token, artID) => {
	return (dispatch) =>
		axios
			.post(
				`${REACT_APP_API_URL}/articles/${artID}/refuse`,
				{},
				{
					headers: { 'x-auth-token': token },
				}
			)
			.then((response) => {
				dispatch(setMessage(response.data));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

//Delete article
export const deleteArticle = (token, artID) => {
	return (dispatch) =>
		axios
			.delete(`${REACT_APP_API_URL}/articles/${artID}`, {
				headers: { 'x-auth-token': token, 'Content-Type': 'application/json' },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};
