import React, { useRef, useState, useEffect } from 'react';
import { DrawingManager, GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { transformBddGeolocDatas, transformGeolocDatasToBddDatas } from './Utils/functions';
import { defaultCenter, containerStyle, polygonOptions } from './Utils/const';
const libraries = ['places', 'drawing'];
const { REACT_APP_API_KEY } = process.env;

const Geofootprint = ({ formInputs, setFormInputs, newGeofootprint, setNewGeofootprint, shape, isRadio }) => {
	const mapRef = useRef();
	const polygonRefs = useRef([]);
	const activePolygonIndex = useRef();
	const drawingManagerRef = useRef();
	const [polygons, setPolygons] = useState([]);
	const [geofootprintInput, setGeofootprintInput] = useState('');
	const [center, setCenter] = useState(defaultCenter);

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: REACT_APP_API_KEY,
		libraries,
	});

	useEffect(() => {
		if (shape) {
			setPolygons((oldArray) => [...oldArray, transformBddGeolocDatas(shape)]);
		}
	}, [shape]);

	useEffect(() => {
		if (isRadio ? formInputs.radio.geofootprint : formInputs.geofootprint) {
			setGeofootprintInput(isRadio ? formInputs.radio.geofootprint : formInputs.geofootprint);
		}
	}, [formInputs, isRadio]);

	const drawingManagerOptions = {
		polygonOptions: polygonOptions,
		drawingControl: true,
		drawingControlOptions: {
			position: window.google?.maps?.ControlPosition?.TOP_CENTER,
			drawingModes: [window.google?.maps?.drawing?.OverlayType?.POLYGON],
		},
	};

	const onLoadMap = (map) => {
		mapRef.current = map;
	};
	const onLoadPolygon = (polygon, index) => {
		polygonRefs.current[index] = polygon;
	};
	const onClickPolygon = (index) => {
		activePolygonIndex.current = index;
	};
	const onLoadDrawingManager = (drawingManager) => {
		drawingManagerRef.current = drawingManager;
	};
	const updateCurrentPolygon = (newPolygon) => {
		setGeofootprintInput('');
		setNewGeofootprint(transformGeolocDatasToBddDatas(newPolygon));
		isRadio
			? setFormInputs((prevInputs) => ({
					...prevInputs,
					radio: {
						...prevInputs.radio,
						geofootprint: transformGeolocDatasToBddDatas(newPolygon),
					},
				}))
			: setFormInputs((prevInputs) => ({
					...prevInputs,
					geofootprint: transformGeolocDatasToBddDatas(newPolygon),
				}));
		setGeofootprintInput(isRadio ? formInputs.radio.geofootprint : formInputs.geofootprint);
	};
	const onOverlayComplete = ($overlayEvent) => {
		drawingManagerRef.current.setDrawingMode(null);
		if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
			const newPolygon = $overlayEvent.overlay
				.getPath()
				.getArray()
				.map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

			// start and end point should be same for valid geojson
			const startPoint = newPolygon[0];
			newPolygon.push(startPoint);
			$overlayEvent.overlay?.setMap(null);
			setPolygons([...polygons, newPolygon]);
			updateCurrentPolygon(newPolygon);
		}
	};

	const onEditPolygon = (index) => {
		const polygonRef = polygonRefs.current[index];
		if (polygonRef) {
			const coordinates = polygonRef
				.getPath()
				.getArray()
				.map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

			const allPolygons = [...polygons];
			allPolygons[index] = coordinates;
			setPolygons(allPolygons);
			updateCurrentPolygon(allPolygons[index]);
		}
	};
	const erasePolygone = () => {
		setGeofootprintInput('');
		setNewGeofootprint('');
		setPolygons([]);
		if (isRadio) {
			setFormInputs((prevInputs) => ({
				...prevInputs,
				radio: {
					...prevInputs.radio,
					geofootprint: '',
				},
			}));
		} else
			setFormInputs((prevInputs) => ({
				...prevInputs,
				geofootprint: '',
			}));
	};
	const restorePolygone = () => {
		if (drawingManagerRef.current) {
			drawingManagerRef.current.setDrawingMode(null);
		}
		if (shape) {
			setPolygons([]);
			setPolygons((oldArray) => [...oldArray, transformBddGeolocDatas(shape)]);
			setGeofootprintInput(shape);
			setNewGeofootprint('');
		} else erasePolygone();
	};

	const getPolygonValues = () => {
		return polygons;
	};

	return isLoaded ? (
		<>
			<div className="d-flex justify-content-start">
				<h6>Geofootprint</h6>
			</div>
			<div className="row">
				<div className=" col-7" style={{ position: 'relative' }}>
					<GoogleMap
						zoom={6}
						center={center}
						onLoad={onLoadMap}
						mapContainerStyle={containerStyle}
						onTilesLoaded={() => setCenter(null)}
					>
						<DrawingManager
							onLoad={onLoadDrawingManager}
							onOverlayComplete={onOverlayComplete}
							options={drawingManagerOptions}
						/>
						{polygons.map((iterator, index) => (
							<Polygon
								key={index}
								onLoad={(event) => onLoadPolygon(event, index)}
								onMouseDown={() => onClickPolygon(index)}
								onMouseUp={() => onEditPolygon(index)}
								onDragEnd={() => onEditPolygon(index)}
								options={polygonOptions}
								paths={iterator}
								draggable
								editable
							/>
						))}
					</GoogleMap>
				</div>
				<div className="col-5 d-flex flex-column align-items-start">
					<textarea
						className="form-control"
						name="geofootprint"
						id="geofootprint"
						rows="4"
						value={newGeofootprint ? newGeofootprint : geofootprintInput}
						onChange={() => getPolygonValues()}
					/>
					<input onClick={erasePolygone} type="button" value="Effacer le polygone" className="my-5" />
					<input onClick={restorePolygone} type="button" value="Rétablir" />
				</div>
			</div>
		</>
	) : null;
};

export default Geofootprint;
