import React from 'react';
import { logosInfos, radiosInfos, streamsInfos } from '../../utils/const';
import { dateFormattingWithMnSec } from '../../utils/functions';
import Loader from '../../assets/loading-new.gif';
import AudioPlayer from '../AudioPlayer';

function PendingRadiosInnerTable({ pendings, categories, row, audioStopped }) {
	const findRadio = (radios, row) => {
		return radios.find((radio) => radio.rpID === row.original.rpID);
	};
	const findCategory = (catId) => {
		return categories.find((cat) => cat.id === catId).nom;
	};

	return (
		<div>
			{radiosInfos.map((info, i) =>
				findRadio(pendings, row)[info.name] ? (
					<div key={`radio${i}`} className="d-flex align-items-center my-3 justify-content-start">
						<div className="w-50 me-5 text-end">{info.title} :</div>
						<div className="w-100 d-flex justify-content-start text-start">
							{info.name.includes('cat_')
								? findCategory(findRadio(pendings, row)[info.name])
								: info.name === 'updatedAt'
									? dateFormattingWithMnSec(findRadio(pendings, row)[info.name])
									: findRadio(pendings, row)[info.name]}
						</div>
					</div>
				) : null
			)}
			{findRadio(pendings, row).streams.map((radioStream, i) => (
				<div key={`index${i}`}>
					<div className="d-flex justify-content-center">
						{streamsInfos.map((stream, i) =>
							radioStream[stream.name] ? (
								stream.name === 'type' ? (
									<div key={`type${i}`} className="fw-bold w-50 me-5 text-end">
										{radioStream[stream.name]} :
									</div>
								) : null
							) : null
						)}
						{streamsInfos.map((stream, i) =>
							radioStream[stream.name] ? (
								stream.name === 'url' ? (
									<div
										key={`url${i}`}
										className="text-start w-100 d-flex justify-content-start flex-column"
									>
										<a key={`stream${i}`} href={radioStream[stream.name]} target="blank">
											{radioStream[stream.name]}
										</a>
									</div>
								) : null
							) : null
						)}
					</div>
					<div className="d-flex justify-content-center my-3">
						<div className="w-50 me-5 text-end">Type :</div>
						<div className="text-start w-100 d-flex justify-content-start">
							{streamsInfos.map((stream, i) =>
								radioStream[stream.name] ? (
									stream.name === 'mime' ? (
										<div key={`mime${i}`}>
											<div className="me-1">{radioStream[stream.name]} à</div>
										</div>
									) : null
								) : null
							)}
							{streamsInfos.map((stream, i) =>
								radioStream[stream.name] ? (
									stream.name === 'bitrate' ? (
										<div key={`bitrate${i}`} className="">
											<div>{radioStream[stream.name]}Kbits</div>
										</div>
									) : null
								) : null
							)}
						</div>
					</div>
					<div className="d-flex justify-content-center">
						{streamsInfos.map((stream, i) =>
							radioStream[stream.name] ? (
								stream.name === 'url' ? (
									<div key={`url${i}`} className="text-start w-100 d-flex justify-content-center">
										<AudioPlayer
											audioStopped={audioStopped}
											url={radioStream[stream.name]}
											radioStream={radioStream}
										/>
									</div>
								) : null
							) : null
						)}
					</div>
				</div>
			))}
			{logosInfos.map((dim, i) =>
				findRadio(pendings, row)?.[dim.name] ? (
					<div key={`logo${i}`} className="d-flex align-items-center my-3 justify-content-start">
						<div className="w-50 me-5 text-end">Logo {dim.size}:</div>
						<div className="w-100 d-flex justify-content-start">
							{findRadio(pendings, row)[dim.name] ? (
								<img
									src={findRadio(pendings, row)[dim.name]}
									alt={dim.size}
									width={dim.width}
									height={dim.height}
								/>
							) : (
								<img src={Loader} border="" width={dim.width} height={dim.height} alt="loader" />
							)}
						</div>
					</div>
				) : null
			)}
		</div>
	);
}

export default PendingRadiosInnerTable;
