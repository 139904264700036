import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import InsideMenu from '../../../components/InsideMenu';
import { getRadio, createRadioProgram } from '../../../services/Radios';
import { getCategories } from '../../../services/Categories';
import {
	selectRadio,
	selectToken,
	selectCategories,
	selectRadiosMessage,
	selectRadiosErrorMessage,
	selectRadiosErrorMessage400,
} from '../../../selectors';
import { setErrorMessage, setMessage, setErrorMessage400 } from '../../../features/radiosSlice';
import CategoriesInputs from '../children/CategoriesInputs';
import UrlInputs from '../children/UrlInputs';
import GeneralInfosInputs from '../children/GeneralInfosInputs';
import ImgInputs from '../children/ImgInputs';
import HoursInputs from '../children/HoursInputs';
import WeekInputs from '../children/WeekInputs';
import { modalStyles } from '../../../utils/const';
import { deleteWhiteSpacesInObject } from '../../../utils/functions';

function CreateRadioProgram() {
	const isCreation = true;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const radioData = useSelector(selectRadio);
	const successMessage = useSelector(selectRadiosMessage);
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const errorMessage400 = useSelector(selectRadiosErrorMessage400);
	const categories = useSelector(selectCategories);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const [currentRadioName, setCurrentRadioName] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [formInputs, setFormInputs] = useState({
		nom_long: '',
		nom_moyen: '',
		nom_court: '',
		description_courte: '',
		description_longue: '',
		image_1400: {},
		cat_1: undefined,
		cat_2: undefined,
		heure_debut: '',
		heure_fin: '',
		lundi: 'N',
		mardi: 'N',
		mercredi: 'N',
		jeudi: 'N',
		vendredi: 'N',
		samedi: 'N',
		dimanche: 'N',
		feed_podcast: '',
	});
	useEffect(() => {
		dispatch(setErrorMessage(''));
		dispatch(setMessage(''));
	}, [dispatch]);
	useEffect(() => {
		if (token) {
			dispatch(getRadio(token, params.rpID));
			dispatch(getCategories(token, 'podcasts'));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, params]);

	useEffect(() => {
		if (radioData) {
			setCurrentRadioName(radioData.data.nom);
		}
	}, [dispatch, radioData]);

	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/radios');
	}

	const updateFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
	};
	const updateFormInputCat = (name, value) => {
		setFormInputs((prevInputs) => ({ ...prevInputs, [name]: value === 'undefined' ? undefined : Number(value) }));
	};
	const handleChangeFormInputs = (event) => {
		updateFormInput(event.target.name, event.target.value);
	};
	const handleChangeCatInputs = (event) => {
		updateFormInputCat(event.target.name, event.target.value);
	};
	const handleChangeImgInputs = (event) => {
		updateFormInput(event.target.name, event.target.files[0]);
	};
	const submitForm = (event) => {
		event.preventDefault();
		deleteWhiteSpacesInObject(formInputs);
		dispatch(createRadioProgram(token, params.rpID, formInputs));
		setIsModalOpen(true);
	};
	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorMessage400([]));
	};
	return (
		<div>
			<InsideMenu
				homeLink={`/radios/${params.rpID}/programs`}
				title={`Gestion Des Programmes - ${currentRadioName}`}
				links={[
					{ name: 'Retour à la radio', path: `/radios/edit/${params.rpID}` },
					{ name: 'Retour aux programmes', path: `/radios/${params.rpID}/programs` },
				]}
			/>
			<div className="bg-white rounded-4 p-5 mx-4">
				<form onSubmit={submitForm}>
					<GeneralInfosInputs handleChangeFormInputs={handleChangeFormInputs} formInputs={formInputs} />
					<ImgInputs
						formInputs={formInputs}
						handleChangeImgInputs={handleChangeImgInputs}
						isCreation={isCreation}
					/>
					<CategoriesInputs
						formInputs={formInputs}
						handleChangeCatInputs={handleChangeCatInputs}
						categories={categories}
					/>
					<HoursInputs handleChangeFormInputs={handleChangeFormInputs} formInputs={formInputs} />
					<WeekInputs handleChangeFormInputs={handleChangeFormInputs} formInputs={formInputs} />
					<UrlInputs formInputs={formInputs} handleChangeFormInputs={handleChangeFormInputs} />
					<div className="d-flex mt-5">
						<input className="formSubmitButton" type="submit" value="Enregistrer" />
					</div>
				</form>
			</div>
			<div>
				<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
					{successMessage ? (
						<div className="text-success">
							Votre création a bien été effectuée.
							<br />
							<Link
								to={`/radios/${params.rpID}/programs`}
								className="fs-6 text-decoration-none "
								onClick={eraseErrorMessage}
							>
								Retour à la page programmes
							</Link>
						</div>
					) : errorMessage ? (
						<div className="mt-5">
							<div className="text-danger">{errorMessage}</div>
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : errorMessage400?.length > 0 ? (
						<div>
							<div>Les erreurs suivantes sont apparues dans les données de votre formulaire:</div>
							{errorMessage400.map((message, index) => (
								<div key={index} className="my-2">
									<div className="text-danger"> - {message.msg}</div>
								</div>
							))}
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : null}
				</Modal>
			</div>
		</div>
	);
}

export default CreateRadioProgram;
