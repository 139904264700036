import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Modal from 'react-modal';
import { RadiosColumnsInfos, globalTableStyle, globalThStyle, globalTdStyle } from '../../utils/const';
import {
	selectRadios,
	selectRadiosErrorMessage,
	selectRadiosMessage,
	selectRadiosLogoOnly,
	selectToken,
	selectLogos,
	selectRadiosServerErrorMessage,
} from '../../selectors';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import {
	setRadio,
	setLocals,
	setMessage,
	setErrorMessage,
	setErrorServerMessage,
	setRadioPrograms,
	setPodcasts,
	setFrequenciesFM,
	setFrequenciesDAB,
} from '../../features/radiosSlice';
import { setErrorAnalyticsMessage } from '../../features/analyticsSlice';
import { getRadios, getLogos, deleteRadio } from '../../services/Radios';
import { modalStyles } from '../../utils/const';
import InsideMenu from '../../components/InsideMenu';
import GlobalFilter from '../../components/GlobalFilter';
import ElementsToShow from '../../components/ElementsToShow';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import GoToTop from '../../components/GoToTop';
import { scrollTop, compareIgnoreCase, numericSort } from '../../utils/functions';

function Radios({ type, isAdmin }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	Modal.setAppElement('body');
	const [data, setData] = useState([]);
	const [logos, setLogos] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [radioToDelete, setRadioToDelete] = useState(null);
	const [isAboutToDeleteRadio, setIsAboutToDeleteRadio] = useState(false);
	const radios = useSelector(selectRadios);
	const radioLogoOnly = useSelector(selectRadiosLogoOnly);
	const logosData = useSelector(selectLogos);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const hiddenColumns = type === 3 ? ['Slogan'] : [];

	useEffect(() => {
		scrollTop();
	}, []);
	useEffect(() => {
		setIsLoaded(false);
		setData([]);
	}, [type]);
	useEffect(() => {
		if (token) {
			if (!type) {
				dispatch(getRadios(token));
			} else if (type === 3) {
				dispatch(getRadios(token, type));
			}
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, type]);

	useEffect(() => {
		if (token) {
			dispatch(getLogos(token));
		}
	}, [dispatch, token]);

	useEffect(() => {
		dispatch(setRadio(null));
		dispatch(setLocals(null));
		dispatch(setRadioPrograms(null));
		dispatch(setPodcasts(null));
		dispatch(setMessage(null));
		dispatch(setErrorServerMessage(''));
		dispatch(setFrequenciesFM(null));
		dispatch(setFrequenciesDAB(null));
		dispatch(setErrorAnalyticsMessage(null));
	}, [dispatch]);

	useEffect(() => {
		if (radios && !type) {
			setData(radios?.radios);
			setIsLoaded(true);
		} else if (radioLogoOnly && type === 3) {
			setData(radioLogoOnly?.radios);
			setIsLoaded(true);
		}
	}, [dispatch, radios, radioLogoOnly, type]);

	useEffect(() => {
		if (logosData) {
			setLogos(logosData);
		}
	}, [dispatch, logosData]);

	const columns = useMemo(() => RadiosColumnsInfos, []);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, hiddenColumns: hiddenColumns },
			sortTypes: {
				alphanumeric: (row1, row2, columnName) => {
					if (columnName === 'rpid') {
						return numericSort(row1.values[columnName], row2.values[columnName]);
					} else return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
				},
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const navigateRadioManager = (row) => {
		navigate(`/radios/edit/${row.original.rpID}`);
	};
	const navigateRadioLogoOnlyEdit = (row) => {
		navigate(`/radios_logo_only/${row.original.rpID}/edit`);
	};
	const navigateRadioPrograms = (row) => {
		navigate(`/radios/${row.original.rpID}/programs`);
	};

	const navigateRadioPodcasts = (row) => {
		navigate(`/radios/${row.original.rpID}/podcasts`);
	};

	const navigateToRadioDecrochages = (row) => {
		navigate(`/radios/${row.original.rpID}/locals`);
	};

	const navigateFrequencies = (row) => {
		navigate(`/radios/${row.original.rpID}/frequencies/edit`);
	};

	const navigateFrequenciesLogoOnly = (row) => {
		navigate(`/radios_logo_only/${row.original.rpID}/frequencies/edit`);
	};

	const deleteCurrentRadio = (row) => {
		setRadioToDelete({ rpID: row.original.rpID, nom: row.original.nom });
		setIsModalOpen(true);
		setIsAboutToDeleteRadio(true);
	};
	const closeModal = () => {
		setIsModalOpen(false);
	};

	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorServerMessage(''));
		dispatch(setMessage(''));
	};
	const actions =
		type === 3
			? [
					{ funct: navigateRadioLogoOnlyEdit, name: 'Modifier' },
					{ funct: navigateFrequenciesLogoOnly, name: 'Fréquences' },
					{ funct: deleteCurrentRadio, name: 'Supprimer' },
				]
			: [
					{ funct: navigateRadioManager, name: 'Modifier' },
					{ funct: navigateRadioPrograms, name: 'Programmes' },
					{ funct: navigateRadioPodcasts, name: 'Podcasts' },
					{ funct: navigateToRadioDecrochages, name: 'Décrochages' },
					{ funct: navigateFrequencies, name: 'Fréquences' },
					isAdmin ? { funct: deleteCurrentRadio, name: 'Supprimer' } : null,
				];

	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to="/radios" className="fs-5 " onClick={() => dispatch(setErrorServerMessage(''))}>
					Retour
				</Link>
			</div>
		);
	}

	return (
		<div>
			{type === 3 ? (
				<InsideMenu
					homeLink={'/radios_logo_only'}
					links={[{ name: "Ajout d'une radio Logo only", path: '/radios_logo_only/add' }]}
				/>
			) : (
				<InsideMenu
					homeLink={'/radios'}
					title={'Gestion des Radios'}
					links={[isAdmin ? { name: "Ajout d'une radio", path: '/radios/add' } : null]}
				/>
			)}

			<div className="overflow-x-scroll d-flex justify-content-center flex-column align-items-column bg-white rounded-4 p-5 mx-4">
				<div className="sorteWrapper">
					<ElementsToShow pageSize={pageSize} setPageSize={setPageSize} />
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
						dataName={'radios'}
					/>
				</div>
				<Table
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
					page={page}
					tableStyle={globalTableStyle}
					thStyle={globalThStyle}
					tdStyle={globalTdStyle}
					actions={actions}
					isLoaded={isLoaded}
					radiosLogos={logos}
				/>
				<Pagination
					gotoPage={gotoPage}
					previousPage={previousPage}
					nextPage={nextPage}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					pageCount={pageCount}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
				/>
			</div>
			<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				{isAboutToDeleteRadio ? (
					<>
						<div className="fw-bold mb-2">
							Etes vous sur de vouloir supprimer la radio&nbsp;
							{radioToDelete?.rpID}-"{radioToDelete?.nom}" ?
						</div>
						<div className="d-flex">
							<button
								className="cancelDeleteProgramButton me-3"
								onClick={() => {
									setIsModalOpen(false);
									setIsAboutToDeleteRadio(false);
								}}
							>
								Retour
							</button>
							<button
								className="deleteProgramButton"
								onClick={() => {
									dispatch(deleteRadio(token, radioToDelete.rpID));
									setIsAboutToDeleteRadio(false);
									eraseErrorMessage();
								}}
							>
								Oui
							</button>
						</div>
					</>
				) : successMessage ? (
					<div>
						<div className="text-success mb-2">Opération réussie.</div>
						<button
							className="btn btn-secondary"
							onClick={() => {
								setIsModalOpen(false);
								if (!type) {
									dispatch(getRadios(token));
								} else if (type === 3) {
									dispatch(getRadios(token, type));
								}
								eraseErrorMessage();
							}}
						>
							Retour
						</button>
					</div>
				) : (
					errorMessage && (
						<div className="mt-5">
							<div className="fs-4 text-danger mb-4">{errorMessage}</div>
							<button
								className="btn btn-secondary"
								onClick={() => {
									setIsModalOpen(false);
									if (!type) {
										dispatch(getRadios(token));
									} else if (type === 3) {
										dispatch(getRadios(token, type));
									}
									eraseErrorMessage();
								}}
							>
								Retour
							</button>
						</div>
					)
				)}
			</Modal>
			<GoToTop />
		</div>
	);
}

export default Radios;
