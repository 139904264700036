import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import './style.css';

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter, dataName }) {
	const TWO_HUNDRED_MS = 200;
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, TWO_HUNDRED_MS);

	return (
		<span className="globalFilter">
			Rechercher:{' '}
			<input
				value={value || ''}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={`${count} ${dataName}...`}
				style={{
					fontSize: '0.8rem',
					border: '1',
					marginBottom: '10px',
				}}
			/>
		</span>
	);
}

export default GlobalFilter;
