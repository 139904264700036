import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { selectToken, selectPodcastsStatistics, selectAnalyticsError } from '../../selectors';
import { setErrorAnalyticsMessage } from '../../features/analyticsSlice';
import InsideMenu from '../../components/InsideMenu';
import { getPodcastsStatistics } from '../../services/Analytics';
import { getLogos } from '../../services/Radios';
import { dateFormattingTwoDaysAgo, scrollTop } from '../../utils/functions';
import SelectedDatesForm from '../../components/Analytics/SelectedDatesForm';
import RpSpinner from '../../components/Spinner';
import GoToTop from '../../components/GoToTop';
import LogosCarousel from '../../components/Analytics/LogosCarousel';

function PodcastsStatistics() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const statistics = useSelector(selectPodcastsStatistics);
	const serverErrorMessage = useSelector(selectAnalyticsError);
	const [isLoaded, setIsLoaded] = useState(false);
	const [podcastsStatistics, setPodcastsStatistics] = useState('');
	const getScreenSize = useCallback(() => {
		return window.innerWidth;
	}, []);
	const [windowSize, setWindowSize] = useState(getScreenSize);
	const [formDates, setFormDates] = useState({ start: dateFormattingTwoDaysAgo(), end: dateFormattingTwoDaysAgo() });
	useEffect(() => {
		scrollTop();
	}, []);
	useEffect(() => {
		function handleResize() {
			setWindowSize(getScreenSize());
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [windowSize, getScreenSize]);

	useEffect(() => {
		if (token) {
			dispatch(getLogos(token));
			if (sessionStorage.getItem('startDate')) {
				dispatch(
					getPodcastsStatistics(token, sessionStorage.getItem('startDate'), sessionStorage.getItem('endDate'))
				);
			} else {
				dispatch(getPodcastsStatistics(token, dateFormattingTwoDaysAgo(), dateFormattingTwoDaysAgo()));
				sessionStorage.setItem('startDate', dateFormattingTwoDaysAgo());
				sessionStorage.setItem('endDate', dateFormattingTwoDaysAgo());
			}
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate]);

	useEffect(() => {
		if (statistics) {
			setIsLoaded(true);
			setPodcastsStatistics(statistics?.podcasts);
		}
	}, [dispatch, statistics]);

	const submitForm = (event) => {
		event.preventDefault();
		setIsLoaded(false);
		dispatch(getPodcastsStatistics(token, formDates.start, formDates.end));
		sessionStorage.setItem('startDate', formDates.start);
		sessionStorage.setItem('endDate', formDates.end);
	};

	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to="/radios" className="fs-5 " onClick={() => dispatch(setErrorAnalyticsMessage(''))}>
					Retour
				</Link>
			</div>
		);
	}
	return (
		<div>
			<InsideMenu homeLink={`/analytics/podcasts_regions`} title={'Stats Podcasts Par Région'} links={[]} />
			<SelectedDatesForm
				key={'dates'}
				submitForm={submitForm}
				setFormDates={setFormDates}
				formDates={formDates}
			/>
			{!isLoaded ? (
				<div className="d-flex flex-column align-items center">
					<RpSpinner />
					<h4 className="bg-white pb-3">
						Les données sont en cours de chargement. <br />
						Merci de patienter...
					</h4>
				</div>
			) : (
				<>
					{podcastsStatistics &&
						podcastsStatistics.map((podcast, index) => {
							return (
								<div key={index}>
									<LogosCarousel
										key={podcast.region.substring(0, 6)}
										type="podcasts"
										title={`Podcasts les + écoutés - ${podcast.region}`}
										data={podcast.feed}
										isLogosLoaded={isLoaded}
									/>
								</div>
							);
						})}
				</>
			)}
			<GoToTop />
		</div>
	);
}

export default PodcastsStatistics;
