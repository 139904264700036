import React, { useState, useEffect } from 'react';
import { MdDeleteForever } from 'react-icons/md';

const FrequencyInput = (data, name, index, type, updateValue) => {
	return (
		<input
			id={`${type}_${name}${index}`}
			className="form-control formInputFrequencies"
			name={name}
			maxLength={name === 'DAB_eid' || name === 'DAB_sid' || name === 'FM_picode' ? 4 : undefined}
			value={data || ''}
			placeholder={name === 'ville' ? '+Ajouter' : null}
			autoFocus={name === 'ville' ? true : false}
			onKeyDown={(e) => {
				if (e.key === 'Delete') {
					updateValue(index, type, '', name);
				}
			}}
			onChange={(e) => {
				updateValue(index, type, e.target.value, name);
			}}
			disabled={name === 'FM_bearer' || name === 'DAB_bearer' ? true : false}
			style={{ backgroundColor: name === 'FM_bearer' || name === 'DAB_bearer' ? '#f8f9fa' : '#fff' }}
		/>
	);
};
const FrequencyCheckInput = (data, name, index, type, updateValue) => {
	const isChecked = data === 'O' ? true : false;

	return (
		<input
			name={name}
			type="checkbox"
			checked={isChecked}
			onChange={(e) => {
				updateValue(index, type, e.target.checked, name);
			}}
		/>
	);
};

const FrequencyRow = (type, data, index, updateValue) => {
	if (type === 'fm') {
		return (
			<>
				<td className="freqVille">{FrequencyInput(data?.ville, 'ville', index, type, updateValue)}</td>
				<td className="freqFMFreq">
					{FrequencyInput(data?.FM_frequences, 'FM_frequences', index, type, updateValue)}
				</td>
				<td className="freqFM_picode">
					{FrequencyInput(data?.FM_picode, 'FM_picode', index, type, updateValue)}
				</td>
				<td className="freqFM_bearer">
					{FrequencyInput(data?.FM_bearer, 'FM_bearer', index, type, updateValue)}
				</td>
				<td className="freqAmazon">{FrequencyCheckInput(data?.amazon, 'amazon', index, type, updateValue)}</td>
			</>
		);
	} else if (type === 'dab') {
		return (
			<>
				<td className="freqVille">{FrequencyInput(data?.ville, 'ville', index, type, updateValue)}</td>
				<td className="freqDAB_eid">{FrequencyInput(data?.DAB_eid, 'DAB_eid', index, type, updateValue)}</td>
				<td className="freqDAB_sid">{FrequencyInput(data?.DAB_sid, 'DAB_sid', index, type, updateValue)}</td>
				<td className="freqDAB_bearer">
					{FrequencyInput(data?.DAB_bearer, 'DAB_bearer', index, type, updateValue)}
				</td>
			</>
		);
	}
};

export default function FrequenciesTable(props) {
	const [indexCount, setIndexCount] = useState(0);
	useEffect(() => {
		setIndexCount(props.data.length);
	}, [props.data]);
	useEffect(() => {
		if (indexCount === props.data.length && !!props.setIsLastData) {
			props.setIsLastData(true);
		}
	}, [props, indexCount]);
	const headArray =
		props.type === 'fm'
			? ['Ville', 'Fréquence FM', 'FM picode', 'FM Bearer', 'Prioritaire?', 'Action']
			: props.type === 'dab'
				? ['Ville', 'DAB eid', 'DAB sid', 'DAB+ Bearer', 'Action']
				: [];
	return (
		<table id={`freq_${props.type}`}>
			<thead>
				<tr key={`${props.type}_freq_header`} className="bg-light text-start">
					{headArray.map((col, index) => {
						return (
							<th key={`th_${props.type}${index}`}>
								<div className="text-center py-2">{col}</div>
							</th>
						);
					})}
				</tr>
			</thead>
			<tbody>
				{props.data.map((frequency, index) => {
					return (
						<tr key={`${props.type}_freq_${index}`}>
							{FrequencyRow(props.type, frequency, index, props.updateValue, props.data.length)}
							<td
								onClick={() => props.delete(props.type, index)}
								className="freqActions"
								title="supprimer"
								key={`${props.type}_freqAction_${index}`}
							>
								<MdDeleteForever size={'1.2rem'} key={`${props.type}_del_${index}`} />
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}
