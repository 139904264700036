import './style.css';

export default function RpSpinner() {
	return (
		<div className="d-flex flex-column align-items-center">
			<div className="bg-white pt-5 w-100">
				<h4>
					Les données sont en cours de chargement. <br />
					Merci de patienter...
				</h4>
			</div>

			<div className="rp-triangle">
				<div className="triangle">
					<div className="bg-triangle"></div>
					<div className="band1"></div>
					<div className="band2"></div>
					<div className="band3"></div>
					<div className="band4"></div>
					<div className="band5"></div>
				</div>
			</div>
		</div>
	);
}
