import React from 'react';

function CategoriesInputs({ formInputs, handleChangeFormInputs, categories }) {
	const catIds = [1, 2, 3, 4];

	return (
		<div className="row">
			{catIds.map((id) => (
				<div className="mb-3 mt-2" key={'cat_' + id}>
					<label className="form-label d-flex justify-content-start">Catégorie {id}</label>
					<select
						className="form-select"
						name={`cat_${id}`}
						value={formInputs.radio['cat_' + id]}
						onChange={handleChangeFormInputs}
					>
						<option value={0}>-----</option>
						{categories &&
							categories.map((categorie, id) => (
								<option key={id} value={categorie.id}>
									{categorie.nom}
								</option>
							))}
					</select>
				</div>
			))}
		</div>
	);
}

export default CategoriesInputs;
