import React, { useEffect, useState, useMemo } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import {
	getEpisodes,
	deleteEpisode,
	getPodcast,
	activatePodcastEpisode,
	desactivatePodcastEpisode,
} from '../../services/Podcasts';
import {
	selectToken,
	selectEpisodes,
	selectRadiosErrorMessage,
	selectRadiosMessage,
	selectRadiosServerErrorMessage,
	selectPodcast,
} from '../../selectors';
import { setMessage, setErrorMessage, setErrorServerMessage } from '../../features/radiosSlice';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import {
	PodcastEpisodesColumnsInfos,
	globalTableStyle,
	globalThStyle,
	globalTdStyle,
	modalStyles,
} from '../../utils/const';
import InsideMenu from '../../components/InsideMenu';
import GlobalFilter from '../../components/GlobalFilter';
import ElementsToShow from '../../components/ElementsToShow';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import GoToTop from '../../components/GoToTop';
import { scrollTop, compareIgnoreCase, numericSort } from '../../utils/functions';
import './style.css';

function PodcastEpisodes() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);
	const currentPodcast = useSelector(selectPodcast);
	const episodesData = useSelector(selectEpisodes);
	const [isLoaded, setLoaded] = useState(false);
	const [data, setData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentPodcastName, setCurrentPodcastName] = useState('');
	const [isAboutToDeleteEpisode, setIsAboutToDeleteEpisode] = useState(false);
	const [episodeId, setEpisodeId] = useState('');
	const [isEpisodesActifs, setIsEpisodesActifs] = useState('O');

	useEffect(() => {
		scrollTop();
	}, []);
	useEffect(() => {
		if (token) {
			dispatch(getEpisodes(token, params.rpID, params.id, isEpisodesActifs));
			dispatch(getPodcast(token, params.rpID, params.id));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, params, isEpisodesActifs]);

	useEffect(() => {
		if (currentPodcast) {
			setCurrentPodcastName(currentPodcast.nom);
		}
	}, [dispatch, currentPodcast]);
	useEffect(() => {
		if (episodesData) {
			setData(episodesData);
			setLoaded(true);
		}
	}, [episodesData]);
	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/radios');
	}

	const columns = useMemo(() => PodcastEpisodesColumnsInfos, []);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
			sortTypes: {
				alphanumeric: (row1, row2, columnName) => {
					if (columnName === 'ID') {
						return numericSort(row1.values[columnName], row2.values[columnName]);
					} else return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
				},
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const deleteCurrentEpisode = (row) => {
		setIsAboutToDeleteEpisode(true);
		setEpisodeId(row.original.id);
		setIsModalOpen(true);
	};

	const moveCurrentEpisode = (row) => {
		isEpisodesActifs === 'O'
			? dispatch(desactivatePodcastEpisode(token, params.rpID, params.id, row.original.id))
			: dispatch(activatePodcastEpisode(token, params.rpID, params.id, row.original.id));
		setIsModalOpen(true);
	};
	const actions = [
		{ funct: deleteCurrentEpisode, name: 'Supprimer cet épisode' },
		currentPodcast?.actif === 'O'
			? {
					funct: moveCurrentEpisode,
					name: isEpisodesActifs === 'N' ? 'Activer cet épisode' : 'Désactiver cet épisode',
				}
			: null,
	];
	const eraseErrorMessage = () => {
		dispatch(setMessage(''));
		dispatch(setErrorMessage(''));
		dispatch(setErrorServerMessage(''));
	};
	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link
					to={`/radios/${params.rpID}/podcasts`}
					className="fs-5"
					onClick={() => dispatch(setErrorServerMessage(''))}
				>
					Retour
				</Link>
			</div>
		);
	}
	return (
		<div>
			<InsideMenu
				homeLink={`/radios/${params.rpID}/podcasts`}
				title={`Voir Les Episodes - ${currentPodcastName ? currentPodcastName : null}`}
				links={[
					{ name: 'Retour aux podcasts', path: `/radios/${params.rpID}/podcasts` },
					{
						name: `Supprimer tous les épisodes de ce feed (${
							currentPodcastName ? currentPodcastName : null
						})`,
						path: `/radios/${params.rpID}/podcasts/${params.id}/delete`,
					},
				]}
			/>
			<div className="overflow-x-scroll scrollNone d-flex justify-content-center flex-column bg-white rounded-4 p-5 mx-4">
				<div
					className="otherPodcastsStatus text-start w-100 mb-4"
					onClick={() => {
						setIsEpisodesActifs(isEpisodesActifs === 'O' ? 'N' : 'O');
						setLoaded(false);
					}}
				>
					{isEpisodesActifs === 'O' ? 'Voir épisodes désactivés' : 'Voir épisodes actifs'}
				</div>
				<div className="sorteWrapper">
					<ElementsToShow pageSize={pageSize} setPageSize={setPageSize} />
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
						dataName={'programmes'}
					/>
				</div>
				<Table
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
					page={page}
					tableStyle={globalTableStyle}
					thStyle={globalThStyle}
					tdStyle={globalTdStyle}
					actions={actions}
					isLoaded={isLoaded}
				/>

				<Pagination
					gotoPage={gotoPage}
					previousPage={previousPage}
					nextPage={nextPage}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					pageCount={pageCount}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
				/>
			</div>
			<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				{isAboutToDeleteEpisode ? (
					<>
						<div className="fw-bold mb-2">
							Etes vous sur de vouloir supprimer le podcast id: "{episodeId}"?
						</div>
						<div className="d-flex">
							<button
								className="cancelDeletePodcastButton me-3"
								onClick={() => {
									setIsModalOpen(false);
									setEpisodeId('');
									setIsAboutToDeleteEpisode(false);
								}}
							>
								Retour
							</button>
							<button
								className="deletePodcastButton"
								onClick={() => {
									dispatch(deleteEpisode(token, Number(params.rpID), Number(params.id), episodeId));
									setEpisodeId('');
									setIsAboutToDeleteEpisode(false);
								}}
							>
								Oui
							</button>
						</div>
					</>
				) : successMessage ? (
					<div>
						<div className="text-success mb-2">Opération réussie.</div>
						<button
							className="btn btn-secondary"
							onClick={() => {
								setIsModalOpen(false);
								dispatch(getEpisodes(token, params.rpID, params.id, isEpisodesActifs));
								eraseErrorMessage();
							}}
						>
							Retour
						</button>
					</div>
				) : (
					errorMessage && (
						<div className="mt-5">
							<div className="fs-4 text-danger mb-4">{errorMessage}</div>
							<button
								className="btn btn-secondary"
								onClick={() => {
									setIsModalOpen(false);
									dispatch(getEpisodes(token, params.rpID, params.id, isEpisodesActifs));
									eraseErrorMessage();
								}}
							>
								Retour
							</button>
						</div>
					)
				)}
			</Modal>
			<GoToTop />
		</div>
	);
}

export default PodcastEpisodes;
