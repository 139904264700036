import React from 'react';
import { Area, AreaChart, YAxis, Legend, CartesianGrid, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import './style.css';

function StatsLineChart({ data, isTwoMonthBetweenDates }) {
	const formatToolTipDate = (date) => {
		let newDate = new Date(date);
		return (
			(isTwoMonthBetweenDates ? '' : newDate.getDate().toString().padStart(2, '0') + '/') +
			(newDate.getMonth() + 1).toString().padStart(2, '0') +
			'/' +
			newDate.getFullYear().toString().slice(2, 4)
		);
	};
	function formatXAxis(tickItem) {
		let newDate = new Date(tickItem);
		return formatToolTipDate(newDate);
	}

	function CustomTooltip({ payload, active }) {
		if (active) {
			return (
				<div className="customTooltip">
					<div className="toolTipTitle">{formatToolTipDate(payload[0].payload?.session_day)}</div>
					<div className="toolTipContent">
						{payload.map((data, index) => (
							<div className="d-flex align-items-center mb-3 mt-1" key={index}>
								<div className="dot me-2" style={{ backgroundColor: data?.stroke }}></div>
								{data.dataKey === 'ecoutes' ? 'Ecoutes: ' : 'Ecoutes plus de 30 secondes: '}
								<span className="ms-2 fw-bold">{data?.value}</span>
							</div>
						))}
					</div>
				</div>
			);
		}
	}
	return (
		<>
			<div className="lineChart">
				<ResponsiveContainer width="100%" height="100%">
					<AreaChart
						width={500}
						height={200}
						data={data}
						syncId="anyId"
						margin={{
							top: 0,
							right: 0,
							left: 0,
							bottom: 0,
						}}
					>
						<defs>
							<linearGradient id={`color-#f37132`} x1="0" y1="0" x2="0" y2="1">
								<stop offset="0%" stopColor="#f37132" stopOpacity={0.7}></stop>
								<stop offset="75%" stopColor="#f37132" stopOpacity={0.3}></stop>
							</linearGradient>
							<linearGradient id={`color-#dc3545`} x1="0" y1="0" x2="0" y2="1">
								<stop offset="0%" stopColor="#dc3545" stopOpacity={0.7}></stop>
								<stop offset="75%" stopColor="#dc3545" stopOpacity={0.3}></stop>
							</linearGradient>
						</defs>
						<CartesianGrid strokearray="3 3" />
						<XAxis dataKey="session_day" tickFormatter={formatXAxis} />
						<YAxis yAxisId="left" orientation="left" />
						<Tooltip
							offset="20"
							content={<CustomTooltip />}
							wrapperStyle={{ borderRadius: 20, padding: '10px' }}
							cursor={{ fill: 'rgba(196,196,196,0.5)' }}
						/>
						<Legend
							align="left"
							formatter={(value, entry, index) => (
								<span>{value === 'ecoutes' ? 'Ecoutes' : 'Ecoutes plus de 30 secondes'}</span>
							)}
							iconType="circle"
						/>
						<Area
							yAxisId="left"
							dataKey="ecoutes"
							stroke="#dc3545"
							strokeWidth={5}
							fill={`url(#color-#dc3545)`}
						/>
						<Area
							yAxisId="left"
							dataKey="ecoutes30s"
							stroke="#f37132"
							strokeWidth={5}
							fill={`url(#color-#f37132)`}
						/>
					</AreaChart>
				</ResponsiveContainer>
			</div>
		</>
	);
}

export default StatsLineChart;
