import './style.css';

function Footer({ token, isSideBarOpen }) {
	return (
		<div
			id="footer"
			className="container-fluid footer bg-light mx-auto"
			style={{
				paddingLeft:
					token && isSideBarOpen ? '280px' : token && !isSideBarOpen ? '85px' : !token ? '0px' : '0vh',
			}}
		>
			<div className="row bg-light">
				<div className="col-md-12 footer-copyright text-center mt-4 mb-0">
					Radioplayer vous présente son
					<a
						className="text-decoration-underline text-reset"
						href="https://www.radioplayer.fr/"
						target="blank"
					>
						&nbsp;site web&nbsp;
					</a>
					et ses applications
					<br />
					<a
						href="https://play.google.com/store/apps/details?id=com.radioplayer.mobile&pcampaignid=web_share"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="footer-badge-android"
							alt="Disponible sur Google Play"
							src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
						/>
					</a>
					<a href="https://apps.apple.com/fr/app/radioplayer/id6443602613" target="_blank" rel="noreferrer">
						<img
							className="footer-badge-ios"
							src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/fr-fr?releaseDate=1617753600"
							alt="Télécharger dans l'App Store"
						/>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Footer;
