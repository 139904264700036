import React, { useEffect, useState, useMemo } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { selectToken, selectUsers, usersErrorServerMessage } from '../../selectors';
import { getUsers } from '../../services/Users';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { UsersColumnsInfos, globalTableStyle, globalThStyle, globalTdStyle } from '../../utils/const';
import GlobalFilter from '../../components/GlobalFilter';
import ElementsToShow from '../../components/ElementsToShow';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { setUser } from '../../features/usersSlice';
import InsideMenu from '../../components/InsideMenu';
import { scrollTop, compareIgnoreCase } from '../../utils/functions';

function Users() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isLoaded, setLoaded] = useState(false);
	const [data, setData] = useState([]);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const users = useSelector(selectUsers);
	const errorServerMessage = useSelector(usersErrorServerMessage);

	useEffect(() => {
		scrollTop();
	}, []);
	useEffect(() => {
		if (users) {
			setData(users);
			dispatch(setUser(null));
			setLoaded(true);
		}
	}, [dispatch, users]);

	useEffect(() => {
		if (token) {
			dispatch(getUsers(token));
		} else {
			navigate('/');
		}
	}, [dispatch, navigate, token]);

	const columns = useMemo(() => UsersColumnsInfos, []);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
			sortTypes: {
				alphanumeric: (row1, row2, columnName) => {
					return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
				},
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);
	const goToUserPage = (row) => {
		navigate(`/users/edit/${row.original.email}`);
	};

	const actions = [{ funct: goToUserPage, name: 'Modifier' }];

	if (errorServerMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{errorServerMessage}</div>
				<Link to="/users" className="fs-5 ">
					Retour
				</Link>
			</div>
		);
	}
	return (
		<div>
			<InsideMenu homeLink={'/users'} title={'Gestion des Utilisateurs'} links={[]} />
			<div className="overflow-x-scroll scrollNone d-flex justify-content-center flex-column bg-white rounded-4 p-5 mx-4">
				<div className="sorteWrapper">
					<ElementsToShow pageSize={pageSize} setPageSize={setPageSize} />
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
						dataName={'utilisateurs'}
					/>
				</div>
				<Table
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
					page={page}
					tableStyle={globalTableStyle}
					thStyle={globalThStyle}
					tdStyle={globalTdStyle}
					actions={actions}
					isLoaded={isLoaded}
				/>

				<Pagination
					gotoPage={gotoPage}
					previousPage={previousPage}
					nextPage={nextPage}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					pageCount={pageCount}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
				/>
			</div>
		</div>
	);
}

export default Users;
