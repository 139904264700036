import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import InsideMenu from '../../components/InsideMenu';
import GlobalFilter from '../../components/GlobalFilter';
import ElementsToShow from '../../components/ElementsToShow';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import Modal from 'react-modal';
import { getRadio } from '../../services/Radios';
import { getLocals, getPendingsLocals, generateLocal, generateLocals, deleteLocal } from '../../services/Locals';
import {
	selectToken,
	selectRadio,
	selectLocals,
	selectPendingsLocals,
	selectRadiosServerErrorMessage,
	selectRadiosMessage,
	selectRadiosErrorMessage,
} from '../../selectors';
import { setErrorServerMessage, setMessage, setErrorMessage, setLocal } from '../../features/radiosSlice';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { LocalsColumnsInfos, globalTableStyle, globalThStyle, globalTdStyle, modalStyles } from '../../utils/const';
import GoToTop from '../../components/GoToTop';
import { scrollTop, compareIgnoreCase, numericSort } from '../../utils/functions';
import './style.css';

function Locals({ isAdmin }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const currentRadio = useSelector(selectRadio);
	const locals = useSelector(selectLocals);
	const pendingsLocals = useSelector(selectPendingsLocals);
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const [currentRadioName, setCurrentRadioName] = useState('');
	const [data, setData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLoaded, setLoaded] = useState(false);
	useEffect(() => {
		scrollTop();
	}, []);
	useEffect(() => {
		if (token) {
			if (params.rpID) {
				dispatch(getRadio(token, params.rpID));
				dispatch(getLocals(token, params.rpID));
			} else {
				dispatch(getPendingsLocals(token));
			}
			dispatch(setLocal(null));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, params]);
	useEffect(() => {
		if (locals && params.rpID) {
			setData(locals);
			setLoaded(true);
		}
	}, [dispatch, locals, params]);
	useEffect(() => {
		if (pendingsLocals) {
			setData(pendingsLocals);
			setLoaded(true);
		}
	}, [dispatch, pendingsLocals]);
	useEffect(() => {
		if (currentRadio) {
			setCurrentRadioName(currentRadio.data.nom);
		}
	}, [dispatch, currentRadio]);
	const columns = useMemo(() => LocalsColumnsInfos, []);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
			sortTypes: {
				alphanumeric: (row1, row2, columnName) => {
					if (columnName === 'rpID') {
						return numericSort(row1.values[columnName], row2.values[columnName]);
					} else return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
				},
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	Modal.setAppElement('body');

	const navigateLocalUpdate = (row) => {
		navigate(`/radios/${row.original.rpID_mere}/locals/${row.original.rpID}/edit`);
	};
	const sendLocal = (row) => {
		setIsModalOpen(true);
		dispatch(generateLocal(token, row.original.rpID_mere, row.original.rpID));
	};
	const removeLocal = (row) => {
		setIsModalOpen(true);
		dispatch(deleteLocal(token, row.original.rpID_mere, row.original.rpID));
	};
	const actions = [
		{ funct: navigateLocalUpdate, name: 'Modifier' },
		{ funct: sendLocal, name: 'Envoyer à RP' },
		{ funct: removeLocal, name: 'Supprimer' },
	];
	const sendLocals = () => {
		setIsModalOpen(true);
		dispatch(generateLocals(token, params.rpID));
	};
	const eraseMessages = () => {
		dispatch(setMessage(''));
		dispatch(setErrorMessage(''));
		dispatch(setErrorServerMessage(''));
	};
	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to={`/radios`} className="fs-5" onClick={() => dispatch(setErrorServerMessage(''))}>
					Retour
				</Link>
			</div>
		);
	}
	return (
		<div>
			<InsideMenu
				homeLink={`/radios/${params.rpID}/locals`}
				title={`Gestion des Décrochages Locaux ${params.rpID ? '- ' + currentRadioName : ''}`}
				links={[
					params.rpID ? { name: 'Retour à la radio', path: `/radios/edit/${params.rpID}` } : {},
					params.rpID
						? {
								name: 'Ajouter un décrochage local',
								path: `/radios/${params.rpID}/locals/add`,
							}
						: {},
				]}
			/>
			{params.rpID ? (
				<div className="d-flex justify-content-end me-4 mb-4">
					<div className="generateLocals" onClick={sendLocals}>
						Générer et envoyer vers Radioplayer
					</div>
				</div>
			) : null}
			<div className=" overflow-x-scroll d-flex justify-content-center flex-column align-items-column bg-white rounded-4 p-5 mx-4">
				<div className="sorteWrapper">
					<ElementsToShow pageSize={pageSize} setPageSize={setPageSize} />
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
						dataName={'décrochages'}
					/>
				</div>
				<Table
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
					page={page}
					tableStyle={globalTableStyle}
					thStyle={globalThStyle}
					tdStyle={globalTdStyle}
					actions={actions}
					isLoaded={isLoaded}
				/>

				<Pagination
					gotoPage={gotoPage}
					previousPage={previousPage}
					nextPage={nextPage}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					pageCount={pageCount}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
				/>
			</div>
			<Modal isOpen={isModalOpen} style={modalStyles} contentLabel="modal">
				{successMessage ? (
					<div>
						<div className="text-success mb-2">Opération réussie.</div>
						<button
							className="btn btn-secondary"
							onClick={() => {
								params.rpID
									? dispatch(getLocals(token, params.rpID))
									: dispatch(getPendingsLocals(token));
								setIsModalOpen(false);
								eraseMessages();
							}}
						>
							Retour
						</button>
					</div>
				) : (
					errorMessage && (
						<div className="mt-5">
							<div className="fs-4 text-danger mb-4">{errorMessage}</div>
							<button
								className="btn btn-secondary"
								onClick={() => {
									setIsModalOpen(false);
									eraseMessages();
								}}
							>
								Retour
							</button>
						</div>
					)
				)}
			</Modal>
			<GoToTop />
		</div>
	);
}

export default Locals;
