import React from 'react';

function DuplicationsInputs({ handleChangeFormInputs, formInputs, sortedRadios }) {
	const duplications = ['duplication_1', 'duplication_2', 'duplication_3', 'duplication_4', 'duplication_5'];

	return (
		<div className="d-flex justify-content-around mt-4">
			{duplications.map((dupli, index) => (
				<div className="col mb-3 me-2" key={index}>
					<label className="form-label d-flex justify-content-start">Duplication {index + 1} </label>
					<select
						className="form-select"
						name={dupli}
						value={formInputs.radio[dupli]}
						onChange={(e) => handleChangeFormInputs(e, 'radio')}
					>
						<option value="0">-----</option>
						{sortedRadios &&
							sortedRadios.map((radio, index) => (
								<option key={index} value={radio.rpID}>
									{radio.nom}
								</option>
							))}
					</select>
				</div>
			))}
		</div>
	);
}

export default DuplicationsInputs;
