import { createSlice } from '@reduxjs/toolkit';

// creating a redux state for metadata
export const analyticsSlice = createSlice({
	name: 'analytics',
	initialState: {
		statistics: null,
		podcastsStatistics: null,
		errorMessage: null,
		analyticsMediasOwned: null,
	},
	reducers: {
		setStatistics: (state, action) => {
			state.statistics = action.payload;
		},
		setPodcastsStatistics: (state, action) => {
			state.podcastsStatistics = action.payload;
		},
		// Message returned by the backend
		setErrorAnalyticsMessage: (state, action) => {
			state.errorMessage = action.payload;
		},
		setAnalyticsMediasOwned: (state, action) => {
			state.analyticsMediasOwned = action.payload;
		},
	},
});

export const { setErrorAnalyticsMessage, setStatistics, setPodcastsStatistics, setAnalyticsMediasOwned } =
	analyticsSlice.actions;
export default analyticsSlice.reducer;
