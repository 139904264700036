import React from 'react';

function SocialInputs({ formInputs, handleChangeFormInputs }) {
	return (
		<div className="row">
			<div className="mb-3 col-4">
				<label className="form-label d-flex justify-content-start">Facebook </label>
				<input
					className="form-control"
					type="text"
					name="facebook"
					value={formInputs.radio.facebook || ''}
					onChange={handleChangeFormInputs}
				/>
			</div>
			<div className="mb-3 col-4">
				<label className="form-label d-flex justify-content-start">Twitter </label>
				<input
					className="form-control"
					type="text"
					name="twitter"
					value={formInputs.radio.twitter || ''}
					onChange={handleChangeFormInputs}
				/>
			</div>
			<div className="mb-3 col-4">
				<label className="form-label d-flex justify-content-start">Instagram </label>
				<input
					className="form-control"
					type="text"
					name="instagram"
					value={formInputs.radio.instagram || ''}
					onChange={handleChangeFormInputs}
				/>
			</div>
		</div>
	);
}

export default SocialInputs;
