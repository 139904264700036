import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectToken,
	selectRadio,
	selectCategories,
	selectRadiosErrorMessage,
	selectRadiosMessage,
	selectPodcastErrorFeed,
} from '../../../selectors';
import { getRadio } from '../../../services/Radios';
import { addPodcast } from '../../../services/Podcasts';
import { setErrorMessage, setErrorServerMessage, setErrorFeed } from '../../../features/radiosSlice';
import { getCategories } from '../../../services/Categories';
import InsideMenu from '../../../components/InsideMenu';
import { modalStyles } from '../../../utils/const';
import { deleteWhiteSpacesInObject } from '../../../utils/functions';
import { IoCloseSharp } from 'react-icons/io5';

const { REACT_APP_API_URL } = process.env;

function PodcastCreate({ groupId, isAdmin }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const [currentRadioName, setCurrentRadioName] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [regions, setRegions] = useState([]);
	const [newImage, setNewImage] = useState('');
	const currentRadio = useSelector(selectRadio);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const errorFeed = useSelector(selectPodcastErrorFeed);
	const categories = useSelector(selectCategories);
	const [formInputs, setFormInputs] = useState({
		rpid: Number(params.rpID),
		nom: '',
		image: '',
		feed: '',
		cat_1: undefined,
		region: 0,
		onboarding: 'N',
		permanent: 'N',
	});

	useEffect(() => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorServerMessage(''));
		dispatch(setErrorFeed(null));
	}, [dispatch]);

	useEffect(() => {
		if (token) {
			dispatch(getRadio(token, params.rpID));
			dispatch(getCategories(token, 'podcasts'));
		}
	}, [dispatch, token, params]);

	useEffect(() => {
		if (token) {
			axios
				.get(`${REACT_APP_API_URL}/regions`, {
					headers: { 'x-auth-token': token },
				})
				.then((res) => {
					setRegions(res.data.regions);
				});
		}
	}, [token]);
	useEffect(() => {
		if (currentRadio) {
			setCurrentRadioName(currentRadio.data.nom);
		}
	}, [dispatch, currentRadio]);

	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/radios');
	}
	const updateFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
	};
	const updateFormSelect = (name, value) => {
		setFormInputs((prevInputs) => ({ ...prevInputs, [name]: value === 'undefined' ? undefined : Number(value) }));
	};
	const handleChangeCatInputs = (event) => {
		updateFormSelect(event.target.name, event.target.value);
	};
	const handleChangeFormInputs = (event) => {
		updateFormInput(event.target.name, event.target.value);
	};
	const handleChangeImgInputs = (event) => {
		updateFormInput(event.target.name, event.target.files[0]);
		previewImg(event.target.files[0]);
	};
	const previewImg = (file) => {
		if (file) {
			setNewImage(URL.createObjectURL(file));
		}
	};
	const submitForm = (event) => {
		event.preventDefault();
		deleteWhiteSpacesInObject(formInputs);
		dispatch(addPodcast(token, formInputs));
		setIsModalOpen(true);
	};
	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorServerMessage(''));
		dispatch(setErrorFeed(null));
	};

	return (
		<div>
			<InsideMenu
				homeLink={`/radios/${params.rpID}/podcasts`}
				title={`Gestion Des Podcasts - ${currentRadioName}`}
				links={[
					{ name: 'Retour à la radio', path: `/radios/edit/${params.rpID}` },
					{ name: 'Retour aux podcasts', path: `/radios/${params.rpID}/podcasts` },
					isAdmin || (groupId < 10 && groupId > 0)
						? { name: 'Gérer podcasts à la une', path: `/onboardings` }
						: {},
					{ name: 'Ajouter un podcast', path: `/radios/${params.rpID}/podcasts/add` },
					{ name: 'Gérer RP', path: `/radios/${params.rpID}/series` },
					{ name: 'Volée', path: `/radios/${params.rpID}/podcasts/on-the-fly/add` },
				]}
			/>
			<div className="RadioManager bg-white rounded-4 p-5 mx-4">
				<form onSubmit={submitForm}>
					<div className="row">
						<div className="mb-3 ">
							<label htmlFor="form_nom" className="form-label d-flex justify-content-start">
								Nom
							</label>
							<input
								type="text"
								className="form-control"
								id="form_nom"
								name="nom"
								value={formInputs.nom || ''}
								onChange={handleChangeFormInputs}
							/>
						</div>
						<div className="mb-3">
							<label className="form-label d-flex align-items-start flex-column ">
								Image
								{newImage ? <img src={newImage} border="" width={'112'} alt={`old Logo`} /> : null}
							</label>
							<div className="input-group">
								<input
									className="form-control"
									type="file"
									name="image"
									id="pictureInput"
									accept=".jpg,.jpeg,.png"
									value={undefined}
									onChange={handleChangeImgInputs}
								/>
								{newImage ? (
									<div
										className="input-group-addon d-flex align-items-center px-3"
										style={{
											borderRadius: '0px 0.375rem 0.375rem 0px',
											border: '1px solid #ced4da',
											cursor: 'pointer',
										}}
										onClick={() => {
											setNewImage('');
											document.getElementById('pictureInput').value = '';
										}}
									>
										<IoCloseSharp />
									</div>
								) : null}
							</div>
						</div>
						<div className="mb-3">
							<label htmlFor="form_feed" className="form-label d-flex justify-content-start">
								URL du feed RSS iTunes (lien)
							</label>
							<input
								type="text"
								className="form-control"
								id="form_feed"
								name="feed"
								value={formInputs.feed || ''}
								onChange={handleChangeFormInputs}
							/>
						</div>
						<div className="mb-3 mt-2">
							<label className="form-label d-flex justify-content-start">Catégorie 1</label>
							<select
								className="form-select"
								name="cat_1"
								value={formInputs.cat_1}
								onChange={handleChangeCatInputs}
							>
								<option value="undefined">-----</option>
								{categories &&
									categories.map((categorie, id) => (
										<option key={id} value={categorie.id}>
											{categorie.nom}
										</option>
									))}
							</select>
						</div>
						<div className="mb-3 mt-2">
							<label className="form-label d-flex justify-content-start">Podcast local ?</label>
							<select
								className="form-select"
								name="region"
								value={formInputs.region}
								onChange={handleChangeCatInputs}
							>
								<option value="0">-----</option>
								{regions &&
									regions.map((region, id) => (
										<option key={id} value={region.id}>
											{region.nom}
										</option>
									))}
							</select>
						</div>
						<div className="me-3 d-flex flex-column justify-content-start align-items-start">
							<div>Présence dans le onboarding ? (4 podcasts maximum par groupe)</div>
							<div className="d-flex">
								<div className="d-flex me-2">
									<label className="form-check-label me-2" htmlFor="onboardingYes">
										OUI
									</label>
									<input
										type="radio"
										name="onboarding"
										id="onboardingYes"
										value="O"
										checked={formInputs.onboarding === 'O'}
										onChange={(e) => handleChangeFormInputs(e, 'service')}
									/>
								</div>
								<div className="d-flex">
									<label className="form-check-label me-2" htmlFor="onboardingNo">
										NON
									</label>
									<input
										type="radio"
										name="onboarding"
										id="onboardingNo"
										value="N"
										checked={formInputs.onboarding === 'N'}
										onChange={(e) => handleChangeFormInputs(e, 'service')}
									/>
								</div>
							</div>
						</div>
						<div className=" mt-3 me-3 d-flex flex-column justify-content-start align-items-start">
							<div>Podcast permanent (ne sera pas désactivé automatiquement après 3 mois sans MAJ)</div>
							<div className="d-flex">
								<div className="d-flex me-2">
									<label className="form-check-label me-2" htmlFor="permanentYes">
										OUI
									</label>
									<input
										className="radioButton"
										type="radio"
										name="permanent"
										id="permanentYes"
										value="O"
										checked={formInputs.permanent === 'O'}
										onChange={(e) => handleChangeFormInputs(e, 'service')}
									/>
								</div>
								<div className="d-flex">
									<label className="form-check-label me-2" htmlFor="permanentNo">
										NON
									</label>
									<input
										className="radioButton"
										type="radio"
										name="permanent"
										id="permanentNo"
										value="N"
										checked={formInputs.permanent === 'N'}
										onChange={(e) => handleChangeFormInputs(e, 'service')}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex mt-5">
						<input className="formSubmitButton" type="submit" value="Enregistrer" />
					</div>
				</form>
			</div>
			<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				{successMessage ? (
					<div className="text-success">
						Votre demande de création a bien été effectuée.
						<br />
						<Link to={`/radios/${params.rpID}/podcasts`} className="fs-5 " onClick={eraseErrorMessage}>
							Retour à la page des podcasts
						</Link>
					</div>
				) : errorMessage ? (
					<div className="mt-5">
						<div className="text-danger">{errorMessage}</div>
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-secondary w-30 mt-3"
								onClick={() => {
									setIsModalOpen(false);
									eraseErrorMessage();
								}}
							>
								Retour
							</button>
						</div>
					</div>
				) : errorFeed ? (
					<div className="mt-5">
						<div className="text-danger mb-3">Cette url est déjà utilisée.</div>
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-secondary w-30 mt-3"
								onClick={() => {
									setIsModalOpen(false);
									eraseErrorMessage();
								}}
							>
								Retour
							</button>
						</div>
					</div>
				) : null}
			</Modal>
		</div>
	);
}

export default PodcastCreate;
