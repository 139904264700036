import React from 'react';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ isAdmin, token, children }) => {
	if (!isAdmin && token) {
		return <Navigate to="/radios" replace />;
	} else if (isAdmin && token) {
		return children;
	} else if (!isAdmin && !token) {
		return <Navigate to="/" replace />;
	}
};

export const ProtectedTokenRoute = ({ token, children }) => {
	if (!token) {
		return <Navigate to="/" replace />;
	} else if (token) {
		return children;
	}
};
