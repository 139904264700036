import axios from 'axios';
import {
	setLocals,
	setLocal,
	setPendingsLocals,
	setErrorServerMessage,
	setMessage,
	setErrorMessage400,
	setErrorMessage,
} from '../../features/radiosSlice';

const { REACT_APP_API_URL } = process.env;

export const getLocals = (token, rpid) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/radios/${rpid}/locals`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setLocals(response.data.locals));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const getPendingsLocals = (token) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/locals`, {
				headers: { 'x-auth-token': token },
				params: { pending: true },
			})
			.then((response) => {
				dispatch(setPendingsLocals(response.data.locals));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const generateLocal = (token, rpid, id) => {
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/radios/${rpid}/scripts/locals/${id}`, '', {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const generateLocals = (token, rpid) => {
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/radios/${rpid}/scripts/locals`, '', {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const getLocal = (token, rpid, rpidDecrochage) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/radios/${rpid}/locals/${rpidDecrochage}`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setLocal(response.data));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const updateLocal = (token, rpid, rpidDecrochage, data) => {
	return (dispatch) =>
		axios
			.put(`${REACT_APP_API_URL}/radios/${rpid}/locals/${rpidDecrochage}`, data, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 400) {
					dispatch(setErrorMessage400(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred'));
				}
			});
};

export const addLocal = (token, rpid, data) => {
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/radios/${rpid}/locals`, data, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 400) {
					dispatch(setErrorMessage400(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred'));
				}
			});
};

export const deleteLocal = (token, rpid, id) => {
	return (dispatch) =>
		axios
			.delete(`${REACT_APP_API_URL}/radios/${rpid}/locals/${id}`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};
