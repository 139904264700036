import React from 'react';

function CommandAndOutputInputs({ handleChangeFormInputs, formInputs }) {
	return (
		<div className="d-flex justify-content-around mt-2">
			<div className="col mb-3 me-2">
				<label htmlFor="inputCommand" className="form-label d-flex justify-content-start">
					INPUT COMMAND
				</label>
				<input
					type="text"
					className="form-control"
					id="inputCommand"
					name="nom"
					value={formInputs.synonym.nom || ''}
					onChange={(e) => handleChangeFormInputs(e, 'synonym')}
				/>
			</div>
			<div className="col mb-3 me-2">
				<label htmlFor="outputName" className="form-label d-flex justify-content-start">
					OUTPUT NAME
				</label>
				<input
					type="text"
					className="form-control"
					id="outputName"
					name="output_name"
					value={formInputs.synonym.output_name || ''}
					onChange={(e) => handleChangeFormInputs(e, 'synonym')}
				/>
			</div>
		</div>
	);
}

export default CommandAndOutputInputs;
