import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import {
	OnboardingsColumnsInfos,
	globalTableStyle,
	globalThStyle,
	globalTdStyle,
	modalStyles,
} from '../../../utils/const';
import {
	setErrorServerMessage,
	setMessage,
	setErrorMessage,
	setOnboarding,
	setPodcastsNames,
} from '../../../features/radiosSlice';
import Modal from 'react-modal';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import {
	selectOnboardings,
	selectToken,
	selectRadiosServerErrorMessage,
	selectRadiosMessage,
	selectRadiosErrorMessage,
} from '../../../selectors';
import { getOnboardings, deleteOnboarding } from '../../../services/Podcasts';
import InsideMenu from '../../../components/InsideMenu';
import GlobalFilter from '../../../components/GlobalFilter';
import ElementsToShow from '../../../components/ElementsToShow';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import GoToTop from '../../../components/GoToTop';
import { scrollTop, compareIgnoreCase, numericSort } from '../../../utils/functions';

function PodcastsOnboardings() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [isLoaded, setLoaded] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [onboardingName, setOnboardingName] = useState('');
	const [podcastId, setPodcastId] = useState(0);
	const [isAboutToDeleteOnboarding, setIsAboutToDeleteOnboarding] = useState(false);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const onboardings = useSelector(selectOnboardings);
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		dispatch(setPodcastsNames([]));
	}, [dispatch]);
	useEffect(() => {
		if (token) {
			dispatch(getOnboardings(token));
			dispatch(setOnboarding(null));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate]);

	useEffect(() => {
		if (onboardings) {
			setData(onboardings);
			setLoaded(true);
		}
	}, [dispatch, onboardings]);

	const columns = useMemo(() => OnboardingsColumnsInfos, []);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
			sortTypes: {
				alphanumeric: (row1, row2, columnName) => {
					if (columnName === 'id') {
						return numericSort(row1.values[columnName], row2.values[columnName]);
					} else return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
				},
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/radios');
	}

	const navigateOnboardingManager = (row) => {
		navigate(`/onboardings/${row.original.id}/edit`);
	};
	const removeOnboarding = (row) => {
		setIsModalOpen(true);
		setOnboardingName(row.original.titre);
		setPodcastId(row.original.id);
		setIsAboutToDeleteOnboarding(true);
	};
	const actions = [
		{ funct: navigateOnboardingManager, name: 'Modifier' },
		{ funct: removeOnboarding, name: 'Supprimer' },
	];

	const eraseMessages = () => {
		dispatch(setMessage(''));
		dispatch(setErrorMessage(''));
		dispatch(setErrorServerMessage(''));
	};

	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to="/radios" className="fs-5 " onClick={() => dispatch(setErrorServerMessage(''))}>
					Retour
				</Link>
			</div>
		);
	}
	return (
		<div>
			<InsideMenu
				homeLink={`/onboardings`}
				title={'Gestion De La Une Podcasts'}
				links={[{ name: "Ajout d'un podcast De Une", path: '/onboardings/add' }]}
			/>
			<div className="d-flex justify-content-center flex-column bg-white rounded-4 p-5 mx-4">
				<div className="sorteWrapper">
					<ElementsToShow pageSize={pageSize} setPageSize={setPageSize} />
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
						dataName={'radios'}
					/>
				</div>
				<Table
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
					page={page}
					tableStyle={globalTableStyle}
					thStyle={globalThStyle}
					tdStyle={globalTdStyle}
					actions={actions}
					isLoaded={isLoaded}
				/>
				<Pagination
					gotoPage={gotoPage}
					previousPage={previousPage}
					nextPage={nextPage}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					pageCount={pageCount}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
				/>
			</div>
			<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				{isAboutToDeleteOnboarding ? (
					<>
						<div className="fw-bold mb-2">
							Etes vous sur de vouloir supprimer le podcast "{onboardingName}"?
						</div>
						<div className="d-flex">
							<button
								className="cancelDeletePodcastButton me-3"
								onClick={() => {
									setIsModalOpen(false);
									setOnboardingName('');
									setPodcastId('');
									setIsAboutToDeleteOnboarding(false);
								}}
							>
								Retour
							</button>
							<button
								className="deletePodcastButton"
								onClick={() => {
									dispatch(deleteOnboarding(token, podcastId));
									setOnboardingName('');
									setPodcastId('');
									setIsAboutToDeleteOnboarding(false);
									eraseMessages();
								}}
							>
								Oui
							</button>
						</div>
					</>
				) : successMessage ? (
					<div>
						<div className="text-success mb-2">Opération réussie.</div>
						<button
							className="btn btn-secondary"
							onClick={() => {
								setIsModalOpen(false);
								dispatch(getOnboardings(token));
								eraseMessages();
							}}
						>
							Retour
						</button>
					</div>
				) : (
					errorMessage && (
						<div className="mt-5">
							<div className="fs-4 text-danger mb-4">{errorMessage}</div>
							<button
								className="btn btn-secondary"
								onClick={() => {
									setIsModalOpen(false);
									eraseMessages();
								}}
							>
								Retour
							</button>
						</div>
					)
				)}
			</Modal>
			<GoToTop />
		</div>
	);
}

export default PodcastsOnboardings;
