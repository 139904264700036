import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { getRadio } from '../../services/Radios';
import { getPodcasts, deletePodcast, desactivatePodcast, activatePodcast } from '../../services/Podcasts';
import {
	selectToken,
	selectPodcasts,
	selectRadiosServerErrorMessage,
	selectRadio,
	selectRadiosErrorMessage,
	selectRadiosMessage,
} from '../../selectors';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { PodcastsColumnsInfos, globalTableStyle, globalThStyle, globalTdStyle, modalStyles } from '../../utils/const';
import {
	setMessage,
	setErrorMessage,
	setErrorServerMessage,
	setEpisodes,
	setPodcast,
} from '../../features/radiosSlice';
import InsideMenu from '../../components/InsideMenu';
import GlobalFilter from '../../components/GlobalFilter';
import ElementsToShow from '../../components/ElementsToShow';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import GoToTop from '../../components/GoToTop';
import { scrollTop, compareIgnoreCase, numericSort } from '../../utils/functions';
import './style.css';

function Podcasts({ groupId, isAdmin }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const [currentRadioName, setCurrentRadioName] = useState('');
	const [isLoaded, setLoaded] = useState(false);
	const [data, setData] = useState([]);
	const [isPodcastsActifs, setIsPodcastsActifs] = useState('O');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const currentRadio = useSelector(selectRadio);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const podcasts = useSelector(selectPodcasts);
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const [isAboutToDeletePodcast, setIsAboutToDeletePodcast] = useState(false);
	const [currentPodcastName, setCurrentPodcastName] = useState('');
	const [podcastId, setPodcastId] = useState('');

	useEffect(() => {
		scrollTop();
	}, []);
	useEffect(() => {
		dispatch(setEpisodes(null));
		dispatch(setPodcast(null));
	}, [dispatch]);
	useEffect(() => {
		if (token) {
			dispatch(getPodcasts(token, params.rpID, isPodcastsActifs));
			dispatch(getRadio(token, params.rpID));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, params, isPodcastsActifs]);
	useEffect(() => {
		if (podcasts) {
			setData(podcasts);
			setLoaded(true);
		}
	}, [dispatch, podcasts]);

	useEffect(() => {
		if (currentRadio) {
			setCurrentRadioName(currentRadio.data.nom);
		}
	}, [dispatch, currentRadio]);

	const columns = useMemo(() => PodcastsColumnsInfos, []);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
			sortTypes: {
				alphanumeric: (row1, row2, columnName) => {
					if (columnName === 'id') {
						return numericSort(row1.values[columnName], row2.values[columnName]);
					} else return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
				},
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/radios');
	}

	const navigatePodcastManagement = (row) => {
		navigate(`/radios/${params.rpID}/podcasts/edit/${row.original.id}`);
	};
	const navigatePodcastEpisodes = (row) => {
		navigate(`/radios/${params.rpID}/podcasts/${row.original.id}/episodes`);
	};
	const disablePodcast = (row) => {
		dispatch(desactivatePodcast(token, Number(row.original.id), Number(params.rpID)));
		setIsModalOpen(true);
	};
	const enablePodcast = (row) => {
		dispatch(activatePodcast(token, Number(row.original.id), Number(params.rpID)));
		setIsModalOpen(true);
	};

	const removePodcast = (row) => {
		setCurrentPodcastName(row.original.nom);
		setPodcastId(Number(row.original.id));
		setIsModalOpen(true);
		setIsAboutToDeletePodcast(true);
	};

	const actions = [
		{ funct: navigatePodcastManagement, name: 'Modifier' },
		{ funct: navigatePodcastEpisodes, name: 'Episodes' },
		{
			funct: isPodcastsActifs === 'O' ? disablePodcast : enablePodcast,
			name: isPodcastsActifs === 'O' ? 'Désactiver' : 'Activer',
		},
		{ funct: removePodcast, name: 'Supprimer' },
	];
	const eraseMessages = () => {
		dispatch(setMessage(''));
		dispatch(setErrorMessage(''));
		dispatch(setErrorServerMessage(''));
	};
	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to="/radios" className="fs-5" onClick={() => dispatch(setErrorServerMessage(''))}>
					Retour
				</Link>
			</div>
		);
	}
	return (
		<div>
			<InsideMenu
				homeLink={`/radios/${params.rpID}/podcasts`}
				title={`Gestion Des Podcasts - ${currentRadioName}`}
				links={[
					{ name: 'Retour à la radio', path: `/radios/edit/${params.rpID}` },
					isAdmin || (groupId < 10 && groupId > 0)
						? { name: 'Gérer podcasts à la une', path: `/onboardings` }
						: {},
					{ name: 'Ajouter un podcast', path: `/radios/${params.rpID}/podcasts/add` },
					{ name: 'Gérer RP', path: `/radios/${params.rpID}/series` },
					{ name: 'Volée', path: `/radios/${params.rpID}/podcasts/on-the-fly/add` },
					{ name: 'Supprimer Tout', path: `/radios/${params.rpID}/podcasts/delete` },
				]}
			/>
			<div className=" overflow-x-scroll scrollNone d-flex justify-content-center flex-column bg-white rounded-4 p-5 mx-4">
				<div
					className="otherPodcastsStatus text-start w-100 mb-4"
					onClick={() => setIsPodcastsActifs(isPodcastsActifs === 'O' ? 'N' : 'O')}
				>
					{isPodcastsActifs === 'O' ? 'Voir podcasts désactivés' : 'Voir podcasts actifs'}
				</div>
				<div className="sorteWrapper">
					<ElementsToShow pageSize={pageSize} setPageSize={setPageSize} />
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
						dataName={'programmes'}
					/>
				</div>
				<Table
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
					page={page}
					tableStyle={globalTableStyle}
					thStyle={globalThStyle}
					tdStyle={globalTdStyle}
					actions={actions}
					isLoaded={isLoaded}
				/>

				<Pagination
					gotoPage={gotoPage}
					previousPage={previousPage}
					nextPage={nextPage}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					pageCount={pageCount}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
				/>
			</div>
			<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				{isAboutToDeletePodcast ? (
					<>
						<div className="fw-bold mb-2">
							Etes vous sur de vouloir supprimer le podcast "{currentPodcastName}"?
						</div>
						<div className="d-flex">
							<button
								className="cancelDeletePodcastButton me-3"
								onClick={() => {
									setIsModalOpen(false);
									setCurrentPodcastName('');
									setPodcastId('');
									setIsAboutToDeletePodcast(false);
								}}
							>
								Retour
							</button>
							<button
								className="deletePodcastButton"
								onClick={() => {
									dispatch(deletePodcast(token, podcastId, Number(params.rpID)));
									setCurrentPodcastName('');
									setPodcastId('');
									setIsAboutToDeletePodcast(false);
									eraseMessages();
								}}
							>
								Oui
							</button>
						</div>
					</>
				) : successMessage ? (
					<div>
						<div className="text-success mb-2">Opération réussie.</div>
						<button
							className="btn btn-secondary"
							onClick={() => {
								setIsModalOpen(false);
								dispatch(getPodcasts(token, params.rpID, isPodcastsActifs));
								eraseMessages();
							}}
						>
							Retour
						</button>
					</div>
				) : (
					errorMessage && (
						<div className="mt-5">
							<div className="fs-4 text-danger mb-4">{errorMessage}</div>
							<button
								className="btn btn-secondary"
								onClick={() => {
									setIsModalOpen(false);
									dispatch(getPodcasts(token, params.rpID, isPodcastsActifs));
									eraseMessages();
								}}
							>
								Retour
							</button>
						</div>
					)
				)}
			</Modal>
			<GoToTop />
		</div>
	);
}

export default Podcasts;
