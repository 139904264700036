import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getSeriesIds, getRadio } from '../../../services/Radios';
import { setErrorServerMessage } from '../../../features/radiosSlice';
import { selectSeriesId, selectToken, selectRadiosServerErrorMessage, selectRadio } from '../../../selectors';
import InsideMenu from '../../../components/InsideMenu';
import Spinner from '../../../components/Spinner';
import GoToTop from '../../../components/GoToTop';
import { scrollTop } from '../../../utils/functions';
import './style.css';

function SeriesId({ groupId, isAdmin }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const [currentRadioName, setCurrentRadioName] = useState('');
	const [isLoaded, setLoaded] = useState(false);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);
	const radioData = useSelector(selectRadio);
	const seriesId = useSelector(selectSeriesId);
	useEffect(() => {
		scrollTop();
	}, []);
	useEffect(() => {
		if (token) {
			dispatch(getSeriesIds(token, params.rpID));
			dispatch(getRadio(token, params.rpID));
			setLoaded(true);
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, params]);

	useEffect(() => {
		if (radioData) {
			setCurrentRadioName(radioData.data.nom);
		}
	}, [radioData]);

	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link
					to={`podcasts/${params.rpID}`}
					className="fs-5 "
					onClick={() => dispatch(setErrorServerMessage(''))}
				>
					Retour
				</Link>
			</div>
		);
	}
	return (
		<div>
			<InsideMenu
				homeLink={`/radios/${params.rpID}/podcasts`}
				title={`Gestion Des Podcasts - ${currentRadioName}`}
				links={[
					{ name: 'Retour aux podcasts', path: `/radios/${params.rpID}/podcasts` },
					isAdmin || (groupId < 10 && groupId > 0)
						? { name: 'Gérer podcasts à la une', path: `/onboardings` }
						: {},
					{ name: 'Ajouter un podcast', path: `/radios/${params.rpID}/podcasts/add` },
					{ name: 'Gérer RP', path: `/radios/${params.rpID}/series` },
					{ name: 'Volée', path: `/radios/${params.rpID}/podcasts/on-the-fly/add` },
				]}
			/>
			{isLoaded ? (
				<>
					<div className="RadioManager bg-white rounded-4 p-5 mx-4 row">
						<div className="col-6 col-md-auto d-flex flex-column align-items-start">
							<div className="fw-bold">Nom</div>
							{seriesId &&
								seriesId.map((serie, index) =>
									serie ? (
										<div className="my-1" key={index}>
											{serie}
										</div>
									) : null
								)}
							<div className="fw-bold">Nom</div>
						</div>
						<div className="col-6 col-md-auto d-flex flex-column align-items-end align-items-md-start">
							<div className="fw-bold">Actions</div>
							{seriesId &&
								seriesId.map((serie, index) =>
									serie ? (
										<div key={index} className="deleteSeriesId my-1 ">
											Supprimer
										</div>
									) : null
								)}
							<div className="fw-bold">Actions</div>
						</div>
					</div>
				</>
			) : (
				<Spinner />
			)}
			<GoToTop />
		</div>
	);
}

export default SeriesId;
