import React from 'react';
import './style.css';

function DeleteUserButton({ setIsAboutToDeleteUser, setIsModalOpen }) {
	const deleteThisUser = () => {
		setIsAboutToDeleteUser(true);
		setIsModalOpen(true);
	};
	return (
		<div>
			<button className="deleteUserFormButton" onClick={() => deleteThisUser()}>
				Supprimer
			</button>
		</div>
	);
}

export default DeleteUserButton;
