import React from 'react';

function HoursInputs({ formInputs, handleChangeFormInputs }) {
	return (
		<div className="row">
			<div className="mb-3 col">
				<label htmlFor="formDebutInput" className="form-label d-flex justify-content-start">
					Heure début
				</label>
				<input
					type="time"
					className="form-control"
					id="formDebutInput"
					name="heure_debut"
					value={formInputs.heure_debut || ''}
					onChange={handleChangeFormInputs}
				/>
			</div>
			<div className="mb-3 col">
				<label htmlFor="formFinInput" className="form-label d-flex justify-content-start">
					heure fin
				</label>
				<input
					type="time"
					className="form-control"
					id="formFinInput"
					name="heure_fin"
					value={formInputs.heure_fin || ''}
					onChange={handleChangeFormInputs}
				/>
			</div>
		</div>
	);
}

export default HoursInputs;
