import axios from 'axios';
import { setFrequenciesFM, setFrequenciesDAB, setErrorMessage, setMessage } from '../../features/radiosSlice';

const { REACT_APP_API_URL } = process.env;

//Get Frequencies list
export const getFrequencies = (token, rpID, type) => {
	const params = {};
	if (type === 3) {
		params.type = 3;
	}
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/radios/${rpID}/frequencies`, {
				headers: { 'x-auth-token': token },
				params: params,
			})
			.then((response) => {
				let fm = response.data.frequencies.filter((freq) => freq.type === 'fm');
				let dab = response.data.frequencies.filter((freq) => freq.type === 'dab');

				dispatch(setFrequenciesFM(fm));
				dispatch(setFrequenciesDAB(dab));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred retriving Frequencies'));
			});
};

//Update Frequencies
export const updateFrequencies = (token, rpID, data) => {
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/radios/${rpID}/frequencies`, data, {
				headers: {
					'x-auth-token': token,
				},
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 400) {
					dispatch(setErrorMessage(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred updating data'));
				}
			});
};
