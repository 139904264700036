import React from 'react';
import { FiUser, FiDatabase } from 'react-icons/fi';
import './style.css';

function InfoBloc({ className, logoColor, title, data }) {
	return (
		<div className={`overflow-hidden d-flex justify-content-between align-items-center ${className}`}>
			<div className="d-flex justify-content-start align-items-center textContentMetadata">
				<div className="me-4">
					<FiDatabase size={30} />
				</div>
				<div className="d-flex flex-column align-items-start">
					<div className="fs-7 text-start">{title}</div>
					<div className="fw-bold dataInfoBloc">{data === 'NaN' || data === undefined ? 0 : data}</div>
				</div>
			</div>
			<div className="metadataUserIcon">
				<FiUser size={110} color={logoColor} />
			</div>
		</div>
	);
}

export default InfoBloc;
