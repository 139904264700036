import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import './style.css';
import Modal from 'react-modal';
import rpLogo from '../../assets/logo.png';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { resetPassword } from '../../services/Auth';
import { setMessage, setErrorMessage } from '../../features/authSlice';
import { selectErrorMessage, selectMessage } from '../../selectors';
import { modalStyles } from '../../utils/const';

const { REACT_APP_SITE_URL } = process.env;

function PasswordReset() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get('token');
	const correctCode = 200;
	const [password, setPassword] = useState('');
	const [passwordSeen, setPasswordSeen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const message = useSelector(selectMessage);
	const errorMessage = useSelector(selectErrorMessage);
	const sendForm = (event) => {
		event.preventDefault();
		setIsModalOpen(true);
		dispatch(resetPassword(token, password));
	};
	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setMessage(''));
	};
	Modal.setAppElement('body');

	return (
		<div className="resetPwPage container-fluid">
			<a href={REACT_APP_SITE_URL}>
				<img className="mb-4" src={rpLogo} width="200" alt="loginpage" />
			</a>
			<div className="card loginCard p-4 rounded-3 border-0">
				<div className="card-body">
					<h4 className="card-title d-flex fw-bold justify-content-start">
						Réinitialisation du mot de passe
					</h4>
					<p className="card-text d-flex justify-content-start fw-lighter mb-4">
						Insérer un nouveau mot de passe.
					</p>
					<form onSubmit={sendForm}>
						<div className="mb-3">
							<label htmlFor="inputPassword" className="form-label d-flex justify-content-start">
								Mot de passe
							</label>
							<div className="input-group">
								<input
									type={passwordSeen ? 'text' : 'password'}
									className="form-control"
									id="inputPassword"
									required
									autoComplete="off"
									onChange={(event) => setPassword(event.target.value)}
								/>
								<div
									className="input-group-addon d-flex align-items-center px-2 eyeIcon"
									onClick={() => setPasswordSeen(!passwordSeen)}
								>
									{!passwordSeen ? (
										<BsEyeSlash size={20} color={'#CE1E41'} />
									) : (
										<BsEye size={20} color={'#CE1E41'} />
									)}
								</div>
							</div>
						</div>
						<div className="d-flex justify-content-start">
							<button type="submit" className="btn loginButton">
								Réinitialier
							</button>
						</div>
					</form>
				</div>
			</div>
			<Modal isOpen={isModalOpen} style={modalStyles} contentLabel="modal">
				{message === correctCode ? (
					<div className="text-success text-center">Votre mot de passe a bien été réinitialisé. </div>
				) : errorMessage === 401 ? (
					<div className="text-danger text-start">
						Le token de réinitialisation de mot de passe est expiré.
						<br />
						Celui-ci a une durée de 24h.
						<br />
						Pour réinitialiser votre mot de passe veuillez effectuer une nouvelle demande et suivre la
						procédure dans les 24h suivant la demande.
						<br />
						Merci
					</div>
				) : (
					<div className="text-danger text-center" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
				)}
				<button
					className="btn mt-2"
					onClick={() => {
						navigate('/');
						eraseErrorMessage();
					}}
				>
					Retour
				</button>
			</Modal>
		</div>
	);
}

export default PasswordReset;
