import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { ArticlesColumnsInfos, globalTableStyle, globalThStyle, globalTdStyle, modalStyles } from '../../utils/const';
import { scrollTop, compareIgnoreCase, numericSort } from '../../utils/functions';
import { selectArticles, selectToken, selectRadiosServerErrorMessage } from '../../selectors';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import {
	setArticles,
	setArticle,
	setMessage,
	setErrorMessage,
	setErrorServerMessage,
} from '../../features/articlesSlice';
import InsideMenu from '../../components/InsideMenu';
import GlobalFilter from '../../components/GlobalFilter';
import ElementsToShow from '../../components/ElementsToShow';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import GoToTop from '../../components/GoToTop';
import { getArticlesByState, validateArticle, refuseArticle, deleteArticle } from '../../services/Articles';

function Articles() {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const status = location.pathname.split('/').pop();
	const [data, setData] = useState([]);
	const articlesToDisplay = useSelector(selectArticles);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLoaded, setLoaded] = useState(false);
	const [articleToDelete, setArticleToDelete] = useState(null);
	const [titleToDelete, setTitleToDelete] = useState(null);
	const errorServerMessage = useSelector(selectRadiosServerErrorMessage);
	Modal.setAppElement('body');
	function closeModal() {
		navigate(0);
	}

	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorServerMessage(''));
	};
	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		if (token) {
			switch (status) {
				case 'online':
					dispatch(getArticlesByState(token, false, 2));
					break;
				case 'validate':
					dispatch(getArticlesByState(token, true));
					break;
				default:
					dispatch(setArticles([]));
			}
		} else {
			navigate('/');
		}
	}, [dispatch, token, status, navigate]);

	useEffect(() => {
		dispatch(setArticle(''));
		dispatch(setArticles(''));
		dispatch(setMessage(''));
		dispatch(setErrorServerMessage(''));
	}, [dispatch]);

	useEffect(() => {
		if (articlesToDisplay) {
			setData(articlesToDisplay);
			setLoaded(true);
		}
	}, [dispatch, articlesToDisplay]);

	const columns = useMemo(() => ArticlesColumnsInfos, []);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
			sortTypes: {
				alphanumeric: (row1, row2, columnName) => {
					if (columnName === 'articleId') {
						return numericSort(row1.values[columnName], row2.values[columnName]);
					} else return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
				},
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const navigateArticleUpdate = (row) => {
		navigate(`/articles/edit/${row.original.id}`);
	};
	const validateCurrentArticle = async (row) => {
		try {
			await dispatch(validateArticle(token, row.original.id));
			navigate(`/articles/online`);
		} catch (error) {}
	};

	const refuseCurrentArticle = async (row) => {
		try {
			await dispatch(refuseArticle(token, row.original.id));
			navigate(0);
		} catch (error) {}
	};

	const deleteCurrentArticle = (row) => {
		setArticleToDelete(row.original.id);
		setTitleToDelete(row.original.title);
		setIsModalOpen(true);
	};

	const confirmDelete = async () => {
		try {
			await dispatch(deleteArticle(token, articleToDelete));
			closeModal();
		} catch (error) {}
	};

	let actions = [];
	if (status !== 'online') {
		actions = [
			{ funct: navigateArticleUpdate, name: 'Modifier' },
			{ funct: validateCurrentArticle, name: 'Valider' },
			{ funct: refuseCurrentArticle, name: 'Refuser' },
			{ funct: deleteCurrentArticle, name: 'Supprimer' },
		];
	} else {
		actions = [
			{ funct: navigateArticleUpdate, name: 'Modifier' },
			{ funct: deleteCurrentArticle, name: 'Supprimer' },
		];
	}

	if (errorServerMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger" dangerouslySetInnerHTML={{ __html: errorServerMessage }}></div>
				<Link to="/articles/online" className="fs-5 " onClick={eraseErrorMessage}>
					Retour à la page d'accueil
				</Link>
			</div>
		);
	}
	return (
		<div>
			<InsideMenu
				homeLink={'/articles/online'}
				title={status === 'online' ? 'Articles en ligne' : 'Gestion des articles'}
				links={[
					{ name: 'Articles à valider', path: '/articles/validate' },
					{ name: 'Articles en ligne', path: '/articles/online' },
					{ name: "Ajout d'un article", path: '/articles/add' },
				]}
			/>
			<div id="table" className="bg-white rounded-4 p-5 mx-4">
				<div className="sorteWrapper">
					<ElementsToShow pageSize={pageSize} setPageSize={setPageSize} />
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
						dataName={'articles'}
					/>
				</div>
				{page?.length > 0 ? (
					<Table
						getTableProps={getTableProps}
						getTableBodyProps={getTableBodyProps}
						headerGroups={headerGroups}
						prepareRow={prepareRow}
						page={page}
						tableStyle={globalTableStyle}
						thStyle={globalThStyle}
						tdStyle={globalTdStyle}
						isLoaded={isLoaded}
						actions={actions}
					/>
				) : (
					'Aucun article de cette catégorie à afficher'
				)}

				<Pagination
					gotoPage={gotoPage}
					previousPage={previousPage}
					nextPage={nextPage}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					pageCount={pageCount}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
				/>
			</div>

			<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				<div className="fw-bold mb-2">Etes vous sur de vouloir supprimer l'article "{titleToDelete}"?</div>
				<div className="d-flex">
					<button
						className="cancelDeleteProgramButton me-3"
						onClick={() => {
							closeModal();
						}}
					>
						Retour
					</button>
					<button
						className="deleteProgramButton"
						onClick={() => {
							confirmDelete();
						}}
					>
						Oui
					</button>
				</div>
			</Modal>
			<GoToTop />
		</div>
	);
}

export default Articles;
