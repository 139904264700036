// Add selectors to get data from Redux
export const selectToken = (state) => state.auth.token;
export const selectMessage = (state) => state.auth.message;
export const selectErrorMessage = (state) => state.auth.errorMessage;
export const selectUser = (state) => state.auth.user;
export const selectUsers = (state) => state.users.users;
export const selectOneUser = (state) => state.users.user;
export const usersErrorMessage = (state) => state.users.errorMessage;
export const usersError404Message = (state) => state.users.error404Message;
export const usersErrorServerMessage = (state) => state.users.errorServerMessage;
export const usersMessage = (state) => state.users.message;
export const selectRadios = (state) => state.radios.radios;
export const selectRadiosLogoOnly = (state) => state.radios.radiosLogoOnly;
export const selectRadioPrograms = (state) => state.radios.radioPrograms;
export const selectRadiosPending = (state) => state.radios.radiosPending;
export const selectPodcasts = (state) => state.radios.podcasts;
export const selectPodcastsNames = (state) => state.radios.podcastsNames;
export const selectPodcast = (state) => state.radios.podcast;
export const selectEpisodes = (state) => state.radios.episodes;
export const selectSeriesId = (state) => state.radios.seriesId;
export const selectOnboardings = (state) => state.radios.onboardings;
export const selectOnboarding = (state) => state.radios.onboarding;
export const selectLogos = (state) => state.radios.logos;
export const selectRadio = (state) => state.radios.radio;
export const selectLocals = (state) => state.radios.locals;
export const selectLocal = (state) => state.radios.local;
export const selectPendingsLocals = (state) => state.radios.pendingsLocals;
export const selectRadiosMessage = (state) => state.radios.message;
export const selectRadiosErrorMessage = (state) => state.radios.errorMessage;
export const selectRadiosErrorMessage400 = (state) => state.radios.errorMessage400;
export const selectPodcastErrorFeed = (state) => state.radios.errorFeed;
export const selectPodcastErrorFeeds = (state) => state.radios.errorFeeds;
export const selectRadiosServerErrorMessage = (state) => state.radios.errorServerMessage;
export const selectCorrectProgramsRequestCount = (state) => state.radios.correctProgramsRequestCount;
export const selectBadProgramsRequestCount = (state) => state.radios.badProgramsRequestCount;
export const selectCategories = (state) => state.categories.categories;
export const selectCategoriesMessage = (state) => state.categories.message;
export const selectCategoriesErrorMessage = (state) => state.categories.errorMessage;
export const selectArticles = (state) => state.articles.articles;
export const selectArticle = (state) => state.articles.article;
export const selectArticlesMessage = (state) => state.articles.message;
export const selectArticlesErrorMessage = (state) => state.articles.errorMessage;
export const selectArticlesErrorMessage404 = (state) => state.articles.errorMessage;
export const selectArticlesServerErrorMessage = (state) => state.articles.errorMessage;
export const selectFrequenciesFM = (state) => state.radios.frequenciesFM;
export const selectFrequenciesDAB = (state) => state.radios.frequenciesDAB;
export const selectAnalyticsError = (state) => state.analytics.errorMessage;
export const selectStatistics = (state) => state.analytics.statistics;
export const selectPodcastsStatistics = (state) => state.analytics.podcastsStatistics;
export const selectAnalyticsMediasOwned = (state) => state.analytics.analyticsMediasOwned;
