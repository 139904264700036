import React, { useState } from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../features/authSlice';
import { FaAngleDown } from 'react-icons/fa';

function Header({ setIsConnected }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isSignoutButtonDisplayed, setSignoutButtonDisplayed] = useState(false);
	const userEmail = sessionStorage.getItem('email');
	const signout = () => {
		sessionStorage.clear();
		dispatch(logout());
		navigate('/');
		setIsConnected(false);
	};

	return (
		<div className="header d-flex justify-content-end align-items-bottom">
			<div
				className="connectedUserEmail d-flex flex-column pb-3 pt-4 pe-5"
				onClick={() => setSignoutButtonDisplayed(true)}
				onMouseLeave={() => setSignoutButtonDisplayed(false)}
			>
				<div>
					<span className="mx-3">
						<FaAngleDown />
					</span>
					<span className="mx-2">{userEmail}</span>
				</div>
				{isSignoutButtonDisplayed ? (
					<button className="signoutButton mt-2 ms-5" onClick={signout}>
						Déconnexion
					</button>
				) : null}
			</div>
		</div>
	);
}

export default Header;
