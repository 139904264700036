import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getRadio } from '../../../services/Radios';
import { addLocal } from '../../../services/Locals';
import Modal from 'react-modal';
import {
	selectToken,
	selectRadio,
	selectRadiosMessage,
	selectRadiosErrorMessage,
	selectRadiosErrorMessage400,
} from '../../../selectors';
import {
	setErrorServerMessage,
	setLocal,
	setErrorMessage,
	setErrorMessage400,
	setMessage,
} from '../../../features/radiosSlice';
import InsideMenu from '../../../components/InsideMenu';
import StreamsInputs from '../../../components/StreamsInputs';
import Geofootprint from '../../../components/Geofootprint';
import { modalStyles } from '../../../utils/const';
import { deleteWhiteSpacesInObject } from '../../../utils/functions';

function LocalCreate() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const currentRadio = useSelector(selectRadio);
	const [currentRadioName, setCurrentRadioName] = useState('');
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const errorMessage400 = useSelector(selectRadiosErrorMessage400);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [newStream, setNewStream] = useState({
		defaut: 'N',
		url: '',
		url_part_2: '',
		bitrate: 64000,
	});
	const [newGeofootprint, setNewGeofootprint] = useState('');
	const [shape, setShape] = useState();
	const [formInputs, setFormInputs] = useState({
		rpid: '',
		nom: '',
		geofootprint: '',
		streams: [],
	});

	useEffect(() => {
		if (token) {
			dispatch(getRadio(token, params.rpID));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, params]);
	useEffect(() => {
		if (currentRadio) {
			setCurrentRadioName(currentRadio.data.nom);
		}
	}, [dispatch, currentRadio]);

	Modal.setAppElement('body');
	const updateFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({
			...prevInputs,
			[name]: value === 'undefined' ? undefined : value,
		}));
	};

	const handleChangeFormInputs = (event) => {
		updateFormInput(event.target.name, event.target.value);
	};
	const submitForm = (event) => {
		event.preventDefault();
		deleteWhiteSpacesInObject(formInputs);
		if (formInputs.geofootprint) {
			dispatch(addLocal(token, params.rpID, formInputs));
		}
		setIsModalOpen(true);
	};
	const eraseMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorMessage400([]));
		dispatch(setErrorServerMessage(''));
		dispatch(setMessage(''));
	};

	return (
		<div>
			<InsideMenu
				homeLink={`/radios/${params.rpID}/locals`}
				title={`Gestion des Décrochages Locaux - ${currentRadioName}`}
				links={[
					{ name: 'Retour à la radio', path: `/radios/edit/${params.rpID}` },
					{ name: 'Retour aux décrochages', path: `/radios/${params.rpID}/locals` },
				]}
			/>
			<div className="RadioManager bg-white rounded-4 p-5 mx-4">
				<form onSubmit={submitForm}>
					<div className="row">
						<div className="mb-3 ">
							<label htmlFor="formidInput" className="form-label d-flex justify-content-start">
								RPID du décrochage
							</label>
							<input
								type="text"
								className="form-control"
								id="formidInput"
								name="rpid"
								value={formInputs.rpid || ''}
								onChange={handleChangeFormInputs}
							/>
						</div>
					</div>
					<div className="row">
						<div className="mb-3 ">
							<label htmlFor="formnomInput" className="form-label d-flex justify-content-start">
								Nom
							</label>
							<input
								type="text"
								className="form-control"
								id="formnomInput"
								name="nom"
								value={formInputs.nom || ''}
								onChange={handleChangeFormInputs}
							/>
						</div>
					</div>
					<StreamsInputs
						formInputs={formInputs}
						setFormInputs={setFormInputs}
						newStream={newStream}
						setNewStream={setNewStream}
					/>
					<Geofootprint
						formInputs={formInputs}
						setFormInputs={setFormInputs}
						newGeofootprint={newGeofootprint}
						setNewGeofootprint={setNewGeofootprint}
						shape={shape}
						setShape={setShape}
						isRadio={false}
					/>
					<div className="d-flex mt-5">
						<input className="formSubmitButton" type="submit" value="Enregistrer" />
					</div>
				</form>
			</div>

			<Modal isOpen={isModalOpen} style={modalStyles} contentLabel="modal">
				{successMessage ? (
					<div className="text-success">
						Votre demande de modification a bien été envoyée.
						<br />
						<Link
							to={`/radios/${params.rpID}/locals`}
							className="fs-5 "
							onClick={() => {
								eraseMessage();
								dispatch(setLocal(null));
							}}
						>
							Retour
						</Link>
					</div>
				) : errorMessage ? (
					<div className="mt-5">
						<div className="text-danger">{errorMessage}</div>
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-secondary w-30"
								onClick={() => {
									dispatch(setLocal(null));
									setIsModalOpen(false);
									eraseMessage();
								}}
							>
								Retour
							</button>
						</div>
					</div>
				) : errorMessage400.length > 0 || !formInputs.geofootprint ? (
					<div>
						<div>Les erreurs suivantes sont apparues dans les données de votre formulaire:</div>
						{errorMessage400.map((message, index) => (
							<div key={index} className="my-2">
								<div className="text-danger"> - {message.msg}</div>
							</div>
						))}
						{!formInputs.geofootprint ? (
							<div className="text-danger my-2">Les données Geofootprint sont manquantes</div>
						) : null}
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-secondary w-30"
								onClick={() => {
									dispatch(setLocal(null));
									setIsModalOpen(false);
									eraseMessage();
								}}
							>
								Retour
							</button>
						</div>
					</div>
				) : null}
			</Modal>
		</div>
	);
}

export default LocalCreate;
