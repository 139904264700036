import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { selectToken, selectRadio, selectRadiosErrorMessage, selectRadiosMessage } from '../../../selectors';
import { getRadio } from '../../../services/Radios';
import { deletePodcasts } from '../../../services/Podcasts';
import { setMessage, setErrorMessage } from '../../../features/radiosSlice';
import { modalStyles } from '../../../utils/const';
import './style.css';

function PodcastsDelete() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const radioData = useSelector(selectRadio);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const [currentRadioName, setCurrentRadioName] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		if (token) {
			dispatch(getRadio(token, params.rpID));
		}
	}, [dispatch, token, params]);

	useEffect(() => {
		if (radioData) {
			setCurrentRadioName(radioData.data.nom);
		}
	}, [dispatch, radioData]);
	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate(`/radios/${params.rpID}/podcasts`);
	}

	const removePodcasts = () => {
		dispatch(deletePodcasts(token, Number(params.rpID)));
		setIsModalOpen(true);
	};

	const eraseMessages = () => {
		dispatch(setMessage(''));
		dispatch(setErrorMessage(''));
	};
	return (
		<div className="mt-5">
			<div className="fs-3">
				<div>Attention</div>
				<div className="my-2">
					Vous êtes sur le point de supprimer tous les Podcasts de la radio {currentRadioName}.
				</div>
			</div>
			<div className="fs-3">Etes vous sûr de vouloir continuer ?</div>
			<div className="mt-3">
				<div onClick={removePodcasts} className="deleteAllPodcastsButton me-3">
					Oui
				</div>
				<Link to={`/radios/${params.rpID}/podcasts`} className="returnPodcastsButton">
					Retour
				</Link>
			</div>
			<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				{successMessage ? (
					<div className="text-success mb-2">Tous les podcasts ont bien été supprimés.</div>
				) : (
					errorMessage && (
						<div className="mt-5">
							<div className="fs-4 text-danger mb-4">{errorMessage}</div>
						</div>
					)
				)}
				<div>
					<button
						className="btn btn-secondary"
						onClick={() => {
							setIsModalOpen(false);
							eraseMessages();
							navigate(`/radios/${params.rpID}/podcasts`);
						}}
					>
						Retour
					</button>
				</div>
			</Modal>
		</div>
	);
}

export default PodcastsDelete;
