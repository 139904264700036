import React from 'react';
import { dateFormatting, dateFormattingTwoDaysAgo } from '../../../utils/functions';

function SelectedDatesForm({ submitForm, setFormDates, formDates, params, warningMessage }) {
	const handleFormDates = (event) => {
		setFormDates((prevInputs) => ({
			...prevInputs,
			[event.target.name]: dateFormatting(event.target.value),
		}));
	};
	return (
		<div className="overflow-x-scroll scrollNone d-flex justify-content-start flex-column bg-white rounded-4 p-5 mx-4 ">
			<div className="text-start text-danger">
				Ces données sont confidentielles et ne doivent pas être communiquées à l'extérieur.
			</div>
			<form onSubmit={submitForm}>
				<div className="d-flex align-items-center flex-column flex-lg-row justify-content-center justify-content-lg-start">
					<div className="my-3 col-10 col-lg-3 me-0 me-lg-3">
						<label htmlFor="startDate" className="form-label d-flex justify-content-start">
							Du
						</label>
						<input
							type="date"
							className="form-control"
							id="startDate"
							name="start"
							defaultValue={formDates.start}
							onChange={(e) => handleFormDates(e)}
							min={!!params.old ? '2021-06-16' : '2024-04-01'}
							max={!!params.old ? '2023-07-14' : dateFormattingTwoDaysAgo()}
						/>
					</div>
					<div className="my-3 col-10 col-lg-3">
						<label htmlFor="endDate" className="form-label d-flex justify-content-start">
							Au
						</label>
						<input
							type="date"
							className="form-control"
							id="endDate"
							name="end"
							defaultValue={formDates.end}
							onChange={(e) => handleFormDates(e)}
							min={!!params.old ? '2021-06-16' : '2024-04-01'}
							max={!!params.old ? '2023-07-14' : dateFormattingTwoDaysAgo()}
						/>
					</div>
					<div className="col-1 text-start">
						<input className="formDateSubmitButton" type="submit" value="Go" />
					</div>
					<div className="col mt-3 mt-lg-0">
						{!!params.old
							? "Données à partir du 16 juin 2021 et jusqu'au 14 juillet 2023"
							: "Données en cours de recette à partir du 1er avril 2024 et jusqu'à J-2."}
					</div>
				</div>
			</form>
			<div className="text-start text-danger" style={{ height: '20px' }}>
				{warningMessage}
			</div>
		</div>
	);
}

export default SelectedDatesForm;
