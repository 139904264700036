import React from 'react';

function GeneralInfosInputs({ formInputs, handleChangeFormInputs }) {
	return (
		<>
			<div className="mb-3">
				<label htmlFor="formNomLongInput" className="form-label d-flex justify-content-start">
					Nom Long
				</label>
				<input
					type="text"
					className="form-control"
					id="formNomLongInput"
					name="nom_long"
					value={formInputs.nom_long || ''}
					onChange={handleChangeFormInputs}
				/>
			</div>
			<div className="mb-3">
				<label htmlFor="formNomMoyenInput" className="form-label d-flex justify-content-start">
					Nom Moyen (16 caractères)
				</label>
				<input
					type="text"
					className="form-control"
					id="formNomMoyenInput"
					maxLength={16}
					name="nom_moyen"
					value={formInputs.nom_moyen || ''}
					onChange={handleChangeFormInputs}
				/>
			</div>
			<div className="mb-3">
				<label htmlFor="formDescrCourteInput" className="form-label d-flex justify-content-start">
					Description Courte - Slogan
				</label>
				<textarea
					type="text"
					className="form-control"
					id="formDescrCourteInput"
					rows="1"
					name="description_courte"
					maxLength={249}
					value={formInputs.description_courte || ''}
					onChange={handleChangeFormInputs}
				/>
			</div>
			<div className="mb-3">
				<label htmlFor="formDescrLongueInput" className="form-label d-flex justify-content-start">
					Description Longue
				</label>
				<textarea
					type="text"
					className="form-control"
					id="formDescrLongueInput"
					rows="4"
					name="description_longue"
					value={formInputs.description_longue || ''}
					onChange={handleChangeFormInputs}
				/>
			</div>
		</>
	);
}

export default GeneralInfosInputs;
