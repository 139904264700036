import axios from '../axiosInstance';
import {
	setStatistics,
	setErrorAnalyticsMessage,
	setPodcastsStatistics,
	setAnalyticsMediasOwned,
} from '../../features/analyticsSlice';

const { REACT_APP_API_URL } = process.env;

export const getStatistics = (token, start, end, old) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/analytics/statistics/start/${start}/end/${end}`, {
				headers: { 'x-auth-token': token },
				params: { old: old },
			})
			.then((response) => {
				dispatch(setStatistics(response.data));
			})
			.catch(function (error) {
				dispatch(setErrorAnalyticsMessage('A problem has occurred'));
			});
};

export const getPodcastsStatistics = (token, start, end) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/analytics/podcasts/start/${start}/end/${end}`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setPodcastsStatistics(response.data));
			})
			.catch(function (error) {
				dispatch(setErrorAnalyticsMessage('A problem has occurred'));
			});
};

export const getAnalyticsMediasOwned = (token) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/scripts/get_analytics_medias_owned`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setAnalyticsMediasOwned(response.data.analyticsMedias));
			})
			.catch(function (error) {
				dispatch(setErrorAnalyticsMessage('A problem has occurred'));
			});
};
