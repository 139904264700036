import { createSlice } from '@reduxjs/toolkit';

// creating a redux state for connected user's login
export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		token: null,
		user: null,
		message: null,
		errorMessage: null,
	},
	reducers: {
		// Token
		setToken: (state, action) => {
			state.token = action.payload;
		},
		// Connected user's  informations
		setUser: (state, action) => {
			state.user = action.payload;
		},
		// Erase connected user's informations
		logout: (state) => {
			state.token = null;
			state.user = null;
			state.message = null;
		},
		// Message returned by the backend
		setMessage: (state, action) => {
			state.message = action.payload;
		},
		// Message returned by the backend
		setErrorMessage: (state, action) => {
			state.errorMessage = action.payload;
		},
	},
});

export const { setToken, logout, setUser, setMessage, setErrorMessage } = authSlice.actions;
export default authSlice.reducer;
