import React from 'react';
import './style.css';

function ElementsToShow({ pageSize, setPageSize }) {
	return (
		<div className="elementsToShow">
			<span className="me-3">Afficher</span>
			<select
				className="py-2 px-3 border"
				value={pageSize}
				onChange={(e) => {
					setPageSize(Number(e.target.value));
				}}
			>
				{[10, 20, 30, 40, 50].map((pageSize) => (
					<option key={pageSize} value={pageSize}>
						{pageSize}
					</option>
				))}
			</select>
			<span className="ms-3">éléments</span>
		</div>
	);
}

export default ElementsToShow;
