import { createSlice } from '@reduxjs/toolkit';

// creating a redux state for articles
export const articlesSlice = createSlice({
	name: 'articles',
	initialState: {
		articles: null,
		article: null,
		message: null,
		errorMessage: null,
		errorMessage404: null,
		errorServerMessage: [],
	},
	reducers: {
		setArticles: (state, action) => {
			state.articles = action.payload;
		},

		setArticle: (state, action) => {
			state.article = action.payload;
		},

		setMessage: (state, action) => {
			state.message = action.payload;
		},

		setErrorMessage: (state, action) => {
			state.errorMessage = action.payload;
		},

		setErrorMessage404: (state, action) => {
			state.errorMessage404 = action.payload;
		},

		setErrorServerMessage: (state, action) => {
			state.errorServerMessage = action.payload;
		},
	},
});

export const { setArticles, setArticle, setMessage, setErrorMessage, setErrorMessage404, setErrorServerMessage } =
	articlesSlice.actions;
export default articlesSlice.reducer;
