export const sortArray = (array) => {
	if (array.length > 0) {
		array.sort((a, b) => {
			return a.ville?.toLowerCase() === b.ville?.toLowerCase()
				? 0
				: a.ville?.toLowerCase() < b.ville?.toLowerCase()
					? -1
					: 1;
		});
	}
	return array;
};

export const normalizeFM = (val) => {
	const parts = val?.length > 0 ? val.split(/[.,]/) : ['', ''];
	const entiers = parts[0];
	const decimal = parts[1] || '';
	return entiers.padStart(3, '0') + decimal.padEnd(2, '0');
};
