import React from 'react';

function VocalSynForm({ handleChangeFormInputs, formInputs }) {
	const synonyms = ['synonym_1', 'synonym_2', 'synonym_3', 'synonym_4', 'synonym_5'];
	return (
		<div className="d-flex justify-content-around mt-2">
			{synonyms.map((synonym, index) => (
				<div className="col mb-3 me-2" key={index}>
					<label htmlFor={synonym} className="form-label d-flex justify-content-start">
						Vocal - synonyme {index + 1}
					</label>
					<input
						type="text"
						className="form-control"
						id={synonym}
						name={synonym}
						value={formInputs.synonym[synonym] || ''}
						onChange={(e) => handleChangeFormInputs(e, 'synonym')}
					/>
				</div>
			))}
		</div>
	);
}

export default VocalSynForm;
