import React from 'react';
import Loader from '../../../../assets/loading-new.gif';

function ImgInputs({ formInputs, handleChangeImgInputs, isCreation, programCurrentImage }) {
	return (
		<div className="mb-3 row ">
			<label className="form-label d-flex align-items-start flex-column ">
				Photo du programme en 1400x1400
				{!isCreation ? (
					programCurrentImage ? (
						<img src={programCurrentImage} border="" width="200" alt="old 11400x1400Logo" />
					) : (
						<img src={Loader} border="" width="200" alt="Loader" />
					)
				) : null}
			</label>
			<input
				className="form-control"
				type="file"
				name="image_1400"
				accept=".jpg,.jpeg,.png"
				value={formInputs.image_1400?.nom}
				onChange={handleChangeImgInputs}
			/>
		</div>
	);
}

export default ImgInputs;
