import React from 'react';

function CategoriesInputs({ formInputs, handleChangeCatInputs, categories }) {
	const categoriesArray = ['cat_1', 'cat_2'];
	return (
		<div className="row">
			{categoriesArray.map((cat, index) => (
				<div className="mb-3 mt-2" key={index}>
					<label className="form-label d-flex justify-content-start">Catégorie {index + 1}</label>
					<select className="form-select" name={cat} value={formInputs[cat]} onChange={handleChangeCatInputs}>
						<option value="undefined">-----</option>
						{categories &&
							categories.map((categorie, id) => (
								<option key={id} value={categorie.id}>
									{categorie.nom}
								</option>
							))}
					</select>
				</div>
			))}
		</div>
	);
}

export default CategoriesInputs;
