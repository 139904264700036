import React from 'react';
import Loader from '../../assets/loading-new.gif';
import NoLogo from '../../assets/nologo.png';

function RadioLogosInnerTable({ radiosLogos, row }) {
	const findLogos = () => {
		return radiosLogos.find((radio) => radio.rpID === row.original.rpID);
	};

	return (
		<>
			{findLogos()?.['128_128'] ? (
				<img src={findLogos()['128_128']} width={100} alt="Player" />
			) : !findLogos()?.['128_128'] ? (
				<img src={NoLogo} border="" width="128" alt="no logo" />
			) : (
				<img src={Loader} border="" width="128" alt="loader" />
			)}
		</>
	);
}

export default RadioLogosInnerTable;
