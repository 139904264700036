import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectToken } from '../../selectors';
import InsideMenu from '../../components/InsideMenu';
import { dateFormatting } from '../../utils/functions';
import { modalStyles } from '../../utils/const';

const { REACT_APP_API_URL } = process.env;

function Logs() {
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const defaultDate = dateFormatting(new Date());
	const userEmail = sessionStorage.getItem('email');
	const [formInputs, setFormInputs] = useState({
		rpid: '',
		type: '',
		start: defaultDate,
		end: defaultDate,
		email: userEmail,
	});
	const [message, setMessage] = useState('');
	const [modalIsOpen, setIsModalOpen] = useState(false);

	Modal.setAppElement('body');

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const updateFormInputs = (e) => {
		setFormInputs((prevInputs) => ({
			...prevInputs,
			[e.target.name]:
				e.target.name === 'start' || e.target.name === 'end' ? dateFormatting(e.target.value) : e.target.value,
		}));
	};

	const submitForm = (event) => {
		event.preventDefault();
		setMessage('');
		setIsModalOpen(true);
		setMessage('Calcul en cours, merci de patienter...');
		if (formInputs.start && formInputs.end && formInputs.start <= formInputs.end) {
			axios
				.post(`${REACT_APP_API_URL}/scripts/get_ingest_logs`, formInputs, {
					headers: { 'x-auth-token': token },
				})
				.then((response) => {
					setMessage(`Le résultat de votre requête a été envoyé à l'adresse: ${formInputs.email}`);
				})
				.catch(function (error) {
					setMessage(error.message);
				});
		} else {
			setMessage('La date de fin doit être supérieure ou égale à la date de début.');
			setIsModalOpen(true);
		}
	};
	return (
		<div>
			<InsideMenu homeLink={`/radios`} title={`Extraction de journaux`} links={[]} />
			<div className="bg-white rounded-4 p-5 mx-4">
				<div className="text-start fs-5 mb-3">Envois à Ingest</div>
				<form onSubmit={submitForm}>
					<div className="mb-3 col-6">
						<label htmlFor="formRpidInput" className="form-label d-flex justify-content-start">
							RPID:
						</label>
						<input
							type="text"
							className="form-control"
							id="formRpidInput"
							name="rpid"
							value={formInputs.rpid || ''}
							onChange={(e) => updateFormInputs(e)}
						/>
					</div>
					<div className="d-flex justify-content-start flex-column mb-3 col-6">
						<label htmlFor="formRpidInput" className="form-label d-flex justify-content-start">
							Type:
						</label>
						<select
							className="py-2 px-3 border rounded"
							value={formInputs.type || ''}
							name="type"
							onChange={(e) => updateFormInputs(e)}
						>
							<option value=""></option>
							<option value="OD">OD</option>
							<option value="SI">SI</option>
							<option value="PI">PI</option>
						</select>
					</div>
					<div className="my-3 col-10 col-lg-3 me-0 me-lg-3">
						<label htmlFor="startDate" className="form-label d-flex justify-content-start">
							Du: *
						</label>
						<input
							type="date"
							className="form-control"
							id="startDate"
							name="start"
							minLength={10}
							maxLength={10}
							defaultValue={formInputs.start}
							onChange={(e) => updateFormInputs(e)}
						/>
					</div>
					<div className="my-3 col-10 col-lg-3">
						<label htmlFor="endDate" className="form-label d-flex justify-content-start">
							Au: *
						</label>
						<input
							type="date"
							className="form-control"
							id="endDate"
							name="end"
							minLength={10}
							maxLength={10}
							defaultValue={formInputs.end}
							onChange={(e) => updateFormInputs(e)}
						/>
					</div>
					<div className="mb-3 col-6">
						<label htmlFor="inputEmail" className="form-label d-flex justify-content-start">
							Adresse e-mail: *
						</label>
						<input
							type="email"
							className="form-control"
							id="inputEmail"
							aria-describedby="emailHelp"
							name="email"
							required
							pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
							autoComplete="off"
							maxLength={100}
							defaultValue={formInputs.email}
							onChange={(e) => updateFormInputs(e)}
						/>
					</div>
					<div className="text-start">* champs requis</div>
					<div className="row">
						<div className="d-flex mt-5">
							<input className="formSubmitButton" type="submit" value="Envoyer" />
						</div>
					</div>
				</form>
			</div>
			<Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				{message ? (
					<div className="row">
						<div>{message}</div>
						<div className="row d-flex justify-content-center">
							<div
								className="fs-5 text-center border w-50 mt-3 rounded"
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setMessage('');
									closeModal();
								}}
							>
								Retour
							</div>
						</div>
					</div>
				) : null}
			</Modal>
		</div>
	);
}

export default Logs;
