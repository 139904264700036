import React, { useRef, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { TokenType } from '../../utils/enum';
import { IoPlaySharp, IoStopSharp } from 'react-icons/io5';
import './style.css';

const { REACT_APP_API_URL } = process.env;

function AudioPlayer({ audioStopped, url, radioStream }) {
	const audioRef = useRef(null);
	const [secureStream, setSecureStream] = useState('');
	const [isPlaying, setIsPlaying] = useState(false);
	const audio = audioRef.current;

	useEffect(() => {
		if (audio && audioStopped) {
			audio.getInternalPlayer()?.pause();
		}
	}, [audioStopped, audio]);

	const handlePlay = async () => {
		setIsPlaying(true);
		try {
			const response = await fetch(`${REACT_APP_API_URL}/pendings/${radioStream.rpID}/stream/${radioStream.id}`, {
				method: 'GET',
				redirect: 'follow',
			});

			if (response.ok) {
				const redirectedURL = response.url;
				setSecureStream(redirectedURL); // Utilise la nouvelle URL pour le lecteur vidéo
			} else {
				console.error('Erreur de réponse:', response.status);
			}
		} catch (error) {
			console.error('Erreur lors de la redirection:', error);
		}
	};

	const handleStop = async () => {
		setIsPlaying(false);
		setSecureStream('');
		audio.getInternalPlayer()?.pause();
	};
	return (
		<div className="my-3">
			{radioStream.type === TokenType.M6 || radioStream.type === TokenType.IndesRadios ? (
				<div>
					<ReactPlayer
						url={secureStream}
						height={'0'}
						width={'0'}
						ref={audioRef}
						playing={!audioStopped || isPlaying}
					/>
					<div className="d-flex align-items-center">
						<div className="me-3">Flux sécurisé: </div>
						<button
							className="button-4 mb-2"
							onClick={() => (isPlaying ? handleStop() : handlePlay(radioStream))}
						>
							{isPlaying ? <IoStopSharp size={20} /> : <IoPlaySharp size={20} />}
						</button>
					</div>
				</div>
			) : (
				<ReactPlayer url={url} height={'50px'} ref={audioRef} playing={!audioStopped} controls />
			)}
		</div>
	);
}

export default AudioPlayer;
