import axios from '../axiosInstance';
import { setUser, setToken, setMessage, setErrorMessage } from '../../features/authSlice';

//send new user's informations and get token
export const signup = (userConnectionInfos) => {
	return (dispatch) =>
		axios
			.post(`/users`, userConnectionInfos)
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage(error.response.data));
			});
};

//send user's connection informations get token and user's informations
export const signin = (userConnectionInfos) => {
	return (dispatch) =>
		axios
			.post(`/users/${userConnectionInfos.email}/signin`, { password: userConnectionInfos.password })
			.then((response) => {
				dispatch(setToken(response.data.token));
				dispatch(setUser(response.data));
				sessionStorage.setItem('token', response.data.token);
				sessionStorage.setItem('email', userConnectionInfos.email);
				if (response.data.isAdmin === 1) sessionStorage.setItem('isAdmin', response.data.isAdmin);
				if (response.data.rpids) sessionStorage.setItem('rpids', response.data.rpids);
				if (response.data.isAnalytics === 1) sessionStorage.setItem('isAnalytics', response.data.isAnalytics);
				if (response.data.isCopil === 1) sessionStorage.setItem('isCopil', response.data.isCopil);
				if (response.data.groupe_id) sessionStorage.setItem('groupId', response.data.groupe_id);
				if (response.data.groupe_id_2) sessionStorage.setItem('groupId2', response.data.groupe_id_2);
				if (response.data.onlyStats === 1) sessionStorage.setItem('onlyStats', response.data.onlyStats);
			})

			.catch(function (error) {
				dispatch(setErrorMessage(error.response.data));
				sessionStorage.removeItem('token');
				sessionStorage.removeItem('email');
			});
};

export const forgotPassword = (email) => {
	return (dispatch) =>
		axios
			.post(`/users/${email}/forgot`)
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 404) {
					dispatch(setErrorMessage(error.response.data));
				} else {
					dispatch(setErrorMessage('Une erreur est survenue. Les administrateurs en ont été informés.'));
				}
			});
};

export const resetPassword = (token, password) => {
	return (dispatch) =>
		axios
			.post(`/users/reset/password`, { password: password, token: token })
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 401) {
					dispatch(setErrorMessage(error.response.status));
				} else {
					dispatch(setErrorMessage('Une erreur est survenue. Les administrateurs en ont été informés.'));
				}
			});
};
