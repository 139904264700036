import React from 'react';

function UrlInputs({ formInputs, handleChangeFormInputs }) {
	return (
		<div className="row mt-3">
			<div className="mb-3 ">
				<label htmlFor="formUrlSiteInput" className="form-label d-flex justify-content-start">
					URL du feed iTunes podcasts
				</label>
				<input
					type="text"
					className="form-control"
					id="formUrlSiteInput"
					name="feed_podcast"
					value={formInputs.feed_podcast || ''}
					onChange={handleChangeFormInputs}
				/>
			</div>
		</div>
	);
}

export default UrlInputs;
