import React, { useEffect, useState } from 'react';
import './style.css';
import axios from 'axios';
import Modal from 'react-modal';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectToken,
	selectOneUser,
	usersErrorMessage,
	usersMessage,
	usersError404Message,
	usersErrorServerMessage,
} from '../../selectors';
import { setMessage, setErrorMessage, setError404Message } from '../../features/usersSlice';
import { getUser, updateUser, deleteUser, getUsers } from '../../services/Users';
import UserAllowingButton from '../../components/Users/UserAllowingButton';
import DeleteUserButton from '../../components/Users/DeleteUserButton';
import Spinner from '../../components/Spinner';
import { modalStyles } from '../../utils/const';
import { deleteWhiteSpacesInObject } from '../../utils/functions';
import InsideMenu from '../../components/InsideMenu';
import { BsXCircle } from 'react-icons/bs';

const { REACT_APP_API_URL } = process.env;

function User() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const user = useSelector(selectOneUser);
	const errorMessage = useSelector(usersErrorMessage);
	const errorMessage400 = useSelector(usersError404Message);
	const errorServerMessage = useSelector(usersErrorServerMessage);
	const successMessage = useSelector(usersMessage);
	const [isLoaded, setLoaded] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [groups, setGroups] = useState([]);
	const [isAboutToDeleteUser, setIsAboutToDeleteUser] = useState(false);
	const [formInputs, setFormInputs] = useState({
		rpids: '',
		isAdmin: '',
		isAnalytics: '',
		isCopil: '',
		groupe_id: undefined,
		groupe_id_2: undefined,
		onlyStats: '',
		createdAt: '',
		updatedAt: '',
	});

	useEffect(() => {
		if (token) {
			dispatch(getUser(params, token));
		} else {
			navigate('/');
		}
	}, [dispatch, navigate, token, params]);
	useEffect(() => {
		if (token) {
			axios
				.get(`${REACT_APP_API_URL}/groups`, {
					headers: { 'x-auth-token': token },
				})
				.then((res) => {
					setGroups(res.data.groups);
				})
				.catch(function (error) {
					navigate('/');
				});
		}
	}, [token, navigate]);
	useEffect(() => {
		if (user) {
			setFormInputs({
				rpids: user.user.rpids === null ? '' : user.user.rpids,
				isAdmin: user.user.isAdmin,
				isAnalytics: user.user.isAnalytics,
				isCopil: user.user.isCopil,
				groupe_id: user.user.groupe_id === null ? undefined : user.user.groupe_id,
				groupe_id_2: user.user.groupe_id_2 === null ? undefined : user.user.groupe_id_2,
				onlyStats: user.user.onlyStats,
				createdAt: user.user.createdAt,
				updatedAt: user.user.updatedAt,
				firstName: user.user.firstName,
				lastName: user.user.lastName,
				phone: user.user.phone,
				job: user.user.job,
				entity: user.user.entity,
			});
			setLoaded(true);
		}
	}, [params, user]);
	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate(`/users/edit/${params.email}`);
	}
	const updateBooleanInput = (name, value) => {
		setFormInputs({ ...formInputs, [name]: value === 'undefined' ? undefined : Number(value) });
	};
	const updateFormInput = (name, value) => {
		setFormInputs({ ...formInputs, [name]: value });
	};
	const handleChangeFormSelect = (event) => {
		updateBooleanInput(event.target.name, event.target.value);
	};
	const handleChangeFormInputs = (event) => {
		updateFormInput(event.target.name, event.target.value);
	};

	const submitForm = (event) => {
		event.preventDefault();
		deleteWhiteSpacesInObject(formInputs);
		dispatch(updateUser(params.email, formInputs, token));
		dispatch(getUser(params, token));
		setIsModalOpen(true);
	};
	const eraseErrorMessage = () => {
		dispatch(setMessage(''));
		dispatch(setErrorMessage(''));
		dispatch(setError404Message([]));
	};
	const userDateFormatting = (date) => {
		const newDate = new Date(date);
		return (
			newDate.getDate() +
			1 +
			'-' +
			newDate.getMonth() +
			'-' +
			newDate.getFullYear() +
			' à ' +
			newDate.getHours() +
			'h:' +
			newDate.getMinutes() +
			'mn'
		);
	};

	if (errorServerMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{errorServerMessage}</div>
				<Link to="/radios" className="fs-5 ">
					Retour
				</Link>
			</div>
		);
	} else if (!isLoaded) {
		return <Spinner />;
	}
	return (
		<div className="p-5">
			<InsideMenu homeLink={'/users'} title={'Gestion des Utilisateurs'} links={[]} />
			<div className="shadow-sm rounded d-flex align-items-center justify-content-between bg-white my-3 mx-4 row">
				<div className="p-2 d-flex flex-column align-items-start fs-6 col-10">
					<div className="mt-1">
						<b>Email :</b> {params.email}
					</div>
					<div className="mt-1">
						<b>Créé le :</b> {userDateFormatting(formInputs.createdAt)}
					</div>
					<div className="mt-1">
						<b>Dernière mise à jour le :</b> {userDateFormatting(formInputs.updatedAt)}
					</div>
				</div>
				<div className="ms-3 d-flex flex-column col ">
					<UserAllowingButton user={user} />
					<DeleteUserButton setIsAboutToDeleteUser={setIsAboutToDeleteUser} setIsModalOpen={setIsModalOpen} />
				</div>
			</div>
			<div className="shadow-sm rounded bg-white my-3 p-4 d-flex justify-content-center row mx-4">
				<form aria-label="update an user" className="d-flex flex-column" onSubmit={submitForm}>
					<label className="mb-3">
						<div className="labelName">Prénom* :</div>
						<input
							className="form-control"
							type="text"
							name="firstName"
							aria-label="firstName"
							value={formInputs.firstName || ''}
							onChange={handleChangeFormInputs}
							autoComplete="no"
							required
						/>
					</label>
					<label className="mb-3">
						<div className="labelName">Nom* :</div>
						<input
							className="form-control"
							type="text"
							name="lastName"
							aria-label="lastName"
							value={formInputs.lastName || ''}
							onChange={handleChangeFormInputs}
							autoComplete="no"
							required
						/>
					</label>
					<label className="mb-3">
						<div className="labelName">Numéro de téléphone* :</div>
						<input
							className="form-control"
							type="text"
							name="phone"
							aria-label="phone"
							value={formInputs.phone || ''}
							onChange={handleChangeFormInputs}
							autoComplete="no"
							required
						/>
					</label>
					<label className="mb-3">
						<div className="labelName">Fonction* :</div>
						<input
							className="form-control"
							type="text"
							name="job"
							aria-label="job"
							value={formInputs.job || ''}
							onChange={handleChangeFormInputs}
							autoComplete="no"
							required
						/>
					</label>
					<label className="mb-3">
						<div className="labelName">Groupe/radio* :</div>
						<input
							className="form-control"
							type="text"
							name="entity"
							aria-label="entity"
							value={formInputs.entity || ''}
							onChange={handleChangeFormInputs}
							autoComplete="no"
							required
						/>
					</label>
					<label className="mb-3">
						<div className="labelName">Administrateur* :</div>
						<select
							className="form-select"
							name="isAdmin"
							aria-label="isAdmin"
							onChange={handleChangeFormInputs}
							value={formInputs.isAdmin}
						>
							<option value="1">Oui</option>
							<option value="0">Non</option>
						</select>
					</label>
					<label className="mb-3">
						<div className="labelName">Analytique* :</div>
						<select
							className="form-select"
							name="isAnalytics"
							aria-label="isAnalytics"
							onChange={handleChangeFormSelect}
							value={formInputs.isAnalytics}
						>
							<option value="1">Oui</option>
							<option value="0">Non</option>
						</select>
					</label>
					<label className="mb-3">
						<div className="labelName">Copil* :</div>
						<select
							className="form-select"
							name="isCopil"
							aria-label="isCopil"
							onChange={handleChangeFormSelect}
							value={formInputs.isCopil}
						>
							<option value="1">Oui</option>
							<option value="0">Non</option>
						</select>
					</label>
					<label className="mb-3">
						<div className="labelName">Statistiques Uniquement* :</div>
						<select
							className="form-select"
							name="onlyStats"
							aria-label="onlyStats"
							onChange={handleChangeFormSelect}
							value={formInputs.onlyStats}
						>
							<option value="1">Oui</option>
							<option value="0">Non</option>
						</select>
					</label>
					<label className="mb-3">
						<div className="labelName">
							Rpids* : (les rpids doivent être ajoutés sous cette forme: -54-74-456-...)
						</div>
						<input
							className="form-control"
							type="text"
							name="rpids"
							aria-label="rpids"
							value={formInputs.rpids || ''}
							onChange={handleChangeFormInputs}
							autoComplete="no"
							required
						/>
					</label>
					<label className="mb-3">
						<div className="labelName"> Groupe id</div>
						<select
							className="form-select"
							name="groupe_id"
							value={formInputs.groupe_id}
							onChange={handleChangeFormSelect}
						>
							<option value="undefined">-----</option>
							{groups.map((group, index) => (
								<option key={index} value={group.id}>
									{group.nom}
								</option>
							))}
						</select>
					</label>
					<label className="mb-3">
						<div className="labelName"> Groupe id 2</div>
						<select
							className="form-select"
							name="groupe_id_2"
							value={formInputs.groupe_id_2}
							onChange={handleChangeFormSelect}
						>
							<option value="undefined">-----</option>
							<option value="1">Groupe 1981</option>
						</select>
					</label>
					<div className="text-start">*Champ obligatoire</div>
					<div>
						<input className="updateUserFormButton mt-4" type="submit" value="Sauvegarder" />
					</div>
				</form>
			</div>
			<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				{isAboutToDeleteUser ? (
					<div>
						<div>Etes vous sûre de vouloir supprimer cet utilisateur ? </div>
						<div className="d-flex">
							<button
								className="cancelDeletePodcastButton me-3"
								onClick={() => {
									setIsModalOpen(false);
									setIsAboutToDeleteUser(false);
								}}
							>
								Retour
							</button>
							<button
								className="deletePodcastButton"
								onClick={() => {
									dispatch(deleteUser(params.email, token));
									dispatch(getUsers(token));
									navigate('/users');
									setIsAboutToDeleteUser(false);
									eraseErrorMessage();
								}}
							>
								Oui
							</button>
						</div>
					</div>
				) : successMessage ? (
					<div>
						<div onClick={closeModal} className="d-flex justify-content-end">
							<BsXCircle className="modaleCloseButton" />
						</div>
						<div className="text-success">
							Utilisateur modifié avec succés. <br /> Les changements prendront effet lors de la prochaine
							connexion.
							<br />
							<Link to="/users" className="fs-5 " onClick={eraseErrorMessage}>
								Retour à la page des utilisateurs
							</Link>
						</div>
					</div>
				) : errorMessage ? (
					<div className="mt-5">
						<div className="text-danger">{errorMessage}</div>
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-secondary w-30"
								onClick={() => {
									setIsModalOpen(false);
									eraseErrorMessage();
								}}
							>
								Retour
							</button>
						</div>
					</div>
				) : errorMessage400.length > 0 ? (
					<div>
						<div>Les erreurs suivantes sont apparues dans les données de votre formulaire:</div>
						{errorMessage400.map((message, index) => (
							<div key={index} className="my-2">
								<div className="text-danger"> - {message.msg}</div>
							</div>
						))}
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-secondary w-30"
								onClick={() => {
									setIsModalOpen(false);
									eraseErrorMessage();
								}}
							>
								Retour
							</button>
						</div>
					</div>
				) : null}
			</Modal>
		</div>
	);
}

export default User;
