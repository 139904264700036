import React from 'react';
import Loader from '../../../../assets/loading-new.gif';
import { averageListens } from '../../../../utils/functions';
const { REACT_APP_URL_LOGO } = process.env;

function TopRadios({ topRadios }) {
	return (
		<>
			<div className="fs-5 text-start fw-bold mt-4 ms-4 mb-2">Top 20 radios</div>
			<div className="overflow-x-scroll scrollNone d-flex justify-content-center flex-column bg-white rounded-4 p-5 mx-4">
				<table className="table">
					<thead>
						<tr>
							<th className="col-2 text-start">-</th>
							<th className="col-2 text-start">Radio</th>
							<th className="col-2 text-start">Ecoutes</th>
							<th className="col-2 text-start">Ecoutes + 30s</th>
							<th className="col-2 text-start">Durée Moyenne</th>
							<th className="col-2 text-start">Utilisateurs actifs</th>
						</tr>
					</thead>
					<tbody>
						{topRadios
							? topRadios.map((radio, index) => (
									<tr key={index}>
										<td className="text-start fw-bold">{index + 1 + ')'}</td>
										<td className="text-start">
											{`${REACT_APP_URL_LOGO}${radio.rpid}-110x110` ? (
												<img
													src={`${REACT_APP_URL_LOGO}${radio.rpid}-110x110.png`}
													alt="radioLogo"
													width={80}
												/>
											) : (
												<img src={Loader} alt="radioLogo" width={80} />
											)}
										</td>
										<td className="text-start">{Number(radio.ecoutes).toLocaleString()}</td>
										<td className="text-start">{Number(radio.ecoutes30s).toLocaleString()}</td>
										<td className="text-start">
											{averageListens(Number(radio.duree_totale), Number(radio.ecoutes))}
										</td>
										<td className="text-start">{Number(radio.mau).toLocaleString()}</td>
									</tr>
								))
							: null}
					</tbody>
				</table>
			</div>
		</>
	);
}

export default TopRadios;
