import React from 'react';

function RadioTypeInputs({ type, formInputs, handleChangeFormInputs }) {
	return (
		<div className="d-flex justify-content-around">
			{type === 3 ? null : (
				<>
					<div className="form-check">
						<label className="form-check-label" htmlFor="radio">
							Radio
						</label>
						<input
							className="form-check-input radioButton"
							type="radio"
							name="type"
							id="radio"
							value="1"
							checked={formInputs.radio.type === '1'}
							onChange={handleChangeFormInputs}
						/>
					</div>
					<div className="form-check">
						<label className="form-check-label" htmlFor="webRadio">
							Webradio
						</label>
						<input
							className="form-check-input radioButton"
							type="radio"
							name="type"
							id="webRadio"
							value="2"
							checked={formInputs.radio.type === '2'}
							onChange={handleChangeFormInputs}
						/>
					</div>
				</>
			)}
		</div>
	);
}

export default RadioTypeInputs;
