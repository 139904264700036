import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import { BsHouseDoor } from 'react-icons/bs';

function InsideMenu({ homeLink, title, links }) {
	return (
		<div className="d-flex flex-column flex-md-row justify-content-between align-items-center m-4">
			<h4 className="insideMenuTitle">{title}</h4>
			<div className="d-flex align-items-center">
				<Link to={homeLink} className=" logoHouseMenu d-flex align-items-center text-secondary-emphasis me-2">
					<BsHouseDoor />
				</Link>
				{links.map((link, index) => (
					<Link key={index} className="insideMenuLink m-2" to={link?.path}>
						{link?.name}
					</Link>
				))}
			</div>
		</div>
	);
}

export default InsideMenu;
