export const Group = 1;
export const Subgroup = 2;

export const analyticsStats = {
	rpStats: 1,
	groupStats: 2,
	rpidsStats: 3,
	subGroupStats: 4,
};

export const TokenType = {
	M6: 'rtltoken',
	IndesRadios: 'infomaniaktoken',
};

export const GroupId = {
	Lagardere: 1,
	IndesRadios: 2,
	M6: 3,
	NextRadioTv: 4,
	NRJ: 5,
	RadioFrance: 6,
};
