import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import {
	selectToken,
	selectRadio,
	selectFrequenciesFM,
	selectFrequenciesDAB,
	selectRadiosMessage,
	selectRadiosErrorMessage,
	selectRadiosServerErrorMessage,
} from '../../selectors';
import {
	setFrequenciesFM,
	setFrequenciesDAB,
	setMessage,
	setErrorMessage,
	setErrorMessage400,
	setErrorServerMessage,
} from '../../features/radiosSlice';
import { getRadio } from '../../services/Radios';
import { getFrequencies, updateFrequencies } from '../../services/Frequencies';
import { normalizeFM } from './utils/utils';
import { modalStyles } from '../../utils/const';
import InsideMenu from '../../components/InsideMenu';
import RpSpinner from '../../components/Spinner';
import FrequenciesTable from './FrequenciesTable/index';
import { scrollTop } from '../../utils/functions';
import GoToTop from '../../components/GoToTop';
import './style.css';

function Frequencies(props) {
	const radioType = props.type;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const frequenciesFMData = useSelector(selectFrequenciesFM);
	const frequenciesDABData = useSelector(selectFrequenciesDAB);
	const frequencyName = useSelector(selectRadio);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [dataFM, setDataFM] = useState(null);
	const [dataDAB, setDataDAB] = useState(null);
	const [isLastData, setIsLastData] = useState(false);
	const [radioName, setRadioName] = useState('');
	const [isFreqFmMissingInfo, setIsFreqFmMissingInfo] = useState(false);
	const [isFreqDabMissingInfo, setIsFreqDabMissingInfo] = useState(false);

	Modal.setAppElement('body');

	useEffect(() => {
		if (isLastData) {
			scrollTop();
		}
	}, [isLastData]);

	useEffect(() => {
		dispatch(setMessage(''));
		dispatch(setErrorMessage(''));
		dispatch(setErrorMessage400([]));
		dispatch(setErrorServerMessage(''));
	}, [dispatch]);

	useEffect(() => {
		if (token) {
			dispatch(getFrequencies(token, params.rpID, radioType));
			dispatch(getRadio(token, params.rpID, radioType));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, params.rpID, radioType]);

	useEffect(() => {
		if (frequencyName) {
			setRadioName(frequencyName?.data.nom);
		}
	}, [frequencyName]);

	useEffect(() => {
		if (frequenciesFMData) {
			setDataFM(frequenciesFMData);
		}
	}, [frequenciesFMData]);

	useEffect(() => {
		if (frequenciesDABData) {
			setDataDAB(frequenciesDABData);
		}
	}, [frequenciesDABData]);

	const closeModal = () => {
		setIsModalOpen(false);
	};
	const eraseErrorMessage = () => {
		closeModal();
		dispatch(setErrorMessage(''));
		dispatch(setErrorServerMessage(''));
	};

	const updateValue = (index, type, value, item) => {
		const newArray = type === 'fm' ? [...dataFM] : [...dataDAB];
		let newValue = value;
		let newBearer = `${type}:fe1.`;
		if (typeof value == 'boolean') {
			newValue = value ? 'O' : 'N';
		}
		switch (item) {
			case 'FM_frequences':
				let prevPicode = newArray[index]['FM_picode'] || '';
				newBearer = newBearer + prevPicode + '.' + normalizeFM(newValue);
				break;
			case 'FM_picode':
				let prevFMFrequency = newArray[index]['FM_frequences'] || '';
				newBearer = newBearer + newValue + '.' + normalizeFM(prevFMFrequency);
				break;
			case 'DAB_eid':
				let prevSid = newArray[index]['DAB_sid'] || '';
				newBearer = newBearer + newValue + '.' + prevSid + '.0';
				break;
			case 'DAB_sid':
				let prevEid = newArray[index]['DAB_eid'] || '';
				newBearer = newBearer + prevEid + '.' + newValue + '.0';
				break;
			default:
				break;
		}

		let updatedFrequency = { ...newArray[index] };
		updatedFrequency[item] = newValue;
		newArray[index] = updatedFrequency;

		if (type === 'fm') {
			if (item === 'FM_picode' || item === 'FM_frequences') {
				newArray[index]['FM_bearer'] = newBearer;
			}
			setDataFM(newArray);
		}
		if (type === 'dab') {
			if (item === 'DAB_eid' || item === 'DAB_sid') {
				newArray[index]['DAB_bearer'] = newBearer;
			}
			setDataDAB(newArray);
		}
	};

	const handleSubmit = (formId, id, type) => {
		setIsFreqFmMissingInfo(false);
		setIsFreqDabMissingInfo(false);
		let isFrequenciesValid = true;
		for (let frequency of formId) {
			if (Object.keys(frequency).length === 0 && frequency.constructor === Object) {
				type === 'fm' ? setIsFreqFmMissingInfo(true) : setIsFreqDabMissingInfo(true);
				isFrequenciesValid = false;
			}
		}
		if (isFrequenciesValid) setIsModalOpen(true);
		dispatch(updateFrequencies(token, params.rpID, { type: type, frequencies: formId }));
	};

	const AddFMFrequency = (newFrequency) => {
		const newData = [...dataFM, newFrequency];
		dispatch(setFrequenciesFM(newData));
	};
	const AddDABFrequency = (newFrequency) => {
		const newData = [...dataDAB, newFrequency];
		dispatch(setFrequenciesDAB(newData));
	};

	const DeleteCurrentFrequency = (type, index) => {
		let newArray = type === 'fm' ? [...dataFM] : [...dataDAB];
		newArray.splice(index, 1);
		if (type === 'fm') {
			setDataFM(newArray);
		}
		if (type === 'dab') {
			setDataDAB(newArray);
		}
	};

	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger" dangerouslySetInnerHTML={{ __html: serverErrorMessage }} />
				<Link to="/radios" className="fs-5 " onClick={() => dispatch(setErrorServerMessage(''))}>
					Retour
				</Link>
			</div>
		);
	}

	return (
		<div>
			{radioType === 3 ? (
				<InsideMenu
					homeLink={'/radios_logo_only'}
					title={`Gestion des Fréquences de ${radioName} (logo only)`}
					links={[{ name: "Ajout d'une radio Logo only", path: '/radios_logo_only/add' }]}
				/>
			) : (
				<InsideMenu
					homeLink={'/radios'}
					title={`Gestion des Fréquences de ${radioName}`}
					links={[{ name: 'Retour à la radio', path: `/radios/edit/${params.rpID}` }]}
				/>
			)}

			<div className="overflow-x-scroll d-flex justify-content-center flex-column align-items-column bg-white rounded-4 p-5 mx-4">
				{!dataFM || !dataDAB ? (
					<RpSpinner />
				) : (
					<>
						<div>
							<form
								id="FrequenciesFMTable"
								method="POST"
								encType="multipart/form-data"
								key="FrequenciesFMTable"
							>
								<div>
									<div className="row">
										<div className="col">
											<h2 className="text-start">Les Fréquences FM</h2>
										</div>
										<div className="col text-end">({dataFM?.length} fréquences)▼</div>
									</div>
									{dataFM?.length === 0 ? (
										<div className="bg-secondary text-white">
											Pas de données FM pour cette radio
										</div>
									) : (
										<FrequenciesTable
											type="fm"
											data={dataFM}
											delete={DeleteCurrentFrequency}
											updateValue={updateValue}
											setIsLastData={setIsLastData}
										/>
									)}
								</div>
							</form>
							{isFreqFmMissingInfo ? (
								<div className="text-danger">
									Il manque des informations à vos nouvelles fréquences, merci de compléter.
								</div>
							) : null}
							<button
								className="btn btn-outline-secondary mt-5"
								onClick={() => {
									AddFMFrequency({});
								}}
							>
								Ajouter une Fréquence FM
							</button>

							<form>
								<button
									className="btn updateUserFormButton my-5 px-3 col-2"
									onClick={(event) => {
										event.preventDefault();
										handleSubmit(dataFM, params.rpID, 'fm');
									}}
								>
									Enregistrer les fréquences
								</button>
							</form>
						</div>
						<div>
							<form
								id="FrequenciesDABTable"
								method="POST"
								encType="multipart/form-data"
								key="FrequenciesDABTable"
							>
								<div>
									<div className="row">
										<div className="col">
											<h2 className="text-start">Les Fréquences DAB+</h2>
										</div>
										<div className="col text-end">({dataDAB?.length} fréquences)▼</div>
									</div>
									{dataDAB?.length === 0 ? (
										<div className="bg-secondary text-white">
											Pas de données DAB+ pour cette radio
										</div>
									) : (
										<FrequenciesTable
											type="dab"
											data={dataDAB}
											delete={DeleteCurrentFrequency}
											updateValue={updateValue}
										/>
									)}
								</div>
							</form>
							{isFreqDabMissingInfo ? (
								<div className="text-danger">
									Il manque des informations à vos nouvelles fréquences, merci de compléter.
								</div>
							) : null}
							<button
								className="btn btn-outline-secondary mt-5"
								onClick={() => {
									AddDABFrequency({});
								}}
							>
								Ajouter une Fréquence DAB+
							</button>

							<form>
								<button
									className="btn updateUserFormButton my-5 px-3 col-2"
									onClick={(event) => {
										event.preventDefault();
										handleSubmit(dataDAB, params.rpID, 'dab');
									}}
								>
									Enregistrer les fréquences
								</button>
							</form>
						</div>
					</>
				)}
			</div>
			<div>
				<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
					{successMessage ? (
						<div className="row">
							<div className="text-success">Nouvelle(s) fréquence(s) ajoutée(s) avec succés.</div>
							<div className="row text-center" style={{ zIndex: 99 }}>
								<Link
									to={`/radios/${params.rpID}/frequencies/edit`}
									className="fs-5 text-center"
									onClick={eraseErrorMessage}
								>
									Retour à la page des fréquences
								</Link>
							</div>
						</div>
					) : errorMessage ? (
						<div className="mt-5">
							<div className="text-danger">{errorMessage}</div>
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : null}
				</Modal>
			</div>
			<br />
			<GoToTop />
		</div>
	);
}

export default Frequencies;
