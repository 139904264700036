import React, { useState, useEffect } from 'react';
import { scrollTop } from '../../utils/functions';
import './style.css';

function GoToTop() {
	const [isGoTopEltVisible, setIsGoTopEltVisible] = useState(false);
	useEffect(() => {
		scrollTop();
	}, []);
	window.addEventListener('scroll', function () {
		setIsGoTopEltVisible(window.scrollY > window.innerHeight);
	});

	return (
		<>
			{isGoTopEltVisible ? (
				<div
					id="goTop"
					onClick={() => {
						setIsGoTopEltVisible(false);
						scrollTop();
					}}
					className=""
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
						className="feather feather-chevrons-up"
					>
						<polyline points="17 11 12 6 7 11"></polyline>
						<polyline points="17 18 12 13 7 18"></polyline>
					</svg>
				</div>
			) : null}
		</>
	);
}

export default GoToTop;
