export const transformBddGeolocDatas = (datas) => {
	return datas
		? datas.split(', ').map((pair) => {
				const coo = pair.split(' ').map(parseFloat);
				return { lat: coo[0], lng: coo[1] };
			})
		: null;
};

export const transformGeolocDatasToBddDatas = (datas) => {
	return datas.map((coord) => Object.values(coord).join(' ')).join(', ');
};
