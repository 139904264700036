import React from 'react';
import InfoBloc from '../../../../components/Analytics/InfoBloc';

function InfosBlocs({ listenings, listenings30, averageDuration, mau, favorites }) {
	return (
		<div className="overflow-x-scroll scrollNone d-flex flex-column align-items-start m-4">
			<div className="fs-5 fw-bold w-100 text-start mb-1">En bref</div>
			<div className="d-flex flex-column flex-wrap flex-lg-row w-100">
				<InfoBloc
					className={'listen'}
					logoColor={'#8f85ff'}
					title={'Ecoutes'}
					data={Number(listenings).toLocaleString()}
				/>
				<InfoBloc
					className={'seconds'}
					logoColor={'#f95a83'}
					title={'+30 secondes'}
					data={Number(listenings30).toLocaleString()}
				/>
				<InfoBloc className={'average'} logoColor={'#8f85ff'} title={'Durée moyenne'} data={averageDuration} />
				<InfoBloc
					className={'favorites'}
					logoColor={'#8f85ff'}
					title={'Mise en favoris'}
					data={Number(favorites).toLocaleString()}
				/>
				<InfoBloc
					className={'users'}
					logoColor={'#8f85ff'}
					title={
						sessionStorage.getItem('startDate') === sessionStorage.getItem('endDate')
							? 'Utilisateurs actifs'
							: 'Moyenne DAU'
					}
					data={Number(mau).toLocaleString()}
				/>
			</div>
		</div>
	);
}

export default InfosBlocs;
