import React from 'react';

function GroupRadios({ data, listenings30 }) {
	return (
		<div className="overflow-x-scroll row d-flex flex-column flex-lg-row rounded-4 mx-4 mt-5 pb-4 bg-white">
			<div className="fs-5 text-start fw-bold ms-4 mb-2 pt-3">Radios par groupe Associés</div>
			<div className="d-flex ms-4 mt-3 text-start">
				{data &&
					data.map((item, index) =>
						item.groupid ? (
							<div key={index} className="d-flex flex-column me-5">
								<div>{item.groupname}</div>
								<div>{((item.ecoutes30s / listenings30) * 100).toFixed(2)}%</div>
							</div>
						) : null
					)}
			</div>
		</div>
	);
}

export default GroupRadios;
