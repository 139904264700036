import React from 'react';
import Loader from '../../../../assets/loading-new.gif';
import NoLogo from '../../../../assets/nologo.png';

function ImgInputs({ type, setFormInputs, currentRadioLogos, isCreation }) {
	const images =
		type === 3
			? [{ width: '1400', height: '1400' }]
			: [
					{ width: '1400', height: '1400' },
					{ width: '1920', height: '1080' },
					{ width: '1024', height: '768' },
					{ width: '112', height: '32' },
				];
	const updateImgFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
	};
	const handleChangeImgInputs = (event) => {
		updateImgFormInput(event.target.name, event.target.files[0]);
	};

	return (
		<div className="row">
			{images.map((img, index) => (
				<div className="mb-3" key={index}>
					<label className="form-label d-flex align-items-start flex-column ">
						Image {img.width}x{img.height}
						{!isCreation ? (
							currentRadioLogos ? (
								<img
									src={
										currentRadioLogos[img.width + `_` + img.height]
											? currentRadioLogos[img.width + `_` + img.height]
											: NoLogo
									}
									border=""
									width={img.width === '112' ? '112' : '200'}
									alt={`old ${img} Logo`}
								/>
							) : (
								<img src={Loader} border="" width="200" alt="loader" />
							)
						) : null}
					</label>
					<input
						className="form-control"
						type="file"
						name={`${img.width}x${img.height}`}
						accept=".jpg,.jpeg,.png"
						value={undefined}
						onChange={handleChangeImgInputs}
					/>
				</div>
			))}
		</div>
	);
}

export default ImgInputs;
