import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import InsideMenu from '../../../components/InsideMenu';
import { getRadioPrograms, getRadio, updateRadioProgram } from '../../../services/Radios';
import { getCategories } from '../../../services/Categories';
import {
	selectRadio,
	selectRadioPrograms,
	selectToken,
	selectCategories,
	selectRadiosMessage,
	selectRadiosErrorMessage,
	selectRadiosServerErrorMessage,
	selectRadiosErrorMessage400,
} from '../../../selectors';
import { setErrorMessage, setMessage, setErrorServerMessage, setErrorMessage400 } from '../../../features/radiosSlice';
import CategoriesInputs from '../children/CategoriesInputs';
import UrlInputs from '../children/UrlInputs';
import GeneralInfosInputs from '../children/GeneralInfosInputs';
import ImgInputs from '../children/ImgInputs';
import HoursInputs from '../children/HoursInputs';
import WeekInputs from '../children/WeekInputs';
import { modalStyles } from '../../../utils/const';
import { deleteWhiteSpacesInObject } from '../../../utils/functions';
import Spinner from '../../../components/Spinner';

function UpdateRadioProgram() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const [isLoaded, setLoaded] = useState(false);
	const radioData = useSelector(selectRadio);
	const radiosPrograms = useSelector(selectRadioPrograms);
	const successMessage = useSelector(selectRadiosMessage);
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);
	const errorMessage400 = useSelector(selectRadiosErrorMessage400);
	const categories = useSelector(selectCategories);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const [currentRadioName, setCurrentRadioName] = useState('');
	const [currentProgram, setCurrentProgram] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [programCurrentImage, setProgramCurrentImage] = useState();
	const [formInputs, setFormInputs] = useState({});

	useEffect(() => {
		dispatch(setErrorMessage(''));
		dispatch(setMessage(''));
	}, [dispatch]);
	useEffect(() => {
		if (token) {
			dispatch(getRadioPrograms(token, params.rpID, 'O'));
			dispatch(getRadio(token, params.rpID));
			dispatch(getCategories(token, 'podcasts'));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, params]);

	useEffect(() => {
		if (radiosPrograms) {
			setCurrentProgram(radiosPrograms.find((program) => program.id === Number(params.id)));
			setLoaded(true);
		}
	}, [dispatch, radiosPrograms, params]);
	useEffect(() => {
		if (radioData) {
			setCurrentRadioName(radioData.data.nom);
		}
	}, [dispatch, radioData]);
	useEffect(() => {
		if (currentProgram) {
			setProgramCurrentImage(currentProgram.image_1400);
			setFormInputs({
				nom_long: currentProgram.nom_long,
				nom_moyen: currentProgram.nom_moyen,
				description_courte: currentProgram.description_courte,
				description_longue: currentProgram.description_longue,
				image_1400: currentProgram.image_1400,
				cat_1: currentProgram.cat_1 === null || currentProgram.cat_1 === 0 ? undefined : currentProgram.cat_1,
				cat_2: currentProgram.cat_2 === null || currentProgram.cat_2 === 0 ? undefined : currentProgram.cat_2,
				heure_debut: currentProgram.heure_debut,
				heure_fin: currentProgram.heure_fin,
				lundi: currentProgram.lundi,
				mardi: currentProgram.mardi,
				mercredi: currentProgram.mercredi,
				jeudi: currentProgram.jeudi,
				vendredi: currentProgram.vendredi,
				samedi: currentProgram.samedi,
				dimanche: currentProgram.dimanche,
				feed_podcast: currentProgram.feed_podcast,
			});
		}
	}, [currentProgram]);
	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/radios');
	}

	const updateFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({ ...prevInputs, [name]: value === 'undefined' ? undefined : value }));
	};
	const updateFormInputCat = (name, value) => {
		setFormInputs((prevInputs) => ({ ...prevInputs, [name]: value === 'undefined' ? undefined : Number(value) }));
	};
	const handleChangeFormInputs = (event) => {
		updateFormInput(event.target.name, event.target.value);
	};
	const handleChangeCatInputs = (event) => {
		updateFormInputCat(event.target.name, event.target.value);
	};
	const handleChangeImgInputs = (event) => {
		updateFormInput(event.target.name, event.target.files[0]);
	};
	const submitForm = (event) => {
		event.preventDefault();
		deleteWhiteSpacesInObject(formInputs);
		dispatch(updateRadioProgram(token, params.rpID, params.id, formInputs));
		setIsModalOpen(true);
	};
	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorServerMessage(''));
		dispatch(setErrorMessage400([]));
	};
	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to="/Radios" className="fs-5 " onClick={() => dispatch(setErrorServerMessage(''))}>
					Retour
				</Link>
			</div>
		);
	}
	return (
		<div>
			<InsideMenu
				homeLink={`/radios/${params.rpID}/programs`}
				title={`Gestion Des Programmes - ${currentRadioName}`}
				links={[
					{ name: 'Retour à la radio', path: `/radios/edit/${params.rpID}` },
					{ name: 'Retour aux programmes', path: `/radios/${params.rpID}/programs` },
					{ name: "Ajout d'un programme", path: `/radios/${params.rpID}/programs/add` },
				]}
			/>
			{isLoaded ? (
				<div className="bg-white rounded-4 p-5 mx-4">
					<form onSubmit={submitForm}>
						<GeneralInfosInputs handleChangeFormInputs={handleChangeFormInputs} formInputs={formInputs} />
						<ImgInputs
							formInputs={formInputs}
							handleChangeImgInputs={handleChangeImgInputs}
							programCurrentImage={programCurrentImage}
						/>
						<CategoriesInputs
							formInputs={formInputs}
							handleChangeCatInputs={handleChangeCatInputs}
							categories={categories}
						/>
						<HoursInputs handleChangeFormInputs={handleChangeFormInputs} formInputs={formInputs} />
						<WeekInputs handleChangeFormInputs={handleChangeFormInputs} formInputs={formInputs} />
						<UrlInputs formInputs={formInputs} handleChangeFormInputs={handleChangeFormInputs} />
						<div className="d-flex mt-5">
							<input className="formSubmitButton" type="submit" value="Enregistrer" />
						</div>
					</form>
				</div>
			) : (
				<Spinner />
			)}
			<div>
				<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
					{successMessage ? (
						<div className="text-success">
							La modification a bien été effectuée.
							<br />
							<Link
								to={`/radios/${params.rpID}/programs`}
								className="fs-6 text-decoration-none "
								onClick={eraseErrorMessage}
							>
								Retour à la page programmes
							</Link>
						</div>
					) : errorMessage ? (
						<div className="mt-5">
							<div className="text-danger">{errorMessage}</div>
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : errorMessage400.length > 0 ? (
						<div>
							<div>Les erreurs suivantes sont apparues dans les données de votre formulaire:</div>
							{errorMessage400.map((message, index) => (
								<div key={index} className="my-2">
									<div className="text-danger"> - {message.msg}</div>
								</div>
							))}
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : null}
				</Modal>
			</div>
		</div>
	);
}

export default UpdateRadioProgram;
