import { createSlice } from '@reduxjs/toolkit';

// creating a redux state for users
export const usersSlice = createSlice({
	name: 'users',
	initialState: {
		users: null,
		user: null,
		message: null,
		errorMessage: null,
		errorServerMessage: null,
		error404Message: [],
	},
	reducers: {
		// Other user's  informations
		setUsers: (state, action) => {
			state.users = action.payload;
		},
		// One user informations
		setUser: (state, action) => {
			state.user = action.payload;
		},
		// Erase connected user's informations
		logout: (state) => {
			state.users = null;
			state.message = null;
		},
		// Message returned by the backend
		setMessage: (state, action) => {
			state.message = action.payload;
		},
		// Error Message returned by the backend
		setErrorServerMessage: (state, action) => {
			state.errorServerMessage = action.payload;
		},
		// Error Message returned by the backend
		setErrorMessage: (state, action) => {
			state.errorMessage = action.payload;
		},
		// Error 400 Message returned by the backend
		setError404Message: (state, action) => {
			state.error404Message = action.payload;
		},
	},
});

export const { setUsers, setUser, setMessage, setErrorMessage, logout, setError404Message, setErrorServerMessage } =
	usersSlice.actions;
export default usersSlice.reducer;
