import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { useNavigate, Link } from 'react-router-dom';
import {
	selectToken,
	selectPodcastsNames,
	selectRadiosErrorMessage,
	selectRadiosErrorMessage400,
	selectRadiosMessage,
} from '../../../selectors';
import { getPodcastsNames, addOnboarding } from '../../../services/Podcasts';
import { setErrorServerMessage, setErrorMessage, setErrorMessage400 } from '../../../features/radiosSlice';
import InsideMenu from '../../../components/InsideMenu';
import { modalStyles } from '../../../utils/const';
import { deleteWhiteSpacesInObject } from '../../../utils/functions';

const { REACT_APP_API_URL } = process.env;

function PodcastOnboardingCreate({ groupId, isAdmin }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [podcastsList, setPodcastsList] = useState([]);
	const [groups, setGroups] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	const [selectedGroup, setSelectedGroup] = useState();
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const podcastsNames = useSelector(selectPodcastsNames);
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const errorMessage400 = useSelector(selectRadiosErrorMessage400);
	const [formInputs, setFormInputs] = useState({
		feedid: '',
		image_1920: {},
		date_debut: '',
		date_fin: '',
	});
	useEffect(() => {
		if (token) {
			if (isAdmin) {
				if (selectedGroup) {
					dispatch(getPodcastsNames(token, selectedGroup));
				}
			} else {
				dispatch(getPodcastsNames(token, groupId));
			}
		}
	}, [dispatch, token, groupId, selectedGroup, isAdmin]);

	useEffect(() => {
		if ((podcastsNames && podcastsNames.length > 0 && selectedGroup) || !isAdmin) {
			setPodcastsList(
				podcastsNames.map((podcast) => {
					return {
						value: podcast.radio + ' - ' + podcast.nom,
						label: podcast.radio + ' - ' + podcast.nom,
						feedid: podcast.id,
					};
				})
			);
		} else {
			setPodcastsList([]);
			setSelectedOption(null);
			const selectedOptionElts = document.getElementsByClassName(' css-1dimb5e-singleValue');
			if (selectedOptionElts.length > 0) {
				selectedOptionElts[0].innerHTML = '';
			}
		}
	}, [podcastsNames, selectedGroup, isAdmin]);

	useEffect(() => {
		if (token) {
			axios
				.get(`${REACT_APP_API_URL}/groups`, {
					headers: { 'x-auth-token': token },
				})
				.then((res) => {
					setGroups(res.data.groups);
				})
				.catch(function (error) {
					navigate('/');
				});
		}
	}, [token, navigate]);
	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/onboardings');
	}
	const updateFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({ ...prevInputs, [name]: name === 'feedid' ? Number(value) : value }));
	};

	const handleChangeFormInputs = (event) => {
		updateFormInput(event.target.name, event.target.value);
	};
	const handleChangeFormSelectInputs = (event) => {
		updateFormInput('feedid', event.feedid);
	};
	const updateImgFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
	};
	const handleChangeImgInputs = (event) => {
		updateImgFormInput(event.target.name, event.target.files[0]);
	};
	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorMessage400([]));
		dispatch(setErrorServerMessage(''));
	};
	const submitForm = (event) => {
		event.preventDefault();
		deleteWhiteSpacesInObject(formInputs);
		dispatch(addOnboarding(token, formInputs));
		setIsModalOpen(true);
	};

	return (
		<div>
			<InsideMenu homeLink={`/onboardings`} title={'Gestion De La Une Podcasts'} links={[]} />
			<div className="RadioManager bg-white rounded-4 p-5 mx-4">
				{isAdmin ? (
					<div className="d-flex flex-column align-items-start w-25">
						<span className="mb-2">Selectionnez un id de groupe *</span>
						<select
							className="py-2 px-3 border"
							value={selectedGroup}
							onChange={(e) => {
								setSelectedGroup(Number(e.target.value));
							}}
						>
							<option value="">Groupe </option>
							{groups.map((group) => (
								<option key={group.id} value={group.id}>
									{group.nom}
								</option>
							))}
						</select>
					</div>
				) : null}
				<form onSubmit={submitForm}>
					<div className="mb-3 mt-3">
						<label className="form-label d-flex justify-content-start">Choisir un podcast *</label>
						<Select
							id="selectInput"
							placeholder={'Recherchez...'}
							defaultValue={selectedOption}
							onChange={(e) => {
								handleChangeFormSelectInputs(e);
								setSelectedOption(e);
							}}
							options={podcastsList}
							styles={{
								control: (baseStyles, state) => ({
									...baseStyles,
									textAlign: 'start',
								}),
								option: (baseStyles, state) => ({
									...baseStyles,
									textAlign: 'start',
								}),
							}}
							required={true}
						/>
					</div>
					<div className="mb-3">
						<label className="form-label d-flex align-items-start flex-column ">Image 1920x1080 *</label>
						<input
							className="form-control"
							type="file"
							name="image_1920"
							accept=".jpg,.jpeg,.png"
							value={undefined}
							onChange={handleChangeImgInputs}
							required={true}
						/>
					</div>
					<div className="row">
						<div className="mb-3 col-4">
							<label className="form-label d-flex justify-content-start">Date début (AAAA-MM-JJ) *</label>
							<input
								className="form-control"
								type="text"
								name="date_debut"
								value={formInputs.date_debut || ''}
								onChange={handleChangeFormInputs}
								pattern="^\d{4}-\d{2}-\d{2}$"
								required={true}
							/>
						</div>
						<div className="mb-3 col-4">
							<label className="form-label d-flex justify-content-start">Date fin (AAAA-MM-JJ) *</label>
							<input
								className="form-control"
								type="text"
								name="date_fin"
								value={formInputs.date_fin || ''}
								onChange={handleChangeFormInputs}
								pattern="^\d{4}-\d{2}-\d{2}$"
								required={true}
							/>
						</div>
					</div>
					<div className="text-start pt-4"> *champ obligatoire</div>
					<div className="d-flex mt-5">
						<input className="formSubmitButton" type="submit" value="Ajouter la mise en avant" />
					</div>
				</form>
			</div>
			<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				{successMessage ? (
					<div className="text-success">
						Votre demande de création a bien été envoyée.
						<br />
						<Link to={`/onboardings`} className="fs-5 " onClick={eraseErrorMessage}>
							Retour
						</Link>
					</div>
				) : errorMessage ? (
					<div className="mt-5">
						<div className="text-danger">{errorMessage}</div>
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-secondary w-30"
								onClick={() => {
									setIsModalOpen(false);
									eraseErrorMessage();
								}}
							>
								Retour
							</button>
						</div>
					</div>
				) : errorMessage400.length > 0 ? (
					<div>
						<div>Les erreurs suivantes sont apparues dans les données de votre formulaire:</div>
						{errorMessage400.map((message, index) => (
							<div key={index} className="my-2">
								<div className="text-danger"> - {message.msg}</div>
							</div>
						))}
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-secondary w-30"
								onClick={() => {
									setIsModalOpen(false);
									eraseErrorMessage();
								}}
							>
								Retour
							</button>
						</div>
					</div>
				) : null}
			</Modal>
		</div>
	);
}

export default PodcastOnboardingCreate;
