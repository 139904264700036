import React, { useEffect, useState } from 'react';
import HandleImageDisplay from '../../HandleImageDisplay';
import LogoRp from '../../../assets/square_rp_logo.png';
import './style.css';

const { REACT_APP_URL_LOGO } = process.env;

function LogosCarousel({ title, data, legend }) {
	const [listenings, setListenings] = useState();
	useEffect(() => {
		if (data.length > 0 && legend) {
			const sumEcoutes = data.reduce((acc, current) => acc + current.ecoutes, 0);
			setListenings(sumEcoutes);
		}
	}, [data, legend]);

	return (
		<div className="overflow-hidden row d-flex flex-column flex-lg-row rounded-4 mx-4 mt-5 bg-white px-4">
			<div className="fs-5 text-start fw-bold mb-2 pt-3">{title}</div>
			<div className="scrollPane">
				{data.length > 0 ? (
					data.map((item, index) => (
						<div className="logoCard" key={index}>
							{item.image || `${REACT_APP_URL_LOGO}${item.rpid}-110x110.png` ? (
								<HandleImageDisplay
									key={index}
									imageUrl={item.image || `${REACT_APP_URL_LOGO}${item.rpid}-110x110.png`}
									imageName={item.name}
									width={100}
									height={100}
								/>
							) : (
								<img src={LogoRp} alt="radioLogo" width={100} height={100} />
							)}
							{legend ? (
								<span className="text-start">
									{index + 1 + ') '}
									{((item.ecoutes / listenings) * 100).toFixed(2)}%
								</span>
							) : null}
						</div>
					))
				) : (
					<div className="p-3">Aucune donnée disponible</div>
				)}
			</div>
		</div>
	);
}

export default LogosCarousel;
