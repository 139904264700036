import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './style.css';
import Modal from 'react-modal';
import { signup, signin, forgotPassword } from '../../services/Auth';
import { setMessage, setErrorMessage } from '../../features/authSlice';
import { selectErrorMessage, selectMessage } from '../../selectors';
import rpLogo from '../../assets/logo.png';
import { selectToken } from '../../selectors';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { modalStyles } from '../../utils/const';
import Loader from '../../assets/loader.gif';

const { REACT_APP_SITE_URL } = process.env;

function Login() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isSignUping, setIsSignuping] = useState(false);
	const [isForgottenPassword, setIsForgottentPassword] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const message = useSelector(selectMessage);
	const errorMessage = useSelector(selectErrorMessage);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const [passwordSeen, setPasswordSeen] = useState(false);
	const [formInputs, setFormInputs] = useState({
		firstName: '',
		lastName: '',
		phone: '',
		job: '',
		entity: '',
		email: '',
		password: '',
	});

	const correctSignupCode = 204;
	const correctReinitPwCode = 200;

	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/');
	}
	const updateFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
	};

	const handleChangeFormInputs = (event) => {
		updateFormInput(event.target.name, event.target.value);
	};
	const sendForm = (event) => {
		event.preventDefault();
		if (isForgottenPassword) {
			setIsModalOpen(true);
			dispatch(forgotPassword(formInputs.email));
		} else {
			if (isSignUping) {
				setIsModalOpen(true);
				dispatch(signup(formInputs));
			} else if (!isSignUping) {
				dispatch(signin({ email: formInputs.email, password: formInputs.password }));
				dispatch(setErrorMessage(''));
			}
		}
	};

	// Verify token to get connected user's infos
	useEffect(() => {
		if (token) {
			navigate('/radios');
		} else {
			navigate('/');
		}
	}, [navigate, token]);

	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setMessage(''));
	};

	return (
		<div className="loginPage container-fluid">
			<a href={REACT_APP_SITE_URL}>
				<img className="mb-4" src={rpLogo} width="200" alt="loginpage" />
			</a>
			<div className="card loginCard px-4 pt-4 rounded-3 border-0">
				{!isForgottenPassword ? (
					<div className="card-body">
						<h4 className="card-title d-flex fw-bold justify-content-start">
							{!isSignUping ? "S'identifier" : "S'inscrire"}
						</h4>
						<p className="card-text d-flex justify-content-start fw-lighter mb-4">
							Veuillez renseigner les informations demandées.
						</p>
						{!isSignUping && errorMessage && !isForgottenPassword ? (
							<div className="text-danger fs-6" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
						) : null}
						<form onSubmit={sendForm}>
							{isSignUping ? (
								<>
									<div className="mb-3">
										<label
											htmlFor="inputFirstName"
											className="form-label d-flex justify-content-start"
										>
											Prénom*
										</label>
										<input
											type="text"
											className="form-control"
											id="inputFirstName"
											name="firstName"
											required
											maxLength={30}
											autoComplete="off"
											onChange={(event) => handleChangeFormInputs(event)}
										/>
									</div>
									<div className="mb-3">
										<label
											htmlFor="inputLastName"
											className="form-label d-flex justify-content-start"
										>
											Nom*
										</label>
										<input
											type="text"
											className="form-control"
											id="inputLastName"
											name="lastName"
											required
											maxLength={30}
											autoComplete="off"
											onChange={(event) => handleChangeFormInputs(event)}
										/>
									</div>
									<div className="mb-3">
										<label htmlFor="inputPhone" className="form-label d-flex justify-content-start">
											Numéro de téléphone*
										</label>
										<input
											type="text"
											className="form-control"
											id="inputPhone"
											name="phone"
											required
											maxLength={20}
											autoComplete="off"
											onChange={(event) => handleChangeFormInputs(event)}
										/>
									</div>
									<div className="mb-3">
										<label htmlFor="inputJob" className="form-label d-flex justify-content-start">
											Fonction*
										</label>
										<input
											type="text"
											className="form-control"
											id="inputJob"
											name="job"
											required
											maxLength={30}
											autoComplete="off"
											onChange={(event) => handleChangeFormInputs(event)}
										/>
									</div>
									<div className="mb-3">
										<label
											htmlFor="inputEntity"
											className="form-label d-flex justify-content-start"
										>
											Groupe/radio*
										</label>
										<input
											type="text"
											className="form-control"
											id="inputEntity"
											name="entity"
											required
											maxLength={30}
											autoComplete="off"
											onChange={(event) => handleChangeFormInputs(event)}
										/>
									</div>
								</>
							) : null}
							<div className="mb-3">
								<label htmlFor="inputEmail" className="form-label d-flex justify-content-start">
									Adresse e-mail{isSignUping ? '*' : ''}
								</label>
								<input
									type="email"
									className="form-control"
									id="inputEmail"
									aria-describedby="emailHelp"
									name="email"
									required
									pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
									autoComplete="off"
									maxLength={100}
									onChange={(event) => handleChangeFormInputs(event)}
								/>
							</div>
							<div className="mb-3">
								<label htmlFor="inputPassword" className="form-label d-flex justify-content-start">
									Mot de passe{isSignUping ? '*' : ''}
								</label>
								<div className="input-group">
									<input
										type={passwordSeen ? 'text' : 'password'}
										className="form-control"
										id="inputPassword"
										name="password"
										required
										autoComplete="off"
										onChange={(event) => handleChangeFormInputs(event)}
									/>
									<div
										className="input-group-addon d-flex align-items-center px-2 eyeIcon"
										onClick={() => setPasswordSeen(!passwordSeen)}
									>
										{!passwordSeen ? (
											<BsEyeSlash size={20} color={'#CE1E41'} />
										) : (
											<BsEye size={20} color={'#CE1E41'} />
										)}
									</div>
								</div>
							</div>
							<div className="d-flex justify-content-start">
								{!isSignUping ? (
									<button type="submit" className="btn loginButton">
										S'identifier
									</button>
								) : (
									<button type="submit" className="btn signupButton">
										S'inscrire
									</button>
								)}
							</div>
						</form>
					</div>
				) : (
					<div className="card-body">
						<h4 className="card-title d-flex fw-bold justify-content-start text-start">
							Demande de réinitialisation <br />
							du mot de passe
						</h4>
						<p className="card-text d-flex justify-content-start fw-lighter mb-4">
							Veuillez entrer votre email
						</p>
						<form onSubmit={sendForm}>
							<div className="mb-3">
								<label htmlFor="inputEmail" className="form-label d-flex justify-content-start">
									Adresse e-mail
								</label>
								<input
									type="email"
									className="form-control"
									id="inputEmail"
									aria-describedby="emailHelp"
									name="email"
									required
									maxLength={100}
									pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
									autoComplete="off"
									onChange={(event) => handleChangeFormInputs(event)}
								/>
							</div>
							<div className="d-flex justify-content-start">
								<button type="submit" className="btn loginButton">
									Réinitialiser
								</button>
							</div>
						</form>
					</div>
				)}
				<div className="d-flex justify-content-around">
					{!isForgottenPassword ? (
						<div className="changeForm" onClick={() => setIsSignuping(!isSignUping)}>
							{!isSignUping ? 'Pas encore inscrit ?' : 'Déjà inscrit ?'}
						</div>
					) : (
						<div className="changeForm pb-4" onClick={() => setIsForgottentPassword(false)}>
							Annuler
						</div>
					)}
					{!isSignUping && !isForgottenPassword ? (
						<div
							className="changingPassword pb-4"
							onClick={() => {
								setIsForgottentPassword(true);
								eraseErrorMessage();
							}}
						>
							Mot de passe oublié?
						</div>
					) : null}
				</div>
				{isSignUping ? <div className="text-end mt-2">*Champ obligatoire</div> : null}
			</div>
			<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				{message === correctSignupCode ? (
					<div className="text-success text-center">
						Une demande d'ouverture de compte à bien été envoyée. <br /> En cas de validation par notre
						administrateur, <br />
						votre compte sera ouvert sous 24h maximum.
					</div>
				) : message === correctReinitPwCode ? (
					<div className="text-success text-center">
						Un lien de réinitialisation vous a été envoyé par email.
					</div>
				) : errorMessage ? (
					<div className="text-danger text-center" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
				) : (
					<div className="text-center">
						<img src={Loader} border="" width={100} height={'auto'} alt="loader" />
					</div>
				)}

				<div className="d-flex justify-content-center">
					<button
						className="btn w-30 mt-3"
						onClick={() => {
							setIsModalOpen(false);
							eraseErrorMessage();
							setIsForgottentPassword(errorMessage && isForgottenPassword);
							setIsSignuping(false);
						}}
					>
						Retour
					</button>
				</div>
			</Modal>
		</div>
	);
}

export default Login;
