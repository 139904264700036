import React from 'react';

function ArticleInputs({ formInputs, handleChangeFormInputs }) {
	return (
		<div>
			<div className="mb-3">
				<label className="form-label d-flex justify-content-start" htmlFor="title">
					Titre*
				</label>
				<input
					type="text"
					className="form-control"
					id="title"
					name="title"
					placeholder="Taper ou coller ici le titre de l'article"
					required
					value={formInputs.title || ''}
					onChange={handleChangeFormInputs}
				/>
			</div>
			<div className="mb-3">
				<div className="form-label d-flex justify-content-start" htmlFor="content">
					Contenu*
				</div>
				<textarea
					type="textarea"
					className="form-control h-100"
					id="content"
					name="content"
					placeholder="Taper ou coller ici le contenu de l'article"
					rows="7"
					multiline="true"
					required
					value={formInputs.content || ''}
					onChange={handleChangeFormInputs}
				/>
			</div>
		</div>
	);
}

export default ArticleInputs;
