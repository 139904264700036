import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLogos } from '../../services/Radios';
import { getAnalyticsMediasOwned } from '../../services/Analytics';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken, selectAnalyticsMediasOwned } from '../../selectors';
import { scrollTop } from '../../utils/functions';
import InsideMenu from '../../components/InsideMenu';
import Spinner from '../../components/Spinner';
import './style.css';

function MediasCounter() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isLoaded, setIsLoaded] = useState(false);
	const [sortedAnalyticsMediasOwned, setSortedAnalyticsMediasOwned] = useState([]);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const analyticsMediasOwned = useSelector(selectAnalyticsMediasOwned);

	useEffect(() => {
		scrollTop();
	}, []);
	useEffect(() => {
		if (token) {
			dispatch(getAnalyticsMediasOwned(token));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate]);

	useEffect(() => {
		if (analyticsMediasOwned) {
			setIsLoaded(true);
			setSortedAnalyticsMediasOwned([...analyticsMediasOwned].sort((a, b) => b.label.localeCompare(a.label)));
		}
	}, [analyticsMediasOwned]);

	useEffect(() => {
		if (token) {
			dispatch(getLogos(token));
		} else {
			navigate('/radios');
		}
	}, [dispatch, token, navigate]);

	return (
		<div>
			<InsideMenu homeLink={`/radios`} title={`Compter de médias`} links={[]} />
			<div className="d-flex justify-content-start ms-4">Les données sont actualisées toutes les 24h.</div>
			<div className="overflow-x-scroll scrollNone d-flex justify-content-center flex-column bg-white rounded-4 p-5 mx-4">
				{isLoaded ? (
					<div className="mediasCounterWrapper">
						<div className="fs-4 mb-3">Podcasts:</div>
						{sortedAnalyticsMediasOwned
							.filter((analytic) => !analytic.label.toLowerCase().includes('radios'.toLowerCase()))
							.map((analytic, index) => (
								<div key={index} className="mediaCounter">
									<div className="mediaCounterLabel">{analytic.label}:</div>
									<div className="mediaCounterValue">{analytic.value}</div>
								</div>
							))}

						<div className="fs-4 my-3">Radios:</div>
						{analyticsMediasOwned
							.filter((item) => item.label.toLowerCase().includes('radios'.toLowerCase()))
							.map((analytic, index) => (
								<div key={index} className="mediaCounter">
									<div className="mediaCounterLabel">{analytic.label}:</div>
									<div className="mediaCounterValue">{analytic.value}</div>
								</div>
							))}
					</div>
				) : (
					<div className="d-flex flex-column align-items center">
						<Spinner />
					</div>
				)}
			</div>
		</div>
	);
}

export default MediasCounter;
