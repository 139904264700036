import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import {
	selectToken,
	selectArticle,
	selectArticlesMessage,
	selectArticlesErrorMessage,
	selectArticlesServerErrorMessage,
} from '../../selectors';

import { setErrorMessage, setErrorServerMessage } from '../../features/articlesSlice';
import { createArticle, updateArticle } from '../../services/Articles';
import InsideMenu from '../../components/InsideMenu';
import ArticleInputs from './children/ArticleInputs';
import ImgInputs from './children/ImgInputs';
import { modalStyles } from '../../utils/const';
import { deleteWhiteSpacesInObject } from '../../utils/functions';
import { getArticle } from '../../services/Articles';

function ArticleCreate() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const param = useParams();
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const errorMessage = useSelector(selectArticlesErrorMessage);
	const errorServerMessage = useSelector(selectArticlesServerErrorMessage);
	const successMessage = useSelector(selectArticlesMessage);
	const data = useSelector(selectArticle);
	const isCreation = param.artID === undefined;
	const [modalIsOpen, setIsModalOpen] = useState(false);

	const [formInputs, setFormInputs] = useState({
		id: 0,
		title: '',
		content: '',
		image: '',
	});

	useEffect(() => {
		if (token) {
			dispatch(getArticle(token, param.artID || 'add'));
		} else {
			navigate('/');
		}
	}, [dispatch, token, param.artID, navigate]);

	useEffect(() => {
		if (!isCreation && data) {
			setFormInputs(data);
		} else setFormInputs({ title: '', content: '', image: '' });
	}, [dispatch, token, data, isCreation]);

	Modal.setAppElement('body');

	const closeModal = () => {
		setIsModalOpen(false);
		navigate('/articles/validate');
	};
	const updateFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({
			...prevInputs,
			[name]: value === 'undefined' ? undefined : value,
		}));
	};

	const handleChangeFormInputs = (event) => {
		updateFormInput(event.target.name, event.target.value);
	};

	const submitForm = (event) => {
		event.preventDefault();
		deleteWhiteSpacesInObject(formInputs);
		isCreation
			? dispatch(createArticle(token, formInputs))
			: dispatch(updateArticle(token, param.artID, formInputs));
		setIsModalOpen(true);
	};

	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorServerMessage(''));
	};

	if (errorServerMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger" dangerouslySetInnerHTML={{ __html: errorServerMessage }}></div>
				<Link to="/articles/add" className="fs-5 " onClick={eraseErrorMessage}>
					Retour à la page d'accueil
				</Link>
			</div>
		);
	}

	return (
		<div>
			<InsideMenu
				homeLink={'/articles/online'}
				title={isCreation ? "Création d'article" : "Modification d'article"}
				links={[
					{ name: 'Articles à valider', path: '/articles/validate' },
					{ name: 'Articles en ligne', path: '/articles/online' },
					{ name: "Ajout d'un article", path: '/articles/add' },
				]}
			/>
			<div className="RadioManager bg-white rounded-4 p-5 mx-4">
				<form onSubmit={submitForm}>
					<ArticleInputs formInputs={formInputs} handleChangeFormInputs={handleChangeFormInputs} />
					<ImgInputs
						formInputs={formInputs}
						updateFormInput={updateFormInput}
						setFormInputs={setFormInputs}
						isCreation={isCreation}
					/>
					<div className="text-start pt-4"> *champ obligatoire</div>
					<div className="d-flex mt-5 end-0">
						<input className="formSubmitButton" type="submit" value="Enregistrer" />
					</div>
				</form>
			</div>
			<div>
				<Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
					{successMessage ? (
						<div className="row">
							<div className="text-success">
								Votre demande de {isCreation ? 'création' : 'modification'} a bien été envoyée.
								<br /> Elle sera examinée par notre équipe dans un délai de 24h.
								<br />
							</div>
							<div className="row text-center">
								<Link to="/articles/validate" className="fs-5 text-center" onClick={eraseErrorMessage}>
									Retour à la page des articles
								</Link>
							</div>
						</div>
					) : errorMessage ? (
						<div className="mt-5">
							<div className="text-danger">{errorMessage}</div>
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : null}
				</Modal>
			</div>
		</div>
	);
}

export default ArticleCreate;
