import React from 'react';
import { radioPriority } from '../../../../utils/const';

function OnboardingPriorityInputs({ handleChangeFormInputs, formInputs }) {
	return (
		<div className="d-flex mt-4">
			<div className="col me-3  d-flex flex-column align-items-start ">
				<div>
					<label className="form-label">Priorité de la radio : *</label>
				</div>
				<select
					className="form-select"
					name="priorite"
					value={formInputs.radio.priorite}
					onChange={(e) => handleChangeFormInputs(e, 'radio')}
				>
					<option value="0">-----</option>
					{radioPriority.map((priority, index) => (
						<option key={index} value={priority.id}>
							{priority.name}
						</option>
					))}
				</select>
			</div>
			<div className="col me-3 d-flex justify-content-start align-items-center">
				<div>Radio présente à l'onboarding ? *</div>
				<div className="d-flex mx-2">
					<label className="form-check-label me-2" htmlFor="onboardingYes">
						OUI
					</label>
					<input
						type="radio"
						name="onboarding"
						id="onboardingYes"
						value="1"
						checked={formInputs.radio.onboarding === '1'}
						onChange={(e) => handleChangeFormInputs(e, 'radio')}
					/>
				</div>
				<div className="d-flex">
					<label className="form-check-label me-2" htmlFor="onboardingNo">
						NON
					</label>
					<input
						type="radio"
						name="onboarding"
						id="onboardingNo"
						value="0"
						checked={formInputs.radio.onboarding === '0'}
						onChange={(e) => handleChangeFormInputs(e, 'radio')}
					/>
				</div>
			</div>
		</div>
	);
}

export default OnboardingPriorityInputs;
