import React, { useState } from 'react';
import axios from 'axios';
import RpLogo from '../../assets/logo.5bf2f09f.svg';
import Loader from '../../assets/loader.gif';
import { HiOutlineRefresh } from 'react-icons/hi';
import './style.css';

const { REACT_APP_POC_IA } = process.env;

function PocIA() {
	const [mp3Url, setMp3Url] = useState('');
	const [isLoaded, setIsLoaded] = useState(false);
	const [message, setMessage] = useState({
		stepOne: '',
		stepTwo: '',
		stepThree: '',
		stepFour: '',
	});
	const [mp3Name, setMp3Name] = useState('');
	const [mp3TranscriptedName, setMp3TranscriptedName] = useState('');
	const [isTranscription, setIsTranscription] = useState(false);
	const [resumeLength, setResumeLength] = useState(0);
	const [resume, setResume] = useState('');
	const [keys, setKeys] = useState();
	const [keyWords, setKeyWords] = useState([]);
	const loadMp3 = () => {
		axios
			.post(
				`${REACT_APP_POC_IA}/mp3`,
				{ url: mp3Url },
				{
					headers: { 'Content-Type': 'application/json' },
				}
			)
			.then((response) => {
				setIsLoaded(true);
				setMessage((prevMessage) => ({
					...prevMessage,
					stepOne: '',
				}));
				setMp3Name(response.data.name);
			})
			.catch(function (error) {
				setMessage((prevMessage) => ({
					...prevMessage,
					stepOne: "Une erreur s'est produite",
				}));
			});
	};
	function sendForm(event) {
		event.preventDefault();
		loadMp3();
		setMessage((prevMessage) => ({
			...prevMessage,
			stepOne: 'Chargement mp3 en cours...',
		}));
	}
	const transcribeMp3 = () => {
		axios
			.post(
				`${REACT_APP_POC_IA}/transcript `,
				{ name: mp3Name },
				{
					headers: { 'Content-Type': 'application/json' },
				}
			)
			.then((response) => {
				setMessage((prevMessage) => ({
					...prevMessage,
					stepOne: '',
				}));
				setMp3TranscriptedName(response.data.name);
				setIsTranscription(true);
			})
			.catch(function (error) {
				setMessage((prevMessage) => ({
					...prevMessage,
					stepOne: error.status === 413 ? error.message : "Une erreur s'est produite.",
				}));
			});
	};
	function transcription() {
		transcribeMp3();
		setMessage((prevMessage) => ({
			...prevMessage,
			stepOne: (
				<div className="d-flex align-items-center">
					Transcription en cours
					<img src={Loader} alt="loading" style={{ width: '30px', height: '30px', marginLeft: '10px' }} />
				</div>
			),
		}));
	}

	const resumeMp3 = () => {
		axios
			.post(
				`${REACT_APP_POC_IA}/resume `,
				{ name: mp3TranscriptedName, resumeLength: resumeLength },
				{
					headers: { 'Content-Type': 'application/json' },
				}
			)
			.then((response) => {
				setMessage((prevMessage) => ({
					...prevMessage,
					stepThree: '',
				}));
				setResume(response.data.resume);
			})
			.catch(function (error) {
				setMessage((prevMessage) => ({
					...prevMessage,
					stepThree: "Une erreur s'est produite.",
				}));
			});
	};

	function sendResumeForm(event) {
		event.preventDefault();
		resumeMp3();
		setMessage((prevMessage) => ({
			...prevMessage,
			stepThree: 'Résumé en cours...',
		}));
	}
	const generateKeys = () => {
		axios
			.post(
				`${REACT_APP_POC_IA}/keys `,
				{ name: mp3TranscriptedName, keysQty: keys },
				{
					headers: { 'Content-Type': 'application/json' },
				}
			)
			.then((response) => {
				setMessage((prevMessage) => ({
					...prevMessage,
					stepFour: '',
				}));
				setKeyWords(response.data.keys);
			})
			.catch(function (error) {
				setMessage((prevMessage) => ({
					...prevMessage,
					stepFour: "Une erreur s'est produite.",
				}));
			});
	};
	function sendKeysForm(event) {
		event.preventDefault();
		generateKeys();
		setMessage((prevMessage) => ({
			...prevMessage,
			stepFour: 'Génération des mots clé en cours...',
		}));
	}
	function resetMp3() {
		setMp3Url('');
		setIsLoaded(false);
		setMessage({
			stepOne: '',
			stepTwo: '',
			stepThree: '',
			stepFour: '',
		});
		setMp3Name('');
		setMp3TranscriptedName('');
		setIsTranscription('');
		setResumeLength('');
		setResume('');
		setKeys('');
		setKeyWords([]);
	}
	return (
		<div className="pb-5 text-center">
			<div>
				<img src={RpLogo} alt="Radioplayer" style={{ width: '30vh', marginTop: '3vh' }} />
			</div>
			<h1 className="mb-5 pt-4">Proof of Concept : Conversion d'un podcast audio</h1>
			<form className="iaFormWidth text-end" onSubmit={sendForm} style={{ margin: 'auto' }}>
				<div className="mb-3 text-start">
					<label htmlFor="inputUrl" className="form-label">
						Url du Mp3
					</label>
					<div className="input-group">
						<input
							type="text"
							className="form-control"
							id="inputUrl"
							name="url"
							required
							autoComplete="off"
							value={mp3Url || ''}
							onChange={(event) => setMp3Url(event.target.value)}
						/>
						<div
							className="input-group-addon d-flex align-items-center px-2 border"
							style={{ cursor: 'pointer', borderRadius: '0 5px 5px 0' }}
							onClick={resetMp3}
						>
							<HiOutlineRefresh size={20} />
						</div>
					</div>
				</div>
				{!isLoaded ? (
					<button type="submit" className="btn btn-primary">
						Charger mp3
					</button>
				) : null}
			</form>
			<div className="mt-5 iaFormWidth" style={{ margin: 'auto' }}>
				<div className="form-label text-start">Résultat:</div>
				<div className="form-control border text-start" rows="3" style={{ minHeight: '50px' }}>
					{message.stepOne
						? message.stepOne
						: mp3Name && !mp3TranscriptedName
							? 'Chargement réussi'
							: mp3TranscriptedName
								? 'Transcription réussie'
								: null}
				</div>
				{isLoaded ? (
					<div className="text-end">
						<button className="btn btn-success mt-3" onClick={transcription}>
							Transcrire en texte
						</button>
					</div>
				) : null}
			</div>

			{isTranscription ? (
				<>
					<div className="mt-5 iaFormWidth" style={{ margin: 'auto' }}>
						<div className="form-label text-start">Nom du fichier transcrit :</div>
						<div className="form-control text-start" rows="3" style={{ minHeight: '50px' }}>
							{message.stepTwo ? message.stepTwo : mp3TranscriptedName ? mp3TranscriptedName : null}
						</div>
					</div>
					<form
						className="iaForm iaFormWidth text-end mt-5"
						onSubmit={sendResumeForm}
						style={{ margin: 'auto' }}
					>
						<div className="mb-3 text-start">
							<label htmlFor="resume" className="form-label">
								Nombre de mot moyen de la description:
							</label>
							<input
								type="number"
								className="form-control"
								id="resume"
								name="resume"
								min={20}
								required
								autoComplete="off"
								onChange={(event) => setResumeLength(event.target.value)}
							/>
						</div>
						<button type="submit" className="btn btn-primary">
							Générer résumé
						</button>
					</form>
					<div className="mt-5 iaFormWidth" style={{ margin: 'auto' }}>
						<div className="form-label text-start">Résultat:</div>
						<div className="form-control text-start" rows="3" style={{ minHeight: '50px' }}>
							{message.stepThree ? message.stepThree : resume ? resume : null}
						</div>
					</div>
				</>
			) : null}
			{resume ? (
				<>
					<form
						className="iaForm iaFormWidth text-end mt-5"
						onSubmit={sendKeysForm}
						style={{ margin: 'auto' }}
					>
						<div className="mb-3 text-start">
							<label htmlFor="keys" className="form-label">
								Nombre de mots clés à générer:
							</label>
							<input
								type="number"
								className="form-control"
								id="keys"
								name="keys"
								min={2}
								required
								autoComplete="off"
								onChange={(event) => setKeys(event.target.value)}
							/>
						</div>
						<button type="submit" className="btn btn-primary">
							Générer mots clé
						</button>
					</form>
					<div className="mt-5 iaFormWidth" style={{ margin: 'auto' }}>
						<div className="form-label text-start">Résultat:</div>
						<div className="form-control text-start" rows="3" style={{ minHeight: '50px' }}>
							{message.stepFour
								? message.stepFour
								: keyWords.length > 0
									? keyWords.map((key, index) => (
											<div key={index} className="my-1">
												{key} <br />
											</div>
										))
									: null}
						</div>
					</div>
				</>
			) : null}
		</div>
	);
}

export default PocIA;
