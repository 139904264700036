import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import {
	selectToken,
	selectOnboarding,
	selectPodcastsNames,
	selectRadiosErrorMessage,
	selectRadiosErrorMessage400,
	selectRadiosMessage,
	selectRadiosServerErrorMessage,
} from '../../../selectors';
import { getOnboarding, getPodcastsNames, updateOnboarding } from '../../../services/Podcasts';
import { setErrorServerMessage, setErrorMessage, setErrorMessage400 } from '../../../features/radiosSlice';
import InsideMenu from '../../../components/InsideMenu';
import Spinner from '../../../components/Spinner';
import { modalStyles } from '../../../utils/const';
import { dateFormatting, deleteWhiteSpacesInObject } from '../../../utils/functions';
import Loader from '../../../assets/loading-new.gif';

const { REACT_APP_API_URL } = process.env;

function PodcastOnboardingUpdate({ groupId, isAdmin }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const [isLoaded, setLoaded] = useState(false);
	const [groups, setGroups] = useState([]);
	const [podcastGroupName, setPodcastGroupName] = useState('');
	const [podcastsInfos, setPodcastsInfos] = useState('');
	const [selectedGroup, setSelectedGroup] = useState();
	const onboarding = useSelector(selectOnboarding);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const podcastsNames = useSelector(selectPodcastsNames);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const errorMessage400 = useSelector(selectRadiosErrorMessage400);
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);
	const [formInputs, setFormInputs] = useState({
		podcast: '',
		image_1920: {},
		date_debut: '',
		date_fin: '',
	});
	useEffect(() => {
		if (token) {
			if (isAdmin) {
				if (selectedGroup) {
					dispatch(getPodcastsNames(token, selectedGroup));
				}
			} else {
				dispatch(getPodcastsNames(token, groupId));
			}
		}
	}, [dispatch, token, groupId, selectedGroup, isAdmin]);
	useEffect(() => {
		if (token) {
			dispatch(getOnboarding(token, params.idSeries));
		}
	}, [dispatch, token, params]);
	useEffect(() => {
		if (onboarding) {
			setLoaded(true);
			setFormInputs({
				podcast: onboarding.id_series,
				image_1920: onboarding.image,
				date_debut: dateFormatting(onboarding.date_debut),
				date_fin: dateFormatting(onboarding.date_fin),
			});
			setSelectedGroup(onboarding.groupe_id);
		}
	}, [dispatch, onboarding]);
	useEffect(() => {
		if (token) {
			axios
				.get(`${REACT_APP_API_URL}/groups`, {
					headers: { 'x-auth-token': token },
				})
				.then((res) => {
					setGroups(res.data.groups);
				})
				.catch(function (error) {
					navigate('/');
				});
		}
	}, [token, navigate]);

	useEffect(() => {
		if (groups && groups.length > 0 && onboarding) {
			setPodcastGroupName(groups.find((group) => group.id === onboarding.groupe_id).nom);
		}
		if (podcastsNames && podcastsNames.length > 0 && onboarding) {
			setPodcastsInfos(podcastsNames.find((podcast) => podcast.id === onboarding.id_series));
		}
	}, [groups, onboarding, podcastsNames]);
	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/onboardings');
	}

	const updateFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
	};

	const handleChangeFormInputs = (event) => {
		updateFormInput(event.target.name, event.target.value);
	};
	const updateImgFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
	};
	const handleChangeImgInputs = (event) => {
		updateImgFormInput(event.target.name, event.target.files[0]);
	};
	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorMessage400([]));
		dispatch(setErrorServerMessage(''));
	};
	const submitForm = (event) => {
		event.preventDefault();
		deleteWhiteSpacesInObject(formInputs);
		dispatch(updateOnboarding(token, Number(onboarding.id), formInputs));
		setIsModalOpen(true);
	};
	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to={`/onboardings`} className="fs-5 " onClick={() => dispatch(setErrorServerMessage(''))}>
					Retour
				</Link>
			</div>
		);
	}

	return (
		<div>
			<InsideMenu
				homeLink={`/onboardings`}
				title={'Gestion De La Une Podcasts'}
				links={[{ name: "Ajout d'un podcast De Une", path: '/onboardings/add' }]}
			/>
			{isLoaded ? (
				<div className="RadioManager bg-white rounded-4 p-5 mx-4">
					<div className="d-flex justify-content-start mb-3 fs-5">
						{podcastGroupName ? podcastGroupName : null} - {podcastsInfos?.radio} - {podcastsInfos?.nom}
					</div>
					<form onSubmit={submitForm}>
						<div className="mb-3">
							<label className="form-label d-flex align-items-start flex-column ">
								Image 1920x1080
								{onboarding?.image ? (
									<img src={onboarding.image} border="" width="200" alt={`old Logo`} />
								) : (
									<img src={Loader} border="" width="200" alt="loader" />
								)}
							</label>
							<input
								className="form-control"
								type="file"
								name="image_1920"
								accept=".jpg,.jpeg,.png"
								value={undefined}
								onChange={handleChangeImgInputs}
							/>
						</div>
						<div className="row">
							<div className="mb-3 col-4">
								<label className="form-label d-flex justify-content-start">
									Date début (AAAA-MM-JJ)
								</label>
								<input
									className="form-control"
									type="text"
									name="date_debut"
									value={formInputs.date_debut || ''}
									onChange={handleChangeFormInputs}
									pattern="^\d{4}-\d{2}-\d{2}$"
								/>
							</div>
							<div className="mb-3 col-4">
								<label className="form-label d-flex justify-content-start">Date fin (AAAA-MM-JJ)</label>
								<input
									className="form-control"
									type="text"
									name="date_fin"
									value={formInputs.date_fin || ''}
									onChange={handleChangeFormInputs}
									pattern="^\d{4}-\d{2}-\d{2}$"
								/>
							</div>
						</div>
						<div className="d-flex mt-5">
							<input className="formSubmitButton" type="submit" value="Modifier la mise en avant" />
						</div>
					</form>
				</div>
			) : (
				<Spinner />
			)}
			<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				{successMessage ? (
					<div className="text-success">
						Votre demande de modification a bien été envoyée.
						<br />
						<Link to={`/onboardings`} className="fs-5 " onClick={eraseErrorMessage}>
							Retour
						</Link>
					</div>
				) : errorMessage ? (
					<div className="mt-5">
						<div className="text-danger">{errorMessage}</div>
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-secondary w-30"
								onClick={() => {
									setIsModalOpen(false);
									eraseErrorMessage();
								}}
							>
								Retour
							</button>
						</div>
					</div>
				) : errorMessage400.length > 0 ? (
					<div>
						<div>Les erreurs suivantes sont apparues dans les données de votre formulaire:</div>
						{errorMessage400.map((message, index) => (
							<div key={index} className="my-2">
								<div className="text-danger"> - {message.msg}</div>
							</div>
						))}
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-secondary w-30"
								onClick={() => {
									setIsModalOpen(false);
									eraseErrorMessage();
								}}
							>
								Retour
							</button>
						</div>
					</div>
				) : null}
			</Modal>
		</div>
	);
}

export default PodcastOnboardingUpdate;
