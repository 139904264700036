export const defaultCenter = {
	lat: 46.232193,
	lng: 2.209667,
};

export const containerStyle = {
	width: '100%',
	height: '500px',
};

export const polygonOptions = {
	fillOpacity: 0.3,
	fillColor: '#ff0000',
	strokeColor: '#ff0000',
	strokeWeight: 2,
	draggable: true,
	editable: true,
};
