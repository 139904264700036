import React from 'react';

function GeneralInfosInputs({ type, formInputs, handleChangeFormInputs }) {
	return (
		<div className="row">
			<div className="mb-3 ">
				<label htmlFor="formnomInput" className="form-label d-flex justify-content-start">
					Nom Général
				</label>
				<input
					type="text"
					className="form-control"
					id="formnomInput"
					name="nom"
					value={formInputs.radio.nom || ''}
					onChange={handleChangeFormInputs}
				/>
			</div>
			{type === 3 ? null : (
				<>
					<div className="mb-3">
						<label htmlFor="formnom_courtInput" className="form-label d-flex justify-content-start">
							Nom Court (8 caractères)
						</label>
						<input
							type="text"
							className="form-control"
							id="formnom_courtInput"
							maxLength={8}
							name="nom_court"
							value={formInputs.radio.nom_court || ''}
							onChange={handleChangeFormInputs}
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="formnom_moyenInput" className="form-label d-flex justify-content-start">
							Nom Moyen (16 caractères)
						</label>
						<input
							type="text"
							className="form-control"
							id="formnom_moyenInput"
							maxLength={16}
							name="nom_moyen"
							value={formInputs.radio.nom_moyen || ''}
							onChange={handleChangeFormInputs}
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="formSloganInput" className="form-label d-flex justify-content-start">
							Slogan
						</label>
						<textarea
							type="text"
							className="form-control"
							id="formSloganInput"
							rows="1"
							name="description_courte"
							value={formInputs.radio.description_courte || ''}
							onChange={handleChangeFormInputs}
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="formDescriptionInput" className="form-label d-flex justify-content-start">
							Description
						</label>
						<textarea
							type="text"
							className="form-control"
							id="formDescriptionInput"
							rows="3"
							name="description"
							value={formInputs.radio.description || ''}
							onChange={handleChangeFormInputs}
						/>
					</div>
				</>
			)}
		</div>
	);
}

export default GeneralInfosInputs;
