import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import {
	selectToken,
	selectRadioPrograms,
	selectRadiosMessage,
	selectCorrectProgramsRequestCount,
	selectBadProgramsRequestCount,
	selectRadiosErrorMessage,
	selectRadiosErrorMessage400,
} from '../../../selectors';
import { createRadioProgram, getRadioPrograms, deleteRadioPrograms } from '../../../services/Radios';
import './style.css';
import { modalStyles, innerModalStyles, validDataModel } from '../../../utils/const';
import {
	resetCorrectProgramsRequestCounter,
	resetBadProgramsRequestCounter,
	addToBadProgramsRequestCounter,
	setErrorMessage,
	setMessage,
	setErrorMessage400,
} from '../../../features/radiosSlice';

function AddPrograms({ setData }) {
	const dispatch = useDispatch();
	const params = useParams();
	const [jsonFileContent, setJsonFileContent] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [messageModalIsOpen, setMessageModalIsOpen] = useState(false);
	const [jsonConversionMessage, setJsonConversionMessage] = useState('');
	const [isFile, setIsFile] = useState(false);
	const [dataErrorMessage, setDataErrorMessage] = useState([]);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const radioPrograms = useSelector(selectRadioPrograms);
	const successMessage = useSelector(selectRadiosMessage);
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const errorMessage400 = useSelector(selectRadiosErrorMessage400);
	const correctProgramsRequestQuantity = useSelector(selectCorrectProgramsRequestCount);
	const badProgramsRequestQuantity = useSelector(selectBadProgramsRequestCount);

	useEffect(() => {
		if (radioPrograms) {
			setData(radioPrograms);
		}
	}, [dispatch, radioPrograms, setData]);

	Modal.setAppElement('body');
	async function urlToFile(url, filename, mimeType) {
		// Fetch the resource
		const res = await fetch(url);
		// Check for a successful fetch
		if (!res.ok) {
			throw new Error(`Failed to fetch ${url}`);
		}
		// Get the blob from the response
		const blob = await res.blob();
		// Create a file from the blob
		return new File([blob], filename, { type: mimeType });
	}

	const handleChangeInput = (event) => {
		const jsonFile = event.target.files[0];
		const fileReader = new FileReader();
		fileReader.onload = function (fileEvent) {
			const fileContent = fileEvent.target.result;
			try {
				const jsonData = JSON.parse(fileContent);
				localStorage.setItem('newPrograms', JSON.stringify(jsonData));
				setJsonFileContent(JSON.parse(localStorage.getItem('newPrograms')));
			} catch (error) {
				setJsonConversionMessage("Une erreur s'est produite lors de l'envoi du fichier JSON.");
				setMessageModalIsOpen(true);
			}
		};
		fileReader.readAsText(jsonFile);
		setIsFile(true);
	};

	const keyControl = (program, innerProgramIt, programIt) => {
		if (!(Object.keys(program)[innerProgramIt] === validDataModel[innerProgramIt].name)) {
			setDataErrorMessage((dataErrorMessage) => [
				...dataErrorMessage,
				{
					message: `La clé "${Object.keys(program)[innerProgramIt]}" du programme N° ${
						programIt + 1
					} est incorrecte, la clé "${validDataModel[innerProgramIt].name}" est attendue`,
				},
			]);
		}
		return Object.keys(program)[innerProgramIt] === validDataModel[innerProgramIt].name;
	};

	const typeControl = (program, innerProgramIt, programIt) => {
		if (!(typeof Object.values(program)[innerProgramIt] === validDataModel[innerProgramIt].type)) {
			setDataErrorMessage((dataErrorMessage) => [
				...dataErrorMessage,
				{
					message: `Le type de "${Object.keys(program)[innerProgramIt]}" du programme N° ${
						programIt + 1
					} est incorrect, le type "${validDataModel[innerProgramIt].type}" est attendu`,
				},
			]);
		}
		return typeof Object.values(program)[innerProgramIt] === validDataModel[innerProgramIt].type;
	};
	const maxLengthControl = (program, innerProgramIt) => {
		if (
			typeof Object.values(program)[innerProgramIt] === 'string' &&
			validDataModel[innerProgramIt].maxLength !== null &&
			validDataModel[innerProgramIt].type === 'string'
		) {
			if (!(Object.values(program)[innerProgramIt].length <= validDataModel[innerProgramIt].maxLength)) {
				setDataErrorMessage((dataErrorMessage) => [
					...dataErrorMessage,
					{
						message: `Le nombre de caractères de "${validDataModel[innerProgramIt].name}" : "${
							Object.values(program)[innerProgramIt]
						}" est de ${Object.values(program)[innerProgramIt].length} alors qu'une longueur max de ${
							validDataModel[innerProgramIt].maxLength
						} est attendue`,
					},
				]);
			}
			return Object.values(program)[innerProgramIt].length <= validDataModel[innerProgramIt].maxLength;
		}
		return true;
	};

	const valueControl = (program, innerProgramIt) => {
		if (validDataModel[innerProgramIt].value !== null) {
			if (!validDataModel[innerProgramIt].value.includes(Object.values(program)[innerProgramIt])) {
				setDataErrorMessage((dataErrorMessage) => [
					...dataErrorMessage,
					{
						message: `La valeur "${Object.values(program)[innerProgramIt]}" de la clé "${
							validDataModel[innerProgramIt].name
						}" du programme "${Object.values(program)[1]}" est incorrecte, la valeur doit être ${
							validDataModel[innerProgramIt].value
						}ou ""`,
					},
				]);
			}
			return validDataModel[innerProgramIt].value.includes(Object.values(program)[innerProgramIt]);
		}
		return true;
	};

	const checkFileContent = (programsFile) => {
		let isDataCorrect = true;
		if (programsFile) {
			for (let programIt = 0; programIt < programsFile.length; programIt++) {
				for (
					let innerProgramIt = 0;
					innerProgramIt < Object.keys(programsFile[programIt]).length;
					innerProgramIt++
				) {
					valueControl(programsFile[programIt], innerProgramIt);
					if (
						!(
							keyControl(programsFile[programIt], innerProgramIt, programIt) &&
							typeControl(programsFile[programIt], innerProgramIt, programIt) &&
							maxLengthControl(programsFile[programIt], innerProgramIt) &&
							valueControl(programsFile[programIt], innerProgramIt)
						)
					) {
						isDataCorrect = false;
					}
				}
			}
			const mimeType = 'image/jpeg';
			programsFile.forEach((program, index) => {
				const fileType = program.image_1400.split('.').pop();
				if (fileType !== 'jpg' && fileType !== 'jpeg' && fileType !== 'png') {
					dispatch(addToBadProgramsRequestCounter());
					setDataErrorMessage((dataErrorMessage) => [
						...dataErrorMessage,
						{
							message: `Le format de "image_1400" du programme "${program.nom_long}" est incorrecte.`,
						},
					]);
					isDataCorrect = false;
				} else {
					urlToFile(program.image_1400, `newImg${index}.jpg`, mimeType)
						.then((file) => {
							program['image_1400'] = file;
						})
						.catch((err) => {
							setDataErrorMessage((dataErrorMessage) => [
								...dataErrorMessage,
								{
									message: `Erreur durant le téléchargement de "image_1400" du programme "${program.nom_long}", veuillez la modifier manuellement depuis la page de modification.`,
								},
							]);
						})
						.finally(() => {
							dispatch(createRadioProgram(token, params.rpID, program));
						});
				}
			});
			return isDataCorrect;
		}
	};

	const checkAndSaveFile = (fileContent) => {
		if (checkFileContent(fileContent) === true) setJsonConversionMessage('Données du fichier Json correctes');
	};
	const eraseMessages = () => {
		setDataErrorMessage([]);
		setJsonConversionMessage('');
		dispatch(setErrorMessage(''));
		dispatch(setMessage(''));
		dispatch(setErrorMessage400([]));
	};
	const eraseData = () => {
		setJsonFileContent('');
		localStorage.removeItem('newPrograms');
	};
	const eraseOldPrograms = () => {
		dispatch(deleteRadioPrograms(token, params.rpID));
	};
	const sendData = () => {
		checkAndSaveFile(jsonFileContent);
		setMessageModalIsOpen(true);
		eraseOldPrograms();
		setIsFile(false);
	};
	const cancelJsonLoader = () => {
		setIsModalOpen(false);
		eraseMessages();
		eraseData();
		setMessageModalIsOpen(false);
		dispatch(resetCorrectProgramsRequestCounter());
		dispatch(resetBadProgramsRequestCounter());
	};

	const closeAlertModal = () => {
		setMessageModalIsOpen(false);
		setIsModalOpen(false);
		eraseMessages();
		eraseData();
		dispatch(getRadioPrograms(token, params.rpID, 'O'));
		dispatch(resetCorrectProgramsRequestCounter());
		dispatch(resetBadProgramsRequestCounter());
	};

	return (
		<div>
			<button className="loadJson" onClick={() => setIsModalOpen(true)}>
				Charger Json
			</button>
			<Modal
				isOpen={isModalOpen}
				onRequestClose={() => setIsModalOpen(true)}
				style={modalStyles}
				contentLabel="Modal to add file"
			>
				<Modal
					isOpen={messageModalIsOpen}
					onRequestClose={() => setMessageModalIsOpen(false)}
					style={innerModalStyles}
					contentLabel="Modal to show messages"
				>
					<h5>{jsonConversionMessage}</h5>
					{dataErrorMessage?.length > 0
						? dataErrorMessage.map((message, index) => (
								<div className="mb-3" key={index}>
									{message.message}
								</div>
							))
						: null}
					{successMessage && errorMessage400?.length === 0 ? (
						<div>
							{correctProgramsRequestQuantity}{' '}
							{correctProgramsRequestQuantity <= 1
								? 'programme a bien été envoyé.'
								: 'programmes ont bien été envoyés.'}
							<br />
							{badProgramsRequestQuantity}{' '}
							{badProgramsRequestQuantity <= 1
								? "n'a pas pu être envoyé."
								: badProgramsRequestQuantity > 1
									? "n'ont pas pu être envoyés."
									: null}
							<br />
						</div>
					) : errorMessage400?.length > 0 && !successMessage ? (
						<div>
							<div>Les erreurs suivantes sont apparues dans les données de votre formulaire:</div>
							{errorMessage400.map((message, index) => (
								<div key={index} className="my-2">
									<div className="text-danger"> - {message.msg}</div>
								</div>
							))}
						</div>
					) : errorMessage ? (
						<div>
							<div>Une erreur est survenue, veuillez rééssayer ultérieurement.</div>{' '}
							<div className="d-flex justify-content-center">
								<button className="btn btn-secondary w-30" onClick={closeAlertModal}>
									Retour
								</button>
							</div>
						</div>
					) : null}
					{correctProgramsRequestQuantity + badProgramsRequestQuantity === jsonFileContent?.length ? (
						<button className="gobackButton mt-3" onClick={closeAlertModal}>
							Retour
						</button>
					) : null}
				</Modal>
				<div className="text-danger mb-3 fs-5">
					Attention, l'envoi d'un fichier va écraser toutes les émissions existantes.
				</div>
				<div>
					<label className="form-label mt-3"> Fichier json</label>
					<input
						className="form-control mb-3"
						type="file"
						name="jsonFile"
						accept=".json"
						value={undefined}
						onChange={handleChangeInput}
					/>
					<br />
				</div>
				{!isFile ? <div className="mb-1 text-danger">Fichier manquant</div> : null}
				<div className="d-flex">
					<button
						className="cancelButton me-2"
						onClick={() => {
							setIsModalOpen(false);
							setJsonConversionMessage('');
							setMessageModalIsOpen(false);
							cancelJsonLoader();
							setIsFile(false);
						}}
					>
						Annuler
					</button>
					{isFile ? (
						<button className="sendButton" onClick={sendData}>
							Envoyer
						</button>
					) : null}
				</div>
			</Modal>
		</div>
	);
}

export default AddPrograms;
