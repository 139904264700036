import React from 'react';
import './style.css';

function Localisation({ data, title }) {
	return (
		<div className="row d-flex flex-column flex-lg-row rounded-4 mx-4 mt-5 bg-white">
			<div className="fs-5 text-start fw-bold ms-4 mb-2 pt-3">{title}</div>
			<div className="d-flex flex-column ms-4 my-3">
				{data &&
					data.map((loc, index) => (
						<div key={index} className="d-flex flex-column">
							<div className="d-flex justify-content-start">
								<div className="locNumber fw-bold text-start">{index + 1 + ') '}</div>

								<div className="locRegion fw-bold text-start">{loc.region}</div>

								<div className="locPercent text-start">{loc.ecoutes}</div>
							</div>
						</div>
					))}
			</div>
		</div>
	);
}

export default Localisation;
