import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { selectToken, selectStatistics, selectAnalyticsError } from '../../selectors';
import { getStatistics } from '../../services/Analytics';
import { setErrorAnalyticsMessage } from '../../features/analyticsSlice';
import InsideMenu from '../../components/InsideMenu';
import Spinner from '../../components/Spinner';
import StatsPie from '../../components/Analytics/StatsPie';
import StatsLineChart from '../../components/Analytics/StatsLineChart';
import GoToTop from '../../components/GoToTop';
import InfoBloc from '../../components/Analytics/InfoBloc';
import SelectedDatesForm from '../../components/Analytics/SelectedDatesForm';
import LogosCarousel from '../../components/Analytics/LogosCarousel';
import TopRadios from './children/TopRadios';
import InfosBlocs from './children/InfosBlocs';
import Localisation from './children/Localisation';
import GroupRadios from './children/GroupRadios';
import {
	dateFormattingTwoDaysAgo,
	daysBetweenTwoDates,
	scrollTop,
	aggregateStationsDetails,
	aggregateFavorites,
	averageListens,
	isDifferenceMoreThanTwoMonths,
} from '../../utils/functions';
import './style.css';

function RadiosStatistics() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const statistics = useSelector(selectStatistics);
	const serverErrorMessage = useSelector(selectAnalyticsError);
	const [isLoaded, setIsLoaded] = useState(false);
	const [groups, setGroups] = useState([]);
	const [favorites, setFavorites] = useState([]);
	const [stationsDetails, setStationsDetails] = useState([]);
	const [liveListenings, setLiveListeningst] = useState([]);
	const [mode, setMode] = useState([]);
	const [network, setNetwork] = useState([]);
	const [analyticsPodcasts, setAnalyticsPodcasts] = useState([]);
	const [podcastsLoc, setPodcastsLoc] = useState([]);
	const [liveLoc, setLiveLoc] = useState([]);
	const [radiosMostListened, setRadiosMostListened] = useState([]);
	const [listenings, setListenings] = useState();
	const [listenings30, setListen30] = useState();
	const [averageDuration, setAverageDuration] = useState();
	const [mau, setMau] = useState();
	const [topRadios, setTopRadios] = useState([]);
	const [associateGroup, setAssociateGroup] = useState([]);
	const [totalFavorites, setTotalFavorites] = useState();
	const [favoritesRadios, setFavoritesRadios] = useState([]);
	const [podcastsMostListened, setPodcastsMostListened] = useState([]);
	const [podcastsListenings, setPodcastsListenings] = useState();
	const [averagePodcastsListening, setAveragePodcastsListening] = useState();
	const [warningMessage, setWarningMessage] = useState('');
	const [isTwoMonthBetweenDates, setIsTwoMonthBetweenDates] = useState(false);

	const getScreenSize = useCallback(() => {
		return window.innerWidth;
	}, []);

	const [windowSize, setWindowSize] = useState(getScreenSize);
	const [formDates, setFormDates] = useState({});

	useEffect(() => {
		setFormDates({
			start: !!params.old ? '2023-07-14' : dateFormattingTwoDaysAgo(),
			end: !!params.old ? '2023-07-14' : dateFormattingTwoDaysAgo(),
		});
	}, [params]);

	useEffect(() => {
		setIsLoaded(false);
	}, [params]);

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		function handleResize() {
			setWindowSize(getScreenSize());
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [windowSize, getScreenSize]);

	useEffect(() => {
		if (token) {
			dispatch(
				getStatistics(
					token,
					!!params.old ? '2023-07-14' : dateFormattingTwoDaysAgo(),
					!!params.old ? '2023-07-14' : dateFormattingTwoDaysAgo(),
					!!params.old
				)
			);
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, params]);
	useEffect(() => {
		if (statistics) {
			setGroups(statistics?.groups);
			setFavorites(statistics.stats?.favoris);
			setStationsDetails(statistics.stats?.stationsDetails);
			setLiveListeningst(statistics.stats?.stationsListens);
			setMode(statistics.stats?.outputs);
			setNetwork(statistics.stats?.networks);
			setAnalyticsPodcasts(statistics.stats?.podcastsDetails);
			setPodcastsLoc(statistics.stats?.podcastsLocalisations);
			setLiveLoc(statistics.stats?.stationsLocalisations);
			setIsLoaded(true);
		}
	}, [dispatch, statistics]);

	useEffect(() => {
		if (stationsDetails.length > 0) {
			setRadiosMostListened(stationsDetails?.slice(0, 100).sort((a, b) => b.ecoutes - a.ecoutes));
			setListenings(stationsDetails.reduce((acc, current) => acc + current.ecoutes, 0));
			setListen30(stationsDetails.reduce((acc, current) => acc + current.ecoutes30s, 0));
			setAverageDuration(
				averageListens(
					stationsDetails.reduce((acc, current) => acc + current.duree_totale, 0),
					listenings
				)
			);
			const daysCount = daysBetweenTwoDates(
				new Date(sessionStorage.getItem('endDate')),
				new Date(sessionStorage.getItem('startDate'))
			);
			setMau(
				daysCount > 1
					? Math.round(stationsDetails.reduce((acc, current) => acc + current.mau, 0) / daysCount)
					: Math.round(stationsDetails.reduce((acc, current) => acc + current.mau, 0))
			);
			const aggregatedPerRpid = aggregateStationsDetails(stationsDetails, 'rpid');
			setTopRadios(aggregatedPerRpid.slice(0, 20).sort((a, b) => b.ecoutes - a.ecoutes));
			if (groups.length > 0) {
				const defineRadioGroup = (duration) => {
					return groups.find((group) => group.rpids.includes(Number(duration.rpid)));
				};
				const reduceStationsDetailsPerGroup = (data) => {
					const dataPerRpidWithGroupId = data.map((elt) => {
						return {
							groupid: defineRadioGroup(elt)?.id,
							groupname: defineRadioGroup(elt)?.nom,
							duree_totale: elt.duree_totale,
							duree_totale30s: elt.duree_totale30s,
							ecoutes: elt.ecoutes,
							ecoutes30s: elt.ecoutes30s,
							mau: elt.mau,
							rpid: elt.rpid,
						};
					});
					return aggregateStationsDetails(dataPerRpidWithGroupId, 'groupid', 'groupname');
				};
				setAssociateGroup(
					reduceStationsDetailsPerGroup(aggregatedPerRpid).sort((a, b) => b.ecoutes - a.ecoutes)
				);
			}
		}
	}, [stationsDetails, groups, listenings]);

	useEffect(() => {
		if (favorites.length > 0) {
			setTotalFavorites(favorites.reduce((acc, current) => acc + current.count, 0));
			setFavoritesRadios(
				aggregateFavorites(favorites, 'rpid')
					.slice(0, 100)
					.sort((a, b) => b.count - a.count)
			);
		}
	}, [favorites]);

	useEffect(() => {
		if (analyticsPodcasts.length > 0) {
			setPodcastsMostListened(analyticsPodcasts?.slice(0, 100).sort((a, b) => b.ecoutes - a.ecoutes));
			setPodcastsListenings(analyticsPodcasts.reduce((acc, current) => acc + current.ecoutes, 0));
			setAveragePodcastsListening(
				averageListens(
					analyticsPodcasts.reduce((acc, current) => acc + current.duree_totale, 0),
					podcastsListenings
				)
			);
		}
	}, [analyticsPodcasts, podcastsListenings]);

	useEffect(() => {
		if (formDates) setIsTwoMonthBetweenDates(isDifferenceMoreThanTwoMonths(formDates.start, formDates.end));
	}, [formDates]);

	const submitForm = (event) => {
		setWarningMessage('');
		event.preventDefault();
		if (formDates.start <= formDates.end) {
			setIsLoaded(false);
			dispatch(getStatistics(token, formDates.start, formDates.end));
		} else {
			setWarningMessage('La date de début doit être inférieure ou égale à la date de fin.');
		}
	};

	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to="/radios" className="fs-5 " onClick={() => dispatch(setErrorAnalyticsMessage(null))}>
					Retour
				</Link>
			</div>
		);
	}
	return (
		<div>
			<InsideMenu homeLink={`/radios`} links={[]} />
			<div className="fs-2 mb-5">{!!params.old ? 'Statistiques old' : 'Statistiques 7.8+'}</div>
			{isLoaded ? (
				<>
					<SelectedDatesForm
						submitForm={submitForm}
						setFormDates={setFormDates}
						formDates={formDates}
						params={params}
						warningMessage={warningMessage}
					/>

					{stationsDetails?.length > 0 ? (
						<InfosBlocs
							listenings={listenings}
							listenings30={listenings30}
							averageDuration={averageDuration}
							mau={mau}
							favorites={totalFavorites}
						/>
					) : (
						<div className="d-flex flex-column bg-white rounded-4 p-5 mx-4">
							Aucune donnée "en bref" disponible
						</div>
					)}
					{daysBetweenTwoDates(new Date(formDates.end), new Date(formDates.start)) !== 0 ? (
						liveListenings?.length > 0 ? (
							<div className="overflow-x-scroll scrollNone d-flex flex-column bg-white rounded-4 p-5 mx-4">
								<div className="fs-5 fw-bold text-start pb-4 mb-5 border-bottom">Ecoute Live</div>
								<StatsLineChart data={liveListenings} isTwoMonthBetweenDates={isTwoMonthBetweenDates} />
							</div>
						) : (
							<div className="d-flex flex-column bg-white rounded-4 p-5 mx-4">
								Aucune donnée "écoute live" disponible
							</div>
						)
					) : null}
					{topRadios?.length > 0 ? (
						<TopRadios topRadios={topRadios} />
					) : (
						<div className="d-flex flex-column bg-white rounded-4 p-5 mx-4 mt-5">
							Aucune donnée "top 20 radios" disponible
						</div>
					)}

					<div className="overflow-x-scroll scrollNone row d-flex flex-column flex-lg-row rounded-4 mx-4 mt-5">
						{mode?.length > 0 ? (
							<div className="col bg-white rounded-4 me-lg-3">
								<div className="fs-5 fw-bold text-start ps-4 py-4 border-bottom">Mode D'écoute</div>
								<StatsPie windowSize={windowSize} data={mode} title={'mode'} />
							</div>
						) : (
							<div className="d-flex justify-content-center bg-white rounded-4 p-5">
								Aucune donnée "mode d'écoute" disponible
							</div>
						)}
						{network?.length > 0 ? (
							<div className="col bg-white rounded-4 ms-lg-3 mt-3 mt-lg-0">
								<div className="fs-5 fw-bold text-start ps-4 py-4 border-bottom">Réseau Utilisé</div>
								<StatsPie windowSize={windowSize} data={network} title={'network'} />
							</div>
						) : (
							<div className="d-flex justify-content-center bg-white rounded-4 p-5 mt-5">
								Aucune donnée "réseau utilisé" disponible
							</div>
						)}
					</div>
					{radiosMostListened?.length > 0 ? (
						<LogosCarousel title={'Les radios les + écoutées'} data={radiosMostListened} legend={true} />
					) : (
						<div className="d-flex flex-column bg-white rounded-4 p-5 mx-4 mt-5">
							Aucune donnée "radio les plus écoutées" disponible
						</div>
					)}
					{associateGroup?.length > 0 ? (
						<GroupRadios data={associateGroup} listenings30={listenings30} groupsNames={groups} />
					) : (
						<div className="d-flex flex-column bg-white rounded-4 p-5 mx-4 mt-5">
							Aucune donnée "radios par groupe associés" disponible
						</div>
					)}
					{liveLoc?.length > 0 ? (
						<Localisation data={liveLoc} title={'Localisation - Live'} />
					) : (
						<div className="d-flex flex-column bg-white rounded-4 p-5 mx-4 mt-5">
							Aucune donnée "localisation live" disponible
						</div>
					)}
					{podcastsListenings ? (
						<div className="row d-flex flex-column flex-lg-row rounded-4 mx-4 mt-5">
							<div className="fs-5 fw-bold w-100 text-start mb-1">Ecoutes podcasts</div>
							<InfoBloc
								className={'podcastsListensBloc'}
								logoColor={'#8f85ff'}
								title={'Podcasts'}
								data={Number(podcastsListenings).toLocaleString()}
							/>
							<InfoBloc
								className={'podcastsAverageDurationBloc'}
								logoColor={'#f95a83'}
								title={'Durée moyenne'}
								data={averagePodcastsListening}
							/>
						</div>
					) : (
						<div className="d-flex flex-column bg-white rounded-4 p-5 mx-4 mt-5">
							Aucune donnée "podcasts" disponible
						</div>
					)}
					{podcastsMostListened?.length > 0 ? (
						<LogosCarousel
							title={'Podcasts les + écoutés'}
							data={podcastsMostListened}
							legend={true}
							listens={analyticsPodcasts?.ecoutes}
						/>
					) : (
						<div className="d-flex flex-column bg-white rounded-4 p-5 mx-4 mt-5">
							Aucune donnée "podcasts les plus écoutés" disponible
						</div>
					)}
					{podcastsLoc?.length > 0 ? (
						<Localisation data={podcastsLoc} title={'Localisation - Podcasts'} />
					) : (
						<div className="d-flex flex-column bg-white rounded-4 p-5 mx-4 mt-5">
							Aucune donnée "localisation podcasts" disponible
						</div>
					)}
					{favorites?.length > 0 ? (
						<LogosCarousel
							title={'Les radios les + mises en favoris'}
							data={favoritesRadios}
							legend={false}
						/>
					) : (
						<div className="d-flex flex-column bg-white rounded-4 p-5 mx-4 mt-5">
							Aucune donnée "radios les + mises en favoris" disponible
						</div>
					)}
				</>
			) : (
				<div className="d-flex flex-column align-items center">
					<Spinner />
				</div>
			)}
			<GoToTop />
		</div>
	);
}

export default RadiosStatistics;
