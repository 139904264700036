import { createSlice } from '@reduxjs/toolkit';

// creating a redux state for categories
export const categoriesSlice = createSlice({
	name: 'categories',
	initialState: {
		categories: null,
		message: null,
		errorMessage: null,
	},
	reducers: {
		// radios
		setCategories: (state, action) => {
			state.categories = action.payload;
		},
		// Message returned by the backend
		setMessage: (state, action) => {
			state.message = action.payload;
		},
		// Error Message returned by the backend
		setErrorMessage: (state, action) => {
			state.errorMessage = action.payload;
		},
	},
});

export const { setCategories, setMessage, setErrorMessage } = categoriesSlice.actions;
export default categoriesSlice.reducer;
