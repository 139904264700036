import { createSlice } from '@reduxjs/toolkit';

// creating a redux state for radios
export const radiosSlice = createSlice({
	name: 'radios',
	initialState: {
		radios: null,
		radiosLogoOnly: null,
		radio: null,
		radioPrograms: null,
		radiosPending: null,
		podcasts: null,
		podcastsNames: null,
		podcast: null,
		episodes: null,
		seriesId: null,
		onboardings: null,
		onboarding: null,
		logos: null,
		locals: null,
		local: null,
		pendingsLocals: null,
		message: null,
		errorMessage: null,
		errorMessage400: [],
		errorFeeds: [],
		errorFeed: null,
		errorServerMessage: null,
		correctProgramsRequestCount: 0,
		badProgramsRequestCount: 0,
		frequenciesFM: null,
		frequenciesDAb: null,
	},
	reducers: {
		setRadios: (state, action) => {
			state.radios = action.payload;
		},
		setRadiosLogoOnly: (state, action) => {
			state.radiosLogoOnly = action.payload;
		},
		setRadio: (state, action) => {
			state.radio = action.payload;
		},
		// Set programs of one radio
		setRadioPrograms: (state, action) => {
			state.radioPrograms = action.payload;
		},
		setRadiosPending: (state, action) => {
			state.radiosPending = action.payload;
		},
		setPodcasts: (state, action) => {
			state.podcasts = action.payload;
		},
		setPodcastsNames: (state, action) => {
			state.podcastsNames = action.payload;
		},
		setPodcast: (state, action) => {
			state.podcast = action.payload;
		},
		setEpisodes: (state, action) => {
			state.episodes = action.payload;
		},
		setSeriesId: (state, action) => {
			state.seriesId = action.payload;
		},
		setOnboardings: (state, action) => {
			state.onboardings = action.payload;
		},
		setOnboarding: (state, action) => {
			state.onboarding = action.payload;
		},
		setLogos: (state, action) => {
			state.logos = action.payload;
		},
		setLocals: (state, action) => {
			state.locals = action.payload;
		},
		setLocal: (state, action) => {
			state.local = action.payload;
		},
		setPendingsLocals: (state, action) => {
			state.pendingsLocals = action.payload;
		},
		// Success message returned by the backend
		setMessage: (state, action) => {
			state.message = action.payload;
		},
		// Error Message returned by the backend
		setErrorMessage: (state, action) => {
			state.errorMessage = action.payload;
		},
		// Error Message 400 returned by the backend
		setErrorMessage400: (state, action) => {
			state.errorMessage400 = action.payload;
		},
		// Error Message on podcasts feeds
		setErrorFeed: (state, action) => {
			state.errorFeed = action.payload;
		},
		// Error Message on podcasts feeds
		setErrorFeeds: (state, action) => {
			state.errorFeeds = [...state.errorFeeds, action.payload];
		},
		// Reset Error Message on podcasts feeds
		resetErrorFeeds: (state, action) => {
			state.errorFeeds = [];
		},
		// Error 500 returned from the backend
		setErrorServerMessage: (state, action) => {
			state.errorServerMessage = action.payload;
		},
		// Count programs when well send
		addToCorrectProgramsRequestCounter: (state, action) => {
			state.correctProgramsRequestCount = state.correctProgramsRequestCount + 1;
		},
		// Reset correctProgramsRequestCount
		resetCorrectProgramsRequestCounter: (state, action) => {
			state.correctProgramsRequestCount = 0;
		},
		// Count programs when well send
		addToBadProgramsRequestCounter: (state, action) => {
			state.badProgramsRequestCount = state.badProgramsRequestCount + 1;
		},
		// Reset badProgramsRequestCount
		resetBadProgramsRequestCounter: (state, action) => {
			state.badProgramsRequestCount = 0;
		},
		setFrequenciesFM: (state, action) => {
			state.frequenciesFM = action.payload;
		},
		setFrequenciesDAB: (state, action) => {
			state.frequenciesDAB = action.payload;
		},
	},
});

export const {
	setRadios,
	setRadiosLogoOnly,
	setRadio,
	setMessage,
	setRadioPrograms,
	setRadiosPending,
	setPodcasts,
	setPodcastsNames,
	setPodcast,
	setOnboardings,
	setOnboarding,
	setSeriesId,
	setEpisodes,
	setLogos,
	setLocals,
	setLocal,
	setPendingsLocals,
	setErrorMessage,
	setErrorMessage400,
	setErrorFeed,
	setErrorFeeds,
	resetErrorFeeds,
	setErrorServerMessage,
	addToCorrectProgramsRequestCounter,
	resetCorrectProgramsRequestCounter,
	addToBadProgramsRequestCounter,
	resetBadProgramsRequestCounter,
	setFrequenciesDAB,
	setFrequenciesFM,
} = radiosSlice.actions;

export default radiosSlice.reducer;
