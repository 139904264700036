import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import {
	selectToken,
	selectRadiosErrorMessage,
	selectRadiosMessage,
	selectRadiosErrorMessage400,
	selectRadiosServerErrorMessage,
} from '../../../selectors';
import { setErrorMessage, setErrorMessage400, setErrorServerMessage } from '../../../features/radiosSlice';
import { createRadio } from '../../../services/Radios';
import InsideMenu from '../../../components/InsideMenu';
import RadioTypeInputs from '../children/RadioTypeInputs';
import GeneralsInfosInputs from '../children/GeneralsInfosInputs';
import ImgInputs from '../children/ImgInputs';
import RpidInput from '../children/RpidInput';
import GoToTop from '../../../components/GoToTop';
import { modalStyles } from '../../../utils/const';
import { deleteWhiteSpacesInObject } from '../../../utils/functions';

function RadioLogoOnlyCreate({ type }) {
	const isCreation = true;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [formInputs, setFormInputs] = useState({
		radio: {
			rpid: 0,
			type: 3,
			nom: '',
		},
		'1400x1400': {},
	});
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const errorMessage400 = useSelector(selectRadiosErrorMessage400);
	const successMessage = useSelector(selectRadiosMessage);
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);

	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/radios_logo_only');
	}
	const updateFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({
			...prevInputs,
			radio: {
				...prevInputs.radio,
				[name]: value === 'undefined' ? undefined : value,
			},
		}));
	};

	const handleChangeFormInputs = (event) => {
		updateFormInput(event.target.name, event.target.value);
	};

	const submitForm = (event) => {
		event.preventDefault();
		deleteWhiteSpacesInObject(formInputs);
		dispatch(createRadio(token, formInputs));
		setIsModalOpen(true);
	};
	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorMessage400([]));
		dispatch(setErrorServerMessage(''));
	};

	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to="/radios_logo_only" className="fs-5 " onClick={eraseErrorMessage}>
					Retour
				</Link>
			</div>
		);
	}

	return (
		<div className="tableWrapper">
			<InsideMenu homeLink={'/radios_logo_only'} title={'Gestion des Radios Logo only'} links={[]} />

			<div className="RadioManager bg-white rounded-4 p-5 mx-4">
				<form onSubmit={submitForm}>
					<RpidInput formInputs={formInputs} handleChangeFormInputs={handleChangeFormInputs} />
					<RadioTypeInputs
						type={type}
						formInputs={formInputs}
						handleChangeFormInputs={handleChangeFormInputs}
					/>
					<GeneralsInfosInputs
						type={type}
						formInputs={formInputs}
						handleChangeFormInputs={handleChangeFormInputs}
					/>
					<ImgInputs
						type={type}
						formInputs={formInputs}
						updateFormInput={updateFormInput}
						setFormInputs={setFormInputs}
						isCreation={isCreation}
					/>
					<div className="d-flex mt-5">
						<input className="formSubmitButton" type="submit" value="Enregistrer" />
					</div>
				</form>
			</div>
			<div>
				<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
					{successMessage ? (
						<div className="text-success">
							Votre radio a bien été créée.
							<br />
							<Link to={'/radios_logo_only'} className="fs-5 " onClick={eraseErrorMessage}>
								Retour à la page des radios
							</Link>
						</div>
					) : errorMessage ? (
						<div className="mt-5">
							<div className="text-danger">{errorMessage}</div>
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : errorMessage400.length > 0 ? (
						<div>
							<div>Les erreurs suivantes sont apparues dans les données de votre formulaire:</div>
							{errorMessage400.map((message, index) => (
								<div key={index} className="my-2">
									<div className="text-danger"> - {message.msg}</div>
								</div>
							))}
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : null}
				</Modal>
			</div>
			<GoToTop />
		</div>
	);
}

export default RadioLogoOnlyCreate;
