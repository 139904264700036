import React from 'react';
import noPicture from '../../../../assets/camera-off.svg';

function ImgInputs({ formInputs, setFormInputs, currentImg, isCreation }) {
	const updateImgFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
	};
	const handleChangeImgInputs = (event) => {
		updateImgFormInput(event.target.name, event.target.files[0]);
	};
	return (
		<div className="row mt-3">
			<div className="d-flex align-items-start">
				<label className="form-label mt-3 mb-3">
					{formInputs?.image instanceof File ? (
						<img src={URL.createObjectURL(formInputs.image)} border="" height="100" alt="article" />
					) : (
						<img
							src={formInputs?.image.length > 0 ? formInputs.image : noPicture}
							border=""
							height="100"
							alt="article"
						/>
					)}
				</label>
			</div>
			<div className=" d-flex align-items-start flex-column">
				<label className="form-label d-flex justify-content-start" htmlFor="image">
					Image*
				</label>
				<input
					className="form-control"
					type="file"
					name="image"
					id="image"
					accept=".jpg,.jpeg,.png"
					onChange={handleChangeImgInputs}
					required={isCreation}
				/>
			</div>
		</div>
	);
}

export default ImgInputs;
