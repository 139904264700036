import axios from 'axios';
import {
	setUsers,
	setUser,
	setMessage,
	setErrorMessage,
	setError404Message,
	setErrorServerMessage,
} from '../../features/usersSlice';

const { REACT_APP_API_URL } = process.env;

export const getUsers = (token) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/users`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setUsers(response.data.users));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const getUser = (userEmail, token) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/users/${userEmail.email}`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setUser(response.data));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const updateUser = (email, data, token) => {
	return (dispatch) =>
		axios
			.put(`${REACT_APP_API_URL}/users/${email}`, data, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.request.status));
			})
			.catch(function (error) {
				if (error.response.request.status === 400) {
					dispatch(setError404Message(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred'));
				}
			});
};

export const deleteUser = (email, token) => {
	return (dispatch) =>
		axios
			.delete(`${REACT_APP_API_URL}/users/${email}`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage('Utilisateur Supprimé !'));
				setTimeout(() => {
					dispatch(setMessage(null));
				}, 5000);
			})
			.catch(function (error) {
				dispatch(setMessage('A problem has occurred'));
			});
};
