import React from 'react';
import { useDispatch } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.css';
import { FaAngleRight } from 'react-icons/fa6';
import { BiSolidInfoSquare } from 'react-icons/bi';
import { FiGrid, FiEdit, FiRadio, FiUsers, FiDatabase } from 'react-icons/fi';
import { FaRegCheckSquare } from 'react-icons/fa';
import { MdOutlinePodcasts } from 'react-icons/md';
import { ImStatsDots } from 'react-icons/im';
import { AiFillPicture } from 'react-icons/ai';
import logoRp from '../../assets/logo.png';
import logoIcon from '../../assets/logo-icon.png';
import { analyticsStats } from '../../utils/enum';
import { setStatistics } from '../../features/analyticsSlice';

function SideBar({
	isAdmin,
	isSideBarOpen,
	setSideBarOpen,
	isSideBarReduced,
	setSideBarReduced,
	setStatsMod,
	groupId,
}) {
	const dispatch = useDispatch();
	const userState = {
		rpids: sessionStorage.getItem('rpids'),
		isAnalytics: sessionStorage.getItem('isAnalytics'),
		isCopil: sessionStorage.getItem('isCopil'),
		groupId: sessionStorage.getItem('groupId'),
		groupId2: sessionStorage.getItem('groupId2'),
		onlyStats: sessionStorage.getItem('onlyStats'),
	};
	const logoSize = '16px';
	const sideBarContent = [
		(isAdmin || userState.rpids) && !userState.onlyStats
			? {
					name: 'Vos Radios',
					link: '/radios',
					logo: <FiRadio size={logoSize} />,
				}
			: isAdmin && userState.onlyStats
				? null
				: null,
		isAdmin
			? {
					name: 'Stats 7.8+',
					link: '/analytics/radioplayer',
					logo: <ImStatsDots size={logoSize} />,
					statsMod: analyticsStats.rpStats,
				}
			: null,
		isAdmin
			? {
					name: 'Stats Old',
					link: '/analytics/radioplayer/old',
					logo: <ImStatsDots size={logoSize} />,
					statsMod: analyticsStats.rpStats,
				}
			: null,

		// {
		// 	name: "Stats Podcasts Régions",
		// 	link: "/analytics/podcasts_regions",
		// 	logo: <ImStatsDots size={logoSize} />,
		// },
		isAdmin
			? {
					name: 'Validation - Others',
					link: '/radios/pending/others',
					logo: <FaRegCheckSquare size={logoSize} />,
				}
			: null,
		isAdmin
			? {
					name: 'Validation - Décrochages',
					link: '/locals',
					logo: <FaRegCheckSquare size={logoSize} />,
				}
			: null,
		isAdmin
			? {
					name: 'Validation - NRJ Group',
					link: '/radios/pending/nrj',
					logo: <FaRegCheckSquare size={logoSize} />,
				}
			: null,
		isAdmin
			? {
					name: 'Utilisateurs',
					link: '/users',
					logo: <FiUsers size={logoSize} />,
				}
			: null,
		isAdmin
			? {
					name: 'Radios Logo only',
					link: '/radios_logo_only',
					logo: <AiFillPicture size={logoSize} />,
					needAdmin: true,
				}
			: null,
		isAdmin
			? {
					name: 'Articles',
					link: '/articles/online',
					logo: <FiEdit size={logoSize} />,
				}
			: null,
		isAdmin || groupId <= 6
			? { name: 'Podcasts à la une', link: `/onboardings`, logo: <MdOutlinePodcasts size={logoSize} /> }
			: null,
		isAdmin
			? {
					name: 'Compteur de médias',
					link: '/medias-counter',
					logo: <BiSolidInfoSquare size={logoSize} />,
				}
			: null,
		isAdmin
			? {
					name: 'Journaux',
					link: '/logs',
					logo: <FiDatabase size={logoSize} />,
				}
			: null,
	];
	const behaviorWhenNavigateToStatsPage = (content) => {
		setStatsMod(content.statsMod);
		dispatch(setStatistics(null));
	};
	return (
		<Nav
			className="col-md-12 d-md-block sidebar miniSideBar ps-3"
			style={isSideBarOpen ? { width: '280px' } : { width: '85px' }}
			onMouseEnter={isSideBarReduced ? () => setSideBarOpen(!isSideBarOpen) : null}
			onMouseLeave={isSideBarReduced ? () => setSideBarOpen(!isSideBarOpen) : null}
		>
			<div className="sideBarLogo d-flex align-items-center justify-content-between">
				{isSideBarOpen ? (
					<Link to="/radios">
						<img src={logoRp} alt="logo Radioplayer" width="110vh" />
					</Link>
				) : null}
				<span
					className="mx-2 text-dark"
					onClick={() => {
						setSideBarOpen(isSideBarReduced);
						setSideBarReduced(!isSideBarReduced);
					}}
				>
					{isSideBarOpen ? (
						<div className="menuDisplayChoice">
							<div className="circle"></div>
							<FiGrid size={'20px'} />
						</div>
					) : (
						<img src={logoIcon} alt="logo small menu" />
					)}
				</span>
			</div>
			<div className="mt-5">
				{sideBarContent.map((content, index) =>
					content ? (
						<Link
							to={content.link}
							className=" sideBarLink d-flex align-items-center justify-content-between"
							key={index}
							onClick={() => (content.statsMod ? behaviorWhenNavigateToStatsPage(content) : null)}
						>
							<div className="sideBarLinkName ms-1">
								{content.logo} {isSideBarOpen ? <span className="ms-1">{content.name}</span> : null}
							</div>
							{isSideBarOpen ? (
								<span className="mx-2">
									<FaAngleRight size={'12px'} />
								</span>
							) : null}
						</Link>
					) : null
				)}
			</div>
		</Nav>
	);
}

export default SideBar;
