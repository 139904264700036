import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import './style.css';

function StatsPie({ windowSize, data, title }) {
	const COLORS = ['#cc0033', '#F73164', '#51BB25', '#A927F9', '#F8D62B'];

	const RADIAN = Math.PI / 180;
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);
		if (percent < 0.02) {
			return;
		} else {
			return (
				<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
					{`${(percent * 100).toFixed(1)}%`}
				</text>
			);
		}
	};
	function CustomTooltip({ payload, active }) {
		if (active) {
			return (
				<div
					className="customTooltipPie d-flex align-items-center p-4"
					style={{ backgroundColor: payload[0].payload.fill }}
				>
					{title === 'mode' ? payload[0].payload?.output : payload[0].payload?.reseau} :{' '}
					{payload[0].value + ' écoutes'}
				</div>
			);
		}
	}

	return (
		<div className="pieChart">
			<PieChart width={windowSize >= 992 ? windowSize / 3 : windowSize / 1.5} height={260}>
				<Pie
					data={data}
					cx="50%"
					cy="50%"
					outerRadius={100}
					dataKey={title === 'mode' ? 'ecoutes' : 'ecoutes'}
					stroke={'#FBFBFB'}
					label={renderCustomizedLabel}
					labelLine={false}
				>
					{data &&
						data.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} className="custom-pie" />
						))}
				</Pie>
				<Legend
					layout="vertical"
					align="right"
					verticalAlign="middle"
					payload={data.map((item, index) => ({
						id: item.output,
						type: 'circle',
						value: title === 'mode' ? item.output : item.reseau ? item.reseau : 'inconnu',
						color: COLORS[index % COLORS.length],
					}))}
				/>
				<Tooltip
					offset="20"
					content={<CustomTooltip />}
					wrapperStyle={{ borderRadius: 20, padding: '10px' }}
					cursor={{ fill: 'rgba(196,196,196,0.5)' }}
				/>
			</PieChart>
		</div>
	);
}

export default StatsPie;
