import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectToken, selectOneUser } from '../../../selectors';
import { updateUser } from '../../../services/Users';
import './style.css';

function UserAllowingButton() {
	const dispatch = useDispatch();
	const params = useParams();
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const user = useSelector(selectOneUser);
	const [isAllowed, setAllowed] = useState();

	useEffect(() => {
		if (user) {
			setAllowed(!!user.user.isAllowed);
		}
	}, [user]);

	const changeUserAllow = (permission) => {
		setAllowed(!!permission);
		dispatch(updateUser(params.email, { isAllowed: permission }, token));
	};

	return (
		<div className="userActionButtons">
			<button
				onClick={() => changeUserAllow(isAllowed ? 0 : 1)}
				className={isAllowed ? 'noAllowUserFormButton' : 'allowUserFormButton'}
			>
				{isAllowed ? 'Désactiver' : 'Activer'}
			</button>
		</div>
	);
}

export default UserAllowingButton;
