import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/authSlice';
import usersSlice from '../features/usersSlice';
import radiosSlice from '../features/radiosSlice';
import categoriesSlice from '../features/categoriesSlice';
import articlesSlice from '../features/articlesSlice';
import analyticsSlice from '../features/analyticsSlice';

// redux store configuration
export const store = configureStore({
	reducer: {
		auth: authReducer,
		users: usersSlice,
		radios: radiosSlice,
		categories: categoriesSlice,
		articles: articlesSlice,
		analytics: analyticsSlice,
	},
});
