import React from 'react';

function WeekInputs({ formInputs, handleChangeFormInputs }) {
	const weekDays = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];

	return (
		<div className="d-flex justify-content-between">
			{weekDays.map((day, index) => (
				<div className="form-check" key={index}>
					<label className="form-check-label" htmlFor={day}>
						{day}
					</label>
					<input
						className="form-check-input weekDay"
						type="checkbox"
						name={day}
						id={day}
						value={formInputs[day] === 'O' ? 'N' : 'O'}
						checked={formInputs[day] === 'O'}
						onChange={handleChangeFormInputs}
					/>
				</div>
			))}
		</div>
	);
}

export default WeekInputs;
