import axios from 'axios';
import { setCategories, setErrorMessage } from '../../features/categoriesSlice';

const { REACT_APP_API_URL } = process.env;

//Get radios  informations with secure token
export const getCategories = (token, type) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/categories/${type}`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setCategories(response.data.categories));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};
