import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import {
	RadioProgramsColumnsInfos,
	globalTableStyle,
	globalThStyle,
	globalTdStyle,
	modalStyles,
} from '../../utils/const';
import {
	selectRadio,
	selectRadioPrograms,
	selectToken,
	selectRadiosErrorMessage,
	selectRadiosMessage,
	selectRadiosServerErrorMessage,
} from '../../selectors';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import {
	getRadioPrograms,
	getRadio,
	desactivateRadioProgram,
	activateRadioProgram,
	deleteRadioProgram,
} from '../../services/Radios';
import { setMessage, setErrorMessage, setErrorServerMessage } from '../../features/radiosSlice';
import InsideMenu from '../../components/InsideMenu';
import GlobalFilter from '../../components/GlobalFilter';
import ElementsToShow from '../../components/ElementsToShow';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import GoToTop from '../../components/GoToTop';
import AddPrograms from '../RadioProgramManager/AddPrograms';
import { scrollTop, compareIgnoreCase, numericSort } from '../../utils/functions';
import './style.css';

function RadioPrograms({ isAdmin }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const radioData = useSelector(selectRadio);
	const [isLoaded, setLoaded] = useState(false);
	const [data, setData] = useState([]);
	const [currentRadioName, setCurrentRadioName] = useState('');
	const radioPrograms = useSelector(selectRadioPrograms);
	const [isProgramsActifs, setIsProgramsActifs] = useState('O');
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isAboutToDeleteProgram, setIsAboutToDeleteProgram] = useState(false);
	const [programName, setProgramName] = useState('');
	const [programId, setProgramId] = useState('');

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		dispatch(setErrorMessage(''));
		dispatch(setMessage(''));
		dispatch(setErrorServerMessage(''));
	}, [dispatch]);

	useEffect(() => {
		if (token) {
			dispatch(getRadioPrograms(token, params.rpID, isProgramsActifs));
			dispatch(getRadio(token, params.rpID));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, params, isProgramsActifs]);

	useEffect(() => {
		if (radioData) {
			setCurrentRadioName(radioData.data.nom);
		}
	}, [dispatch, radioData]);

	useEffect(() => {
		if (radioPrograms) {
			setData(radioPrograms);
			setLoaded(true);
		}
	}, [dispatch, radioPrograms]);

	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/programs');
	}
	const columns = useMemo(() => RadioProgramsColumnsInfos, []);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
			sortTypes: {
				alphanumeric: (row1, row2, columnName) => {
					if (columnName === 'id') {
						return numericSort(row1.values[columnName], row2.values[columnName]);
					} else return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
				},
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);
	const navigateProgramManagement = (row) => {
		navigate(`/radios/${row.original.rpID}/programs/edit/${row.original.id}`);
	};
	const disableProgram = (row) => {
		dispatch(desactivateRadioProgram(token, params.rpID, row.original.id));
		setIsModalOpen(true);
	};
	const enableProgram = (row) => {
		dispatch(activateRadioProgram(token, params.rpID, row.original.id));
		setIsModalOpen(true);
	};

	const deleteProgram = (row) => {
		setProgramName(row.original.nom_long);
		setProgramId(row.original.id);
		setIsModalOpen(true);
		setIsAboutToDeleteProgram(true);
	};

	const actions = [
		{ funct: navigateProgramManagement, name: 'Modifier' },
		{
			funct: isProgramsActifs === 'O' ? disableProgram : enableProgram,
			name: isProgramsActifs === 'O' ? 'Désactiver' : 'Activer',
		},
		{ funct: deleteProgram, name: 'Supprimer' },
	];
	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorServerMessage(''));
	};
	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to="/Radios" className="fs-5 " onClick={() => dispatch(setErrorServerMessage(''))}>
					Retour
				</Link>
			</div>
		);
	}
	return (
		<div>
			<InsideMenu
				homeLink={`/radios`}
				title={`Gestion Des Programmes - ${currentRadioName}`}
				links={[
					{ name: 'Retour à la radio', path: `/radios/edit/${params.rpID}` },
					{ name: "Ajout d'un programme", path: `/radios/${params.rpID}/programs/add` },
				]}
			/>
			<div className="d-flex justify-content-end me-4 mb-4">
				<AddPrograms setData={setData} />
			</div>
			<div className="overflow-x-scroll scrollNone d-flex justify-content-center flex-column bg-white rounded-4 p-5 mx-4">
				<div
					className="otherPodcastsStatus text-start w-100 mb-4"
					onClick={() => setIsProgramsActifs(isProgramsActifs === 'O' ? 'N' : 'O')}
				>
					{isProgramsActifs === 'O' ? 'Voir les émissions désactivées' : 'Voir les émissions actives'}
				</div>
				<div className="sorteWrapper">
					<ElementsToShow pageSize={pageSize} setPageSize={setPageSize} />
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
						dataName={'programmes'}
					/>
				</div>
				<Table
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
					page={page}
					tableStyle={globalTableStyle}
					thStyle={globalThStyle}
					tdStyle={globalTdStyle}
					actions={actions}
					isLoaded={isLoaded}
				/>

				<Pagination
					gotoPage={gotoPage}
					previousPage={previousPage}
					nextPage={nextPage}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					pageCount={pageCount}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
				/>
			</div>
			<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				{isAboutToDeleteProgram ? (
					<>
						<div className="fw-bold mb-2">
							Etes vous sur de vouloir supprimer le programme "{programName}"?
						</div>
						<div className="d-flex">
							<button
								className="cancelDeleteProgramButton me-3"
								onClick={() => {
									setIsModalOpen(false);
									setProgramName('');
									setProgramId('');
									setIsAboutToDeleteProgram(false);
								}}
							>
								Retour
							</button>
							<button
								className="deleteProgramButton"
								onClick={() => {
									dispatch(deleteRadioProgram(token, params.rpID, programId));
									setProgramName('');
									setProgramId('');
									setIsAboutToDeleteProgram(false);
									eraseErrorMessage();
								}}
							>
								Oui
							</button>
						</div>
					</>
				) : successMessage ? (
					<div>
						<div className="text-success mb-2">Opération réussie.</div>
						<button
							className="btn btn-secondary"
							onClick={() => {
								setIsModalOpen(false);
								dispatch(getRadioPrograms(token, params.rpID, isProgramsActifs));
								eraseErrorMessage();
							}}
						>
							Retour
						</button>
					</div>
				) : (
					errorMessage && (
						<div className="mt-5">
							<div className="fs-4 text-danger mb-4">{errorMessage}</div>
							<button
								className="btn btn-secondary"
								onClick={() => {
									setIsModalOpen(false);
									dispatch(getRadioPrograms(token, params.rpID, isProgramsActifs));
									eraseErrorMessage();
								}}
							>
								Retour
							</button>
						</div>
					)
				)}
			</Modal>
			<GoToTop />
		</div>
	);
}

export default RadioPrograms;
