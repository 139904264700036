import React from 'react';

function MotherAndGroupIDInputs({ handleChangeFormInputs, formInputs, sortedRadios, radioGroup }) {
	return (
		<div className="d-flex justify-content-around mt-1">
			<div className="col mb-3 me-2">
				<label className="form-label d-flex justify-content-start">ID de la radio Mère</label>
				<select
					className="form-select"
					name="id_mere"
					value={formInputs.radio.id_mere}
					onChange={(e) => handleChangeFormInputs(e, 'radio')}
				>
					<option value={0}>-----</option>
					{sortedRadios &&
						sortedRadios.map((radio, index) => (
							<option key={index} value={radio.rpID}>
								{radio.nom}
							</option>
						))}
				</select>
			</div>
			<div className="col mb-3 mx-2">
				<label className="form-label d-flex justify-content-start">ID du groupe de la radio</label>
				<select
					className="form-select"
					name="groupe_id"
					value={formInputs.radio.groupe_id}
					onChange={(e) => handleChangeFormInputs(e, 'radio')}
				>
					<option value={0}>-----</option>
					{radioGroup &&
						radioGroup.map((radio, index) => (
							<option key={index} value={radio.id}>
								{radio.nom}
							</option>
						))}
				</select>
			</div>
		</div>
	);
}

export default MotherAndGroupIDInputs;
