import axios from 'axios';
import {
	setPodcasts,
	setPodcast,
	setPodcastsNames,
	setEpisodes,
	setOnboardings,
	setOnboarding,
	setErrorMessage,
	setMessage,
	setErrorMessage400,
	setErrorFeed,
	setErrorServerMessage,
} from '../../features/radiosSlice';

const { REACT_APP_API_URL } = process.env;

export const getPodcasts = (token, rpID, isPodcastsActifs) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/podcasts`, {
				headers: { 'x-auth-token': token },
				params: { rpid: Number(rpID), actif: isPodcastsActifs },
			})
			.then((response) => {
				dispatch(setPodcasts(response.data.podcasts));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};
export const getPodcast = (token, rpID, feedId) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/podcasts/${feedId}`, {
				headers: { 'x-auth-token': token },
				params: { rpid: Number(rpID), actif: 'O' },
			})
			.then((response) => {
				dispatch(setPodcast(response.data.podcast));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const addPodcast = (token, data) => {
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/podcasts`, data, {
				headers: { 'x-auth-token': token, 'Content-Type': 'multipart/form-data' },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.data === 'Feed url already in use.') {
					dispatch(setErrorFeed(`${data.feed}`));
				} else {
					dispatch(setErrorMessage(`A problem has occurred`));
				}
			});
};

export const updatePodcast = (token, podcastId, data) => {
	return (dispatch) =>
		axios
			.put(`${REACT_APP_API_URL}/podcasts/${podcastId}`, data, {
				headers: { 'x-auth-token': token, 'Content-Type': 'multipart/form-data' },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 400) {
					dispatch(setErrorMessage400(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred'));
				}
			});
};
export const deletePodcasts = (token, radioRpID) => {
	return (dispatch) =>
		axios
			.delete(`${REACT_APP_API_URL}/podcasts`, {
				headers: { 'x-auth-token': token },
				data: {
					rpid: radioRpID,
				},
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};
export const deletePodcast = (token, podcastId, radioRpID) => {
	return (dispatch) =>
		axios
			.delete(`${REACT_APP_API_URL}/podcasts/${podcastId}`, {
				headers: { 'x-auth-token': token },
				data: {
					rpid: radioRpID,
				},
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};

export const desactivatePodcast = (token, podcastId, radioRpID) => {
	const bodyData = { rpid: radioRpID };
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/podcasts/${podcastId}/desactivate`, bodyData, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};

export const activatePodcast = (token, podcastId, radioRpID) => {
	const bodyData = { rpid: radioRpID };
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/podcasts/${podcastId}/activate`, bodyData, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};

export const getEpisodes = (token, rpID, feedId, actif) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/radios/${rpID}/podcasts/${feedId}/episodes`, {
				headers: { 'x-auth-token': token },
				params: { actif: actif },
			})
			.then((response) => {
				dispatch(setEpisodes(response.data.episodes));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const deleteEpisode = (token, rpID, feedId, episodeId) => {
	return (dispatch) =>
		axios
			.delete(`${REACT_APP_API_URL}/radios/${rpID}/podcasts/${feedId}/episodes/${episodeId}`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};

export const deleteEpisodes = (token, rpID, feedId) => {
	return (dispatch) =>
		axios
			.delete(`${REACT_APP_API_URL}/radios/${rpID}/podcasts/${feedId}/episodes`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};

export const getOnboardings = (token) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/recommendations/podcasts`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setOnboardings(response.data.recommendations));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const getOnboarding = (token, idSeries) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/recommendations/podcasts/${idSeries}`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setOnboarding(response.data.recommendation));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};
export const getPodcastsNames = (token, groupid) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/podcasts`, {
				headers: { 'x-auth-token': token },
				params: { groupeId: groupid },
			})
			.then((response) => {
				dispatch(setPodcastsNames(response.data.podcasts));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};
export const deleteOnboarding = (token, idSeries) => {
	return (dispatch) =>
		axios
			.delete(`${REACT_APP_API_URL}/recommendations/podcasts/${idSeries}`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};

export const updateOnboarding = (token, id, data) => {
	return (dispatch) =>
		axios
			.put(`${REACT_APP_API_URL}/recommendations/podcasts/${id}`, data, {
				headers: { 'x-auth-token': token, 'Content-Type': 'multipart/form-data' },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 400) {
					dispatch(setErrorMessage400(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred'));
				}
			});
};

export const addOnboarding = (token, data) => {
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/recommendations/podcasts`, data, {
				headers: { 'x-auth-token': token, 'Content-Type': 'multipart/form-data' },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 400) {
					dispatch(setErrorMessage400(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred'));
				}
			});
};

export const activatePodcastEpisode = (token, radioRpID, podcastFeedId, episodeId) => {
	return (dispatch) =>
		axios
			.post(
				`${REACT_APP_API_URL}/radios/${radioRpID}/podcasts/${podcastFeedId}/episodes/${episodeId}/activate`,
				'',
				{ headers: { 'x-auth-token': token } }
			)
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};

export const desactivatePodcastEpisode = (token, radioRpID, podcastFeedId, episodeId) => {
	return (dispatch) =>
		axios
			.post(
				`${REACT_APP_API_URL}/radios/${radioRpID}/podcasts/${podcastFeedId}/episodes/${episodeId}/desactivate`,
				'',
				{ headers: { 'x-auth-token': token } }
			)
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};
