export const scrollTop = () => {
	const body = document.querySelector('body');
	if (body) {
		body.scrollIntoView({ behavior: 'smooth' });
	}
};
export function compareIgnoreCase(a, b) {
	let r1 = String(a).toLowerCase();
	let r2 = String(b).toLowerCase();
	if (r1 < r2) {
		return -1;
	}
	if (r1 > r2) {
		return 1;
	}
	return 0;
}
export function numericSort(a, b) {
	return a - b;
}
export const dateFormattingWithMnSec = (date) => {
	const newDate = new Date(date);
	return (
		newDate.getFullYear() +
		'-' +
		(newDate.getMonth() + 1 < 10 ? '0' + (newDate.getMonth() + 1) : newDate.getMonth() + 1) +
		'-' +
		(newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()) +
		' ' +
		(newDate.getHours() - 2 < 10 ? '0' + (newDate.getHours() - 2) : newDate.getHours() - 2) +
		':' +
		(newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes()) +
		':' +
		(newDate.getSeconds() < 10 ? '0' + newDate.getSeconds() : newDate.getSeconds())
	);
};

export const dateFormatting = (date) => {
	const newDate = new Date(date);
	return (
		newDate.getFullYear() +
		'-' +
		(newDate.getMonth() + 1 < 10 ? '0' + (newDate.getMonth() + 1) : newDate.getMonth() + 1) +
		'-' +
		(newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate())
	);
};

export const dateFormattingTwoDaysAgo = () => {
	const today = new Date();
	const twoDaysAgo = new Date(today);
	// Retirer deux jours
	twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
	// Formater la date en YYYY-MM-DD
	const year = twoDaysAgo.getFullYear();
	const month = String(twoDaysAgo.getMonth() + 1).padStart(2, '0');
	const day = String(twoDaysAgo.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
};

export const daysBetweenTwoDates = (date1, date2) => {
	if (date1 && date2) {
		return Math.floor((date1 - date2) / 1000 / 60 / 60 / 24);
	}
};

export const averageListens = (totalDuration, listens) => {
	if (totalDuration && listens) {
		const date = new Date(null);
		const seconds = totalDuration / listens;
		date.setSeconds(seconds);
		return date.toISOString().slice(11, 19);
	}
};

export const deleteWhiteSpacesInObject = (object) => {
	for (let key in object) {
		if (object.hasOwnProperty(key) && typeof object[key] === 'string') {
			object[key] = object[key].trim();
		}
	}
};

export const aggregateStationsDetails = (data, key, keysup) => {
	const result = data.reduce((acc, item) => {
		const keyValue = item[key];
		const keySupValue = item[keysup];
		const existing = acc.find((x) => x[key] === keyValue);

		if (existing) {
			existing.mau += item.mau;
			existing.ecoutes += item.ecoutes;
			existing.duree_totale += item.duree_totale;
			existing.ecoutes30s += item.ecoutes30s;
			existing.duree_totale30s += item.duree_totale30s;
		} else {
			acc.push({
				[key]: keyValue,
				[keysup]: keySupValue,
				mau: item.mau,
				ecoutes: item.ecoutes,
				duree_totale: item.duree_totale,
				ecoutes30s: item.ecoutes30s,
				duree_totale30s: item.duree_totale30s,
			});
		}

		return acc;
	}, []);

	return result;
};
export const aggregateFavorites = (data, key) => {
	const result = data.reduce((acc, item) => {
		const keyValue = item[key];
		const existing = acc.find((x) => x[key] === keyValue);

		if (existing) {
			existing.count += item.count;
		} else {
			acc.push({
				[key]: keyValue,
				count: item.count,
			});
		}

		return acc;
	}, []);

	return result;
};

export const isDifferenceMoreThanTwoMonths = (date1, date2) => {
	// Convertir les chaînes de date en objets Date
	const parsedDate1 = new Date(date1);
	const parsedDate2 = new Date(date2);

	// Calculer la différence en mois et années
	const yearsDifference = parsedDate2.getFullYear() - parsedDate1.getFullYear();
	const monthsDifference = parsedDate2.getMonth() - parsedDate1.getMonth();
	const daysDifference = parsedDate2.getDate() - parsedDate1.getDate();

	// Calculer la différence totale en mois et jours
	let totalMonthsDifference = yearsDifference * 12 + monthsDifference;

	// Ajuster les mois si les jours ne sont pas suffisants pour un mois complet de différence
	if (daysDifference < 0) {
		totalMonthsDifference--;
	}

	// Calculer si la différence dépasse exactement 2 mois et 1 jour
	if (totalMonthsDifference > 2) {
		return true; // Plus de deux mois indépendamment des jours
	} else if (totalMonthsDifference === 2 && daysDifference >= 1) {
		return true; // Exactement deux mois et plus d'un jour
	}

	return false; // Moins que ou égal à deux mois et un jour
};
